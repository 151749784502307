import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";

export function autocompleteSuggestionValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (typeof control.value === "string") {
      return { invalidAutocompleteObject: { value: control.value } };
    }
    return null; /* valid option selected */
  };
}
