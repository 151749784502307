import { CommonModule } from "@angular/common";
import { Component, EventEmitter, Output } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatTabsModule } from "@angular/material/tabs";
import { MatToolbarModule } from "@angular/material/toolbar";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import {
  faAngleDown,
  faCircle,
  faCircleDot,
  faTimes,
  faTrashCan,
} from "@fortawesome/pro-light-svg-icons";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { PBAlertModule } from "@portbase/material/alert";
import { ButtonIconDirective } from "@portbase/material/button";
import { PortbaseExportFormInputComponent } from "../../../components";
import { PortbaseExportTabBarComponent } from "../../../components/tab-bar/tab-bar.component";
import { DialogService } from "../../../core/services/dialog/dialog.service";
import { PortbaseExportEmStatusIndicatorComponent } from "../../components/status-indicator/status-indicator.component";
import { PortbaseExportEmVesselVisitSearchComponent } from "../../components/vessel-visit-search/vessel-visit-search.component";
import {
  CreateExportManifestShipmentDto,
  ExportManifestService,
  ExportManifestShipmentDto,
  ExportManifestStatus,
} from "../../services/export-manifest.service";
import { VesselVisit } from "../../services/vessel-visit.service";
import { ExportManifestShipmentsComponent } from "../components/export-shipments/export-shipments.component";
import { PortbaseVesselVisitItemComponent } from "../components/vessel-visit-item/vessel-visit-item.component";

@Component({
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    MatToolbarModule,
    MatButtonModule,
    PortbaseExportTabBarComponent,
    PortbaseExportEmStatusIndicatorComponent,
    PortbaseExportFormInputComponent,
    FontAwesomeModule,
    ButtonIconDirective,
    MatTabsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    PortbaseVesselVisitItemComponent,
    ExportManifestShipmentsComponent,
    PortbaseExportEmVesselVisitSearchComponent,
    PBAlertModule,
  ],
  selector: "pbe-export-manifest-create-page",
  templateUrl: './create-page.component.html',
  styleUrl: './create-page.component.scss'
})
export class ExportManifestCreatePageComponent {
  faTimes = faTimes;
  faCircle = faCircle;
  faCircleDot = faCircleDot;
  faAngleDown = faAngleDown;
  faTrashCan = faTrashCan;

  ExportManifestStatus = ExportManifestStatus;

  createExportManifest =
    this.exportManifestService.createExportManifest().mutateAsync;

  updateExportManifest =
    this.exportManifestService.updateExportManifest().mutateAsync;

  sendToCustoms = this.exportManifestService.sendToCustoms().mutateAsync;

  initialShipments: ExportManifestShipmentDto[] = [];

  updatedShipments: CreateExportManifestShipmentDto[] = [];

  showEmptyShipmentsError = false;
  disableSubmit = false;

  @Output() createClose = new EventEmitter();

  selectedVesselVisit: VesselVisit | null = null;

  manifestId: string | null = null;

  constructor(
    private translateService: TranslateService,
    private exportManifestService: ExportManifestService,
    private dialogService: DialogService,
  ) {}

  onClose() {
    this.createClose.emit();
  }

  async save(shipments: CreateExportManifestShipmentDto[]) {
    this.updatedShipments = shipments;
    this.showEmptyShipmentsError = false;

    if (!this.manifestId) {
      return;
    }

    await this.updateExportManifest({
      id: this.manifestId,
      shipments:
        this.updatedShipments.filter((shipment) => shipment !== null) ?? [],
    });
  }

  async onSendToCustoms() {
    if (!this.selectedVesselVisit) {
      return;
    }

    if (
      this.updatedShipments === null ||
      this.updatedShipments.length === 0 ||
      this.manifestId === null
    ) {
      this.showEmptyShipmentsError = true;
      return;
    }

    await this.sendToCustoms({ id: this.manifestId });
    this.createClose.emit();
  }

  async onVesselVisitSelected(vesselVisit: VesselVisit | null) {
    this.selectedVesselVisit = vesselVisit;

    if (!this.selectedVesselVisit) {
      this.manifestId = null;
      return;
    }

    const { id } = await this.createExportManifest({
      callReferenceNumber: this.selectedVesselVisit.callReferenceNumber,
      shipments: [],
    });

    this.manifestId = id;
  }

  async onDelete() {
    const shouldDelete = await this.dialogService.showDeleteConfirmationDialog({
      title: this.translateService.instant(
        "services.exportManifest.deleteManifestDialog.title",
      ),
      description: this.translateService.instant(
        "services.exportManifest.deleteManifestDialog.description",
      ),
      confirmationButtonLabel: this.translateService.instant(
        "services.exportManifest.deleteManifestDialog.confirmButton",
      ),
      cancelButtonLabel: this.translateService.instant(
        "services.exportManifest.deleteManifestDialog.cancelButton",
      ),
      confirmationButtonColor: "warn",
    });

    if (!shouldDelete || !this.manifestId) {
      return;
    }

    await this.exportManifestService.deleteManifest().mutateAsync({
      manifestId: this.manifestId,
    });

    this.createClose.emit();
  }

  get title() {
    if (this.selectedVesselVisit) {
      return this.selectedVesselVisit.callReferenceNumber;
    }

    return this.translateService.instant(
      "services.exportManifest.createPage.header",
    );
  }

  get vesselIsInRotterdam() {
    if (!this.selectedVesselVisit) {
      return false;
    }

    return this.selectedVesselVisit.callReferenceNumber.startsWith("NLRTM");
  }
}
