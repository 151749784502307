import { HttpErrorResponse } from "@angular/common/http";
import { inject } from "@angular/core";
import { CanActivateFn } from "@angular/router";
import { map, Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { WINDOW } from "../../window";
import { AppConfigService } from "../services/app-config.service";
import { AuthenticationService } from "./authentication.service";

export const isAuthenticatedGuard: CanActivateFn = (): Observable<boolean> => {
  const authService = inject(AuthenticationService);
  const config = inject(AppConfigService);
  const window = inject(WINDOW);

  return authService.isAuthenticated().pipe(
    map((value) => {
      return value.authenticated;
    }),
    catchError((error: HttpErrorResponse) => {
      if (error.status === 401) {
        window.location.assign(
          `${config.getConfig().loginUrl}?redirect=${window.location.href}`,
        );
      }
      return throwError(() => error);
    }),
  );
};
