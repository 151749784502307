import { CommonModule } from "@angular/common";
import {
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  inject,
} from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { RejectionReasonsService } from "../../services/rejection-reasons.service";
import { ShipmentDto } from "../../services/shipment.service";

@Component({
  selector: "pbe-rejection-reasons",
  standalone: true,
  imports: [CommonModule, TranslateModule],
  templateUrl: './rejection-reasons.component.html',
  styleUrl: "./rejection-reasons.component.scss"
})
export class PortbaseExportRejectionReasonsComponent implements OnChanges {
  @Input({ required: true }) shipment!: ShipmentDto;

  rejectionReasonsService = inject(RejectionReasonsService);
  rejectionReasonsQuery =
    this.rejectionReasonsService.getRejectionReasonsForShipmentId(null);
  rejectionReasons = this.rejectionReasonsQuery.result;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes["shipment"].currentValue) {
      const shipment = changes["shipment"].currentValue as ShipmentDto;

      this.rejectionReasonsQuery.updateOptions(
        this.rejectionReasonsService.rejectionReasonsQueryOptions(
          shipment.shipmentId,
          shipment.arrivalAtExitStatus,
        ),
      );
    }
  }
}
