import { CommonModule, NgFor, NgIf } from "@angular/common";
import { Component, Input } from "@angular/core";
import {
  FormGroup,
  ReactiveFormsModule,
  ValidationErrors,
} from "@angular/forms";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { TranslateModule } from "@ngx-translate/core";

@Component({
  selector: "pbe-document-number-form-input",
  standalone: true,
  imports: [
    NgIf,
    NgFor,
    CommonModule,
    MatFormFieldModule,
    TranslateModule,
    MatInputModule,
    ReactiveFormsModule,
  ],
  templateUrl: './document-number-form-input.component.html',
  styleUrl: './document-number-form-input.component.scss'
})
export class PortbaseExportDocumentNumberFormInputComponent {
  @Input({ required: true }) form!: FormGroup;
  @Input({ required: true }) controlName!: string;

  getErrorsAsArray(validationErrors: ValidationErrors | null) {
    return Object.keys(validationErrors ?? {});
  }

  getFirstError(validationErrors: ValidationErrors | null) {
    return this.getErrorsAsArray(validationErrors)[0];
  }
}
