<form
  [formGroup]="form"
  (ngSubmit)="sendToCustoms.emit()"
  class="bg-white p-8 shadow-sm border max-w-3xl mx-auto"
>
  <h2 class="text-lg font-medium mb-8">
    {{ "services.arrivalAtExit.createPage.headers.document" | translate }}
  </h2>

  @if (
    form.controls["creationDate"] && form.controls["creationDate"].errors
    ) {
    <div class="w-full -mt-4 mb-2">
      <pb-alert
        type="error"
        [title]="
            'services.arrivalAtExit.errors.maxOneMonthOld.title' | translate
          "
        [message]="
            'services.arrivalAtExit.errors.maxOneMonthOld.message' | translate
          "
      ></pb-alert>
    </div>
  }

  <div class="w-full flex flex-row gap-8">
    <div class="max-w-96 flex-grow">
      <pbe-form-select
        [label]="
            'services.arrivalAtExit.createPage.fields.documentType' | translate
          "
        [form]="form"
        controlName="documentType"
        [placeholder]="
            'services.arrivalAtExit.createPage.fields.documentTypePlaceholder'
              | translate
          "
        [options]="[
            {
              value: 'EX',
              label:
                'services.arrivalAtExit.createPage.fields.documentTypeOptions.EX'
                | translate
            },
            {
              value: 'CO',
              label:
                'services.arrivalAtExit.createPage.fields.documentTypeOptions.CO'
                | translate
            }
          ]"
      />
      <pbe-document-number-form-input
        [form]="form"
        controlName="documentNumber"
      />
      <pb-input
        [label]="
            'services.arrivalAtExit.createPage.fields.reference' | translate
          "
        [form]="form"
        controlName="reference"
      />

      @if (form.controls["reference"].errors) {
        <mat-error class="text-xs">{{
            "general.forms.errors.reference.maxlength" | translate
          }}</mat-error>
      }

      <h2 class="text-lg font-medium mb-3 mt-4">
        {{ "services.arrivalAtExit.createPage.headers.arrival" | translate }}
      </h2>
      <pb-autocomplete
        [label]="
            'services.arrivalAtExit.createPage.fields.arrivalLocation'
              | translate
          "
        [placeholder]="
            'services.arrivalAtExit.createPage.fields.arrivalLocationPlaceholder'
              | translate
          "
        [formGroup]="form"
        controlName="arrivalLocation"
        [suggestions]="
            getFilteredSuggestionsForArrivalLocations(
              form.value.arrivalLocation
            )
          "
        [quickSelectionSuggestions]="
            quickSelectionSuggestionsForArrivalLocations
          "
      ></pb-autocomplete>
      @if (
        form.controls["arrivalLocation"].errors &&
        form.controls["arrivalLocation"].touched
        ) {
        <mat-error class="text-xs">{{
            "general.forms.errors.required" | translate
          }}</mat-error>
      }
      @if (showCargoHandlingAgentField) {
        <h2 class="text-lg font-medium mb-3 mt-4">
          {{ "services.arrivalAtExit.createPage.headers.agent" | translate }}
        </h2>
        <pb-autocomplete
          [label]="
              'services.arrivalAtExit.createPage.fields.agent' | translate
            "
          [placeholder]="
              'services.arrivalAtExit.createPage.fields.agentPlaceholder'
                | translate
            "
          [formGroup]="form"
          controlName="agent"
          [optionalLabel]="'general.forms.optionalLabel' | translate"
          [suggestions]="
              getFilteredSuggestionsForCargoHandlingAgents(form.value.agent)
            "
        ></pb-autocomplete>
      }
    </div>
    <div class="max-w-64">
      <ng-content></ng-content>
    </div>
  </div>
  <div class="flex flex-row align-center justify-between mt-8">
    <button
      mat-stroked-button
      type="button"
      (click)="cancel.emit()"
      [disabled]="isSaving || isSendingToCustoms"
    >
      {{ "services.arrivalAtExit.createPage.buttons.cancel" | translate }}
    </button>

    <div>
      <button
        mat-stroked-button
        type="button"
        class="mr-5"
        [disabled]="isSaving || isSendingToCustoms"
        (click)="save.emit()"
      >
          <span class="flex flex-row items-center">
            @if (isSaving) {
              <fa-icon [icon]="loadingIcon" class="animate-spin-slow" />
            }
            <span>{{
                "services.arrivalAtExit.createPage.buttons.saveAsDraft"
                  | translate
              }}</span>
          </span>
      </button>
      <button
        mat-raised-button
        type="submit"
        [disabled]="isSaving || isSendingToCustoms"
      >
          <span class="flex flex-row items-center ">
            @if (isSendingToCustoms) {
              <fa-icon [icon]="loadingIcon" class="animate-spin-slow" />
            }
            <span>{{
                "services.arrivalAtExit.createPage.buttons.submit" | translate
              }}</span>
          </span>
      </button>
    </div>
  </div>
</form>
