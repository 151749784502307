import { Component, Input, inject } from "@angular/core";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { faRightLeft } from "@fortawesome/pro-solid-svg-icons";
import { TranslateModule } from "@ngx-translate/core";
import { ArrivalLocationService } from "../../../services/arrival-location.service";
import {
  ShipmentHistoryDto,
  ShipmentOperationType,
  ValueHolder,
} from "../../../services/shipment-history.service";

@Component({
  selector: "pbe-history-item-details-changed",
  standalone: true,
  imports: [TranslateModule, FontAwesomeModule],
  templateUrl: './history-item-details-changed.component.html',
  styleUrl: './history-item-details-changed.component.scss'
})
export class HistoryItemDetailsChangedComponent {
  @Input({ required: true })
  history!: ShipmentHistoryDto<ShipmentOperationType.AAX_SHIPMENT_DETAILS_CHANGED>;

  faArrowRight = faRightLeft;

  headerMap: Record<string, string> = {
    documentType: "Document Type",
    documentNumber: "Document Number",
    reference: "Reference",
    arrivalLocationId: "Arrival Location",
  };

  arrivalLocationService = inject(ArrivalLocationService);

  getHeader(): string {
    const { attribute } = this.history.operationPayload;
    return this.headerMap[attribute] || "Unknown";
  }

  getChanges(): ValueHolder<string, string> {
    const { attribute, change } = this.history.operationPayload;

    if (attribute === "arrivalLocationId") {
      const oldArrivalLocation =
        this.arrivalLocationService.getArrivalLocationForId(change.oldValue);

      const newArrivalLocation =
        this.arrivalLocationService.getArrivalLocationForId(change.newValue);

      return {
        oldValue: `${oldArrivalLocation?.warehouseName} ${oldArrivalLocation?.houseNumber}, ${oldArrivalLocation?.zipCode}`,
        newValue: `${newArrivalLocation?.warehouseName} ${newArrivalLocation?.houseNumber}, ${newArrivalLocation?.zipCode}`,
      };
    }

    return change;
  }
}
