import { Component, EventEmitter, Input, Output } from "@angular/core";
import { MatListModule } from "@angular/material/list";

import { CommonModule } from "@angular/common";
import { MatButtonModule } from "@angular/material/button";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { faHeadset, faPlus, faTimes } from "@fortawesome/pro-light-svg-icons";
import { TippyDirective } from "@ngneat/helipopper";
import { TranslateModule } from "@ngx-translate/core";
import { ButtonIconDirective } from "@portbase/material/button";
import { PBDatePickerModule } from "@portbase/material/date-picker";

@Component({
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    MatListModule,
    ButtonIconDirective,
    TippyDirective,
    FontAwesomeModule,
    MatButtonModule,
    PBDatePickerModule,
  ],
  selector: "pbe-filter-list-item",
  templateUrl: "./filter-list-item.component.html",
  styleUrl: "./filter-list-item.component.scss",
})
export class FilterListItemComponent<T> {
  @Input({ required: true }) filter!: null | T;
  @Input({ required: true }) label!: string;
  @Input({ required: true }) formattedFilter!: string;
  @Input() disabled = false;
  @Input() isAdmin = false;

  @Output() clearButtonClick = new EventEmitter();

  clearButtonClicked(event: Event) {
    if (this.filter !== null) {
      event.stopPropagation();
      this.clearButtonClick.emit();
    }
  }

  faPlus = faPlus;
  faTimes = faTimes;
  protected readonly faHeadset = faHeadset;
}
