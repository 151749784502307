import { Component, Input, inject } from "@angular/core";
import { MatTableModule } from "@angular/material/table";

import { CommonModule } from "@angular/common";
import { MatButtonModule } from "@angular/material/button";
import { ActivatedRoute, Router } from "@angular/router";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { faChevronRight } from "@fortawesome/pro-regular-svg-icons";
import { TranslateModule } from "@ngx-translate/core";
import {
  AuthenticationService,
  UserRole,
} from "../../../../core/authentication/authentication.service";
import { PortbaseExportAaxStatusIndicatorComponent } from "../../../components/status-indicator/status-indicator.component";
import { ArrivalAtExitStatus, ShipmentDto } from "../../../services/shipment.service";
import { resolveDocumentType } from "../../../utils/resolve-document-type.utils";
import { HighlightPipe } from "../../../../core/pipes/highlight.pipe";

@Component({
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    PortbaseExportAaxStatusIndicatorComponent,
    MatTableModule,
    FontAwesomeModule,
    MatButtonModule,
    HighlightPipe,
  ],
  selector: "pbe-arrival-at-exit-operational-view-table",
  templateUrl: "./operational-view-table.component.html",
  styleUrl: "./operational-view-table.component.scss"
})
export class ArrivalAtExitOperationalViewTableComponent {
  @Input({ required: true }) shipments!: ShipmentDto[];
  @Input() search: string | null = null;

  authenticationData = inject(AuthenticationService).authenticated;
  userRoles = inject(AuthenticationService).userRoles;

  router = inject(Router);
  route = inject(ActivatedRoute);

  ArrivalAtExitStatus = ArrivalAtExitStatus;

  faChevronRight = faChevronRight;

  displayedColumns = [
    "assigned",
    "documentNumber",
    "documentType",
    "creationDate",
    "reference",
    "arrivalAtExitStatus",
    "actions",
  ];

  openDetailsPage(entity: ShipmentDto) {
    const shouldGoToEdit = this.canEdit(entity);

    this.router.navigate([], {
      relativeTo: this.route,
      queryParamsHandling: "merge",
      queryParams: {
        view: shouldGoToEdit ? "edit" : "details",
        id: entity.shipmentId,
      },
    });
  }

  canEdit(entity: ShipmentDto) {
    return (
      entity.registeredByOrg === this.authenticationData().data?.credentials &&
      (entity.arrivalAtExitStatus ===
        ArrivalAtExitStatus.ARRIVAL_AT_EXIT_NOT_YET_REPORTED_TO_CUSTOMS ||
        entity.arrivalAtExitStatus ===
          ArrivalAtExitStatus.EXPORT_SHIPMENT_REJECTED_AT_CUSTOMS)
    );
  }

  mapDocumentType(documentType: string) {
    return resolveDocumentType(documentType);
  }

  isTerminal() {
    return this.userRoles().includes(UserRole.TerminalOperator);
  }
}
