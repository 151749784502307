import { CommonModule } from "@angular/common";
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  Renderer2,
  SimpleChanges,
  inject,
} from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatTabsModule } from "@angular/material/tabs";
import { MatToolbarModule } from "@angular/material/toolbar";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import {
  faAngleDown,
  faArrowRightFromBracket,
} from "@fortawesome/pro-light-svg-icons";
import { faTimes } from "@fortawesome/pro-regular-svg-icons";
import { TranslateModule } from "@ngx-translate/core";
import { ButtonIconDirective } from "@portbase/material/button";
import { endOfDay, format, startOfDay, subYears } from "date-fns";
import { PortbaseExportTabBarComponent } from "../../../../components";
import { AppConfigService } from "../../../../core/services/app-config.service";
import { OrganisationService } from "../../../../core/services/organisation.service";
import { formatArrivalLocationAddress } from "../../../../core/utils/address-formatter.utils";
import { ArrivalLocationService } from "../../../services/arrival-location.service";
import { PortbaseExportRejectionReasonsComponent } from "../../../components/rejection-reasons/rejection-reasons.component";
import { PortbaseExportAaxStatusIndicatorComponent } from "../../../components/status-indicator/status-indicator.component";
import { ShipmentHistoryComponent } from "../../../history/shipment-history/shipment-history.component";
import { InspectionTypeService } from "../../../services/inspection-type.service";
import { ShipmentHistoryService } from "../../../services/shipment-history.service";
import { ArrivalAtExitStatus, ShipmentDto } from "../../../services/shipment.service";

@Component({
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    MatToolbarModule,
    MatButtonModule,
    PortbaseExportTabBarComponent,
    PortbaseExportAaxStatusIndicatorComponent,
    PortbaseExportRejectionReasonsComponent,
    FontAwesomeModule,
    ButtonIconDirective,
    MatTabsModule,
    MatFormFieldModule,
    MatInputModule,
    ShipmentHistoryComponent,
  ],
  selector: "pbe-arrival-at-exit-customer-service-details-page",
  templateUrl: './customer-service-details-page.component.html',
  styleUrl: './customer-service-details-page.component.scss'
})
export class ArrivalAtExitCustomerServiceDetailsPageComponent
  implements OnChanges
{
  @Input({ required: true }) shipment!: ShipmentDto;

  faTimes = faTimes;
  faArrowRightFromBracket = faArrowRightFromBracket;
  faAngleDown = faAngleDown;

  arrivalLocationsService = inject(ArrivalLocationService);

  organisationService = inject(OrganisationService);

  inspectionTypeService = inject(InspectionTypeService);
  inspectionTypeQuery =
    this.inspectionTypeService.getInspectionTypeForShipmentId(null);
  inspectionType = this.inspectionTypeQuery.result;

  shipmentHistoryService = inject(ShipmentHistoryService);
  shipmentHistoryQuery =
    this.shipmentHistoryService.getHistoryForShipmentId(null);
  shipmentHistory = this.shipmentHistoryQuery.result;

  ArrivalAtExitStatus = ArrivalAtExitStatus;

  @Output() detailsClose = new EventEmitter();

  @Input() userCanAssignCargoHandlingAgent = false;

  activeTabIndex = 0;
  tabs = ["details", "history"];

  renderer = inject(Renderer2);
  appConfigService = inject(AppConfigService);

  ngOnChanges(changes: SimpleChanges): void {
    if (changes["shipment"].currentValue) {
      const shipment = changes["shipment"].currentValue as ShipmentDto;
      this.inspectionTypeQuery.updateOptions(
        this.inspectionTypeService.inspectionTypeQueryOptions(
          shipment.shipmentId,
          shipment.arrivalAtExitStatus,
        ),
      );

      this.shipmentHistoryQuery.updateOptions(
        this.shipmentHistoryService.shipmentHistoryQueryOptions(
          shipment.shipmentId,
        ),
      );
    }
  }

  onClose() {
    this.detailsClose.emit();
  }

  getArrivalLocationAsString(): string {
    const arrivalLocation =
      this.arrivalLocationsService.getArrivalLocationForId(
        this.shipment.arrivalLocationId,
      );
    if (!arrivalLocation) {
      return "";
    }
    return formatArrivalLocationAddress(arrivalLocation);
  }

  getCargoHandlingAgentAsString(): string {
    const cargoHandlingAgent =
      this.organisationService.getCargoHandlingAgentForId(
        this.shipment.assignedToOrg,
      );
    if (!cargoHandlingAgent) {
      return "";
    }
    return cargoHandlingAgent.name;
  }

  getTerminalAsString(): string {
    const terminal = this.organisationService.getTerminalForId(
      this.shipment.registeredByOrg,
    );
    if (!terminal) {
      return "";
    }
    return terminal.name;
  }

  openMessageManagement(messageReferenceId: string): void {
    const form = this.renderer.createElement("form");
    form.action = `${this.appConfigService.getConfig().wpcsBaseUrl}/messagecontrol/searchmessage.do`;
    form.method = "POST";
    form.target = "messagemanagement";
    form.style.display = "none"; // Make sure the form is not visible on the screen

    const creationFromDate = startOfDay(subYears(new Date(), 1));
    const creationToDate = endOfDay(new Date());

    const data: Record<string, string | boolean> = {
      currentActionRouter: "messageOverview",
      actionRouter: "messageOverview",
      actionName: "filterMessagesPM",
      actionValue: true,
      screenId: "S087",
      fndMessageCreationFromDateTime: format(
        creationFromDate,
        "dd-MM-yyyy HH:mm",
      ),
      fndMessageCreationFromDate: format(creationFromDate, "dd-MM-yyyy"),
      fndMessageCreationFromTime: format(creationFromDate, "HH:mm"),
      fndMessageCreationToDateTime: format(creationToDate, "dd-MM-yyyy HH:mm"),
      fndMessageCreationToDate: format(creationToDate, "dd-MM-yyyy"),
      fndMessageCreationToTime: format(creationToDate, "HH:mm"),
      fndProcessId: messageReferenceId,
      deeplink: true,
    };

    for (const key in data) {
      const input = this.renderer.createElement("textarea");
      input.name = key;
      input.value = data[key];
      form.appendChild(input);
    }

    this.renderer.appendChild(document.body, form);
    form.submit();
    this.renderer.removeChild(document.body, form);
  }
}
