import {
  Directive,
  ElementRef,
  HostListener,
  Inject,
  InjectionToken,
  Input,
} from "@angular/core";
import { AmplitudeService } from "./amplitude.service";

const WINDOW = new InjectionToken<Window>("WindowToken", {
  providedIn: "root",
  factory: () => window,
});

@Directive({
  selector: "a[pbAmplitudeLocationEvent]",
})
export class AmplitudeLocationEventDirective {
  @Input("pbAmplitudeLocationEvent") eventType!: string;
  @Input() eventProperties?: Record<string, unknown>;

  constructor(
    private elementRef: ElementRef,
    @Inject(WINDOW) private window: Window,
    private amplitudeService: AmplitudeService,
  ) {}

  @HostListener("click", ["$event"])
  onClick(event: PointerEvent): void {
    event.preventDefault();

    this.amplitudeService
      .trackEvent(this.eventType, this.eventProperties)
      .finally(() => {
        const href = this.elementRef.nativeElement.getAttribute("href");
        this.window.location.assign(href);
      });
  }
}
