import { Component, Input } from "@angular/core";
import { NgClass } from "@angular/common";

@Component({
  selector: "pbe-data-item",
  standalone: true,
  templateUrl: "./data-item.component.html",
  imports: [NgClass],
  styleUrl: "./data-item.component.scss",
})
export class PortbaseDataItemComponent {
  @Input({ required: true }) label!: string;
  @Input({ required: true }) value!: string | number | Date | boolean;
  @Input() textWarp = false;
}
