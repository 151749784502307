import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";
import { environment } from "../../../environments/environment";

const DOCUMENT_NUMBER_MOCK_PREFIXES = ["AP-", "INS-", "NOT-", "RE-", "DR-"];
const DOCUMENT_NUMBER_MOCK_PREFIX = /^(AP-|INS-|NOT-|RE-|DR-)/;
const DOCUMENT_NUMBER_YEAR_REG_EXP = /^[0-9]{2}$/;
const DOCUMENT_NUMBER_COUNTRY_REG_EXP =
  /^(A[^ABCHJKNPVY]|B[^CKPUX]|C[^BEJPQST]|D[EJKMOZ]|E[CEGHRST]|F[IJKMOR]|G[^CJKOVXZ]|H[KMNRTU]|I[DEL-OQ-T]|J[EMOP]|K[EGHIMNPRWYZ]|L[ABCIKR-VY]|M[^BIJ]|N[ACEFGILOPRUZ]|OM|P[AE-HK-NRSTWY]|QA|R[EOSUW]|S[^FPQUW]|T[^ABEIPQSUXY]|U[AGMSYZ]|V[ACEGINU]|WF|WS|YE|YT|Z[AMW])$/;
const DOCUMENT_NUMBER_ALPHANUMERIC_REG_EXP = /^[0-9A-Z]{12}$/;
const DOCUMENT_NUMBER_CATEGORY_REG_EXP = /^[0-9A-Z]$/;
const DOCUMENT_NUMBER_CHECK_DIGIT_REG_EXP = /^[0-9]$/;

export const DOCUMENT_NUMBER_LENGTH = 18;

const CHAR_VALUES: { [key: string]: number } = {
  A: 10,
  B: 12,
  C: 13,
  D: 14,
  E: 15,
  F: 16,
  G: 17,
  H: 18,
  I: 19,
  J: 20,
  K: 21,
  L: 23,
  M: 24,
  N: 25,
  O: 26,
  P: 27,
  Q: 28,
  R: 29,
  S: 30,
  T: 31,
  U: 32,
  V: 34,
  W: 35,
  X: 36,
  Y: 37,
  Z: 38,
};

interface DocumentNumberParts {
  year: string;
  country: string;
  alphaNumeric: string;
  category: string;
  checkDigit: string;
}

// The function and check digit validation logic is based on the
// ISO6346ValidationService found in the export-frontend repository.
// It is adapted to work with the document number instead of equipment number.
function calculateSum(documentNumber: string): number {
  return documentNumber
    .slice(0, -1)
    .split("")
    .reduce((sum: number, char: string, index: number) => {
      const charValue = isNaN(Number(char)) ? CHAR_VALUES[char] : Number(char);
      const positionMultiplier = Math.pow(2, index);

      return sum + charValue * positionMultiplier;
    }, 0);
}

// This function validates the given document number based on the
// specification found in https://portbasebv.atlassian.net/browse/ER-536
export function validateDocumentNumber(
  value: string,
  allowPrefixes = false,
): null | ValidationErrors {
  const documentNumberHasPrefix = DOCUMENT_NUMBER_MOCK_PREFIXES.some((prefix) =>
    value.startsWith(prefix),
  );
  const documentNumber =
    documentNumberHasPrefix && allowPrefixes
      ? value.replace(DOCUMENT_NUMBER_MOCK_PREFIX, "")
      : value;

  if (documentNumber.length < DOCUMENT_NUMBER_LENGTH) {
    return { minlength: true };
  }

  if (documentNumber.length > DOCUMENT_NUMBER_LENGTH) {
    return { maxlength: true };
  }

  const parts: DocumentNumberParts = {
    year: documentNumber.slice(0, 2),
    country: documentNumber.slice(2, 4),
    alphaNumeric: documentNumber.slice(4, 16),
    category: documentNumber.slice(16, 17),
    checkDigit: documentNumber.slice(17),
  };

  if (!DOCUMENT_NUMBER_YEAR_REG_EXP.test(parts.year)) {
    return { invalidYear: true };
  }

  if (!DOCUMENT_NUMBER_COUNTRY_REG_EXP.test(parts.country)) {
    return { invalidCountry: true };
  }

  if (!DOCUMENT_NUMBER_ALPHANUMERIC_REG_EXP.test(parts.alphaNumeric)) {
    return { invalidAlphaNumeric: true };
  }

  if (!DOCUMENT_NUMBER_CATEGORY_REG_EXP.test(parts.category)) {
    return { invalidCategory: true };
  }

  if (!DOCUMENT_NUMBER_CHECK_DIGIT_REG_EXP.test(parts.checkDigit)) {
    return { invalidCheckDigit: true };
  }

  const sum = calculateSum(documentNumber);

  const providedCheckDigit = Number(documentNumber.slice(-1));
  let calculatedCheckDigit = sum - Math.floor(sum / 11) * 11;

  if (calculatedCheckDigit === 10) {
    calculatedCheckDigit = 0;
  }

  const validChecksum = providedCheckDigit === calculatedCheckDigit;
  return validChecksum ? null : { invalidChecksum: true };
}

export function documentNumberValidator(): ValidatorFn {
  return (control: AbstractControl<string>): ValidationErrors | null => {
    const { value } = control;
    return validateDocumentNumber(value, environment.production === false);
  };
}
