import { TippyConfig } from '@ngneat/helipopper/lib/tippy.types';

type Variation = TippyConfig['variations'][0];

export const pbPopperVariation: Variation = {
  theme: 'light',
  arrow: false,
  animation: 'fade',
  trigger: 'click',
  placement: 'bottom',
  interactive: true,
  maxWidth: '',
};
