import { CommonModule, NgClass, NgFor, NgIf } from "@angular/common";
import { Component, DestroyRef, Input, OnInit } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { FormGroup, ReactiveFormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import {
  faCircleExclamation,
  faWarning,
} from "@fortawesome/pro-regular-svg-icons";
import { TippyDirective } from "@ngneat/helipopper";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { ButtonIconDirective } from "@portbase/material/button";

@Component({
  selector: "pbe-em-document-number-form-input",
  standalone: true,
  imports: [
    NgIf,
    NgFor,
    CommonModule,
    MatFormFieldModule,
    TranslateModule,
    MatInputModule,
    ReactiveFormsModule,
    NgClass,
    FontAwesomeModule,
    ButtonIconDirective,
    MatButtonModule,
    TippyDirective,
  ],
  templateUrl: './document-number-form-input.component.html',
  styleUrl: './document-number-form-input.component.scss'
})
export class PortbaseExportEmDocumentNumberFormInputComponent
  implements OnInit
{
  @Input({ required: true }) formGroup!: FormGroup;
  @Input({ required: true }) controlName!: string;
  @Input({ required: true }) ignoreLengthValidation!: boolean;

  faCircleExclamation = faCircleExclamation;
  faWarning = faWarning;

  constructor(
    private translateService: TranslateService,
    private destroyRef: DestroyRef,
  ) {}

  ngOnInit(): void {
    this.formGroup
      .get("documentType")
      ?.valueChanges.pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.formGroup.get(this.controlName)?.updateValueAndValidity();
      });

    const isCopiedFromMael = this.formGroup.get("copiedFromMael")?.value;

    if (isCopiedFromMael) {
      this.formGroup.get("documentNumber")?.disable();
      this.formGroup.get("documentType")?.disable();
    }
  }

  get errorLabel(): string | undefined {
    const control = this.formGroup.get(this.controlName);

    if (control === null || control.untouched) {
      return undefined;
    }

    if (control.hasError("required")) {
      return this.translateService.instant("general.forms.errors.required");
    }

    if (!this.ignoreLengthValidation) {
      if (control.hasError("minlength")) {
        return this.translateService.instant(
          "services.exportManifest.createPage.errors.minlength",
          { requiredLength: 0 },
        );
      }

      if (control.hasError("maxlength")) {
        return this.translateService.instant(
          "services.exportManifest.createPage.errors.maxlength",
          { requiredLength: 27 },
        );
      }
    }

    const [error] = Object.keys(control.errors ?? []);
    return this.translateService.instant(
      "general.forms.errors.documentNumber." + error,
    );
  }

  hasErrors(controlName: string) {
    const errors = Object.keys(
      this.formGroup.controls[controlName]?.errors ?? [],
    );

    const errorKeys = ["required", "duplicateDocumentNumber"];

    if (!this.ignoreLengthValidation) {
      errorKeys.push("minlength", "maxlength");
    }

    return errors.some((key) => errorKeys.includes(key));
  }
}
