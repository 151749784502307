import { APP_INITIALIZER, InjectionToken, ModuleWithProviders, NgModule } from '@angular/core';
import { BeamerService } from './beamer.service';
import { BeamerConfig } from './beamer-config.model';
import { beamerInitializer } from './beamer-initializer';

export const BEAMER_CONFIG = new InjectionToken<BeamerConfig>('BEAMER_CONFIG');

@NgModule()
export class BeamerModule {
  static forRoot(config: BeamerConfig): ModuleWithProviders<BeamerModule> {
    const configProvider = config ? [{ provide: BEAMER_CONFIG, useValue: config }] : [];
    return {
      ngModule: BeamerModule,
      providers: [
        ...configProvider,
        {
          provide: APP_INITIALIZER,
          useFactory: beamerInitializer,
          deps: [BEAMER_CONFIG, BeamerService],
          multi: true
        },
        BeamerService
      ]
    }
  };
}

