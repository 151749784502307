<div class="flex flex-col items-start w-full text-sm my-3">
  <div class="flex-grow-0 flex-shrink w-full">
    <h1 class="font-medium">
      {{
        "preferences.notifications.types." + notificationType | translate
      }}
    </h1>

    <span>
          {{
        "preferences.notifications.types.descriptions." + notificationType
          | translate
      }}
        </span>
  </div>

  <div class="flex flex-col">
    <div class="flex items-center flex-row">
      @if (showDetails) {
        @if (canEdit) {
          <div class="flex flex-col w-96">
            <form [formGroup]="formGroup">
              <div formArrayName="email">
                @for (
                    group of formGroup.controls.email.controls;
                  track $index
                  ) {
                  <div class="relative" [formGroupName]="$index">
                    <mat-form-field
                      class="w-full !mt-2"
                      [formGroup]="group"
                    >
                      <input matInput formControlName="email" type="text" />
                    </mat-form-field>
                    @if ($index > 0) {
                      <div
                        class="absolute -right-[35px] top-1/2 -translate-y-1/2"
                      >
                        <button
                          mat-icon-button
                          [icon]="faTimes"
                          (click)="
                                formGroup.controls.email.removeAt($index)
                              "
                        ></button>
                      </div>
                    }
                  </div>

                  @if (group.get("email")?.hasError("email")) {
                    <mat-error class="text-xs">{{
                        "general.forms.errors.email" | translate
                      }}</mat-error>
                  }
                }
              </div>
            </form>

            <div class="flex justify-between items-center my-2">
              <button
                mat-button
                color="primary"
                class="!p-0"
                [icon]="faPlus"
                (click)="addAnotherEmailAddress()"
              >
                {{
                  "preferences.arrivalAtExit.fields.addAnotherButtonText"
                    | translate
                }}
              </button>

              <button
                mat-stroked-button
                type="button"
                (click)="clickHideDetails()"
              >
                Save
              </button>
            </div>
          </div>
        } @else {
          <div class="flex flex-col mb-3 w-full">
            <h1 class="font-medium">Subscribed email addresses:</h1>

            <div class="flex flex-col w-full">
              @for (email of emailList; track $index) {
                <mat-form-field class="w-96 !mt-2">
                  <input
                    matInput
                    [value]="email"
                    [disabled]="true"
                    type="text"
                  />
                </mat-form-field>
              }
            </div>
          </div>
        }
      } @else {
        <div class="line-clamp-2 text-slate-700 break-word my-2">
          {{
            "preferences.notifications.emailAddressesSubscribed"
              | pluralTranslate: emailList.length
              | translate: { emails: emailList.length }
          }}
        </div>
      }

      @if (!showDetails) {
        @if (canEdit) {
          <button
            class="absolute -right-1"
            mat-icon-button
            (click)="clickShowDetails()"
          >
            <fa-icon [icon]="faPencil"></fa-icon>
          </button>
        }

        @if (!canEdit && emailList.length > 0) {
          <button
            class="absolute -right-1"
            mat-icon-button
            (click)="clickShowDetails()"
          >
            <fa-icon [icon]="faEye"></fa-icon>
          </button>
        }
      }
    </div>

    @if (showDetails && !canEdit) {
      <div class="flex justify-end mb-2">
        <button
          mat-stroked-button
          type="button"
          (click)="clickHideDetails()"
        >
          Hide
        </button>
      </div>
    }

    @if (!showDetails) {
      <mat-slide-toggle
        [checked]="isUserSubscribed"
        (change)="toggleSubscription.emit($event.checked)"
      >
        {{
          "preferences.notifications.sendToMyEmailAddress"
            | translate: { email: profile().data?.emailAddress }
        }}
      </mat-slide-toggle>
    }
  </div>
</div>
