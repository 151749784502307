import { Component, Input } from "@angular/core";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { TranslateModule } from "@ngx-translate/core";
import {
  ShipmentHistoryDto,
  ShipmentOperationType,
} from "../../services/shipment-history.service";
import { HistoryItemAcceptedComponent } from "../operations/history-item-accepted/history-item-accepted.component";
import { HistoryItemCargoHandlingAgentChangedComponent } from "../operations/history-item-cargo-handling-agent-changed/history-item-cargo-handling-agent-changed.component";
import { HistoryItemDetailsChangedComponent } from "../operations/history-item-details-changed/history-item-details-changed.component";
import { HistoryItemInspectionComponent } from "../operations/history-item-inspection/history-item-inspection.component";
import { HistoryItemRejectionComponent } from "../operations/history-item-rejection/history-item-rejection.component";
import { HistoryItemReleasedComponent } from "../operations/history-item-released/history-item-released.component";
import { ShipmentHistoryWrapperComponent } from "../shipment-history-wrapper/shipment-history-wrapper.component";

@Component({
  selector: "pbe-shipment-history",
  standalone: true,
  imports: [
    FontAwesomeModule,
    TranslateModule,
    ShipmentHistoryWrapperComponent,
    HistoryItemDetailsChangedComponent,
    HistoryItemCargoHandlingAgentChangedComponent,
    HistoryItemAcceptedComponent,
    HistoryItemReleasedComponent,
    HistoryItemRejectionComponent,
    HistoryItemInspectionComponent,
  ],
  templateUrl: './shipment-history.component.html',
  styleUrl: './shipment-history.component.scss'
})
export class ShipmentHistoryComponent {
  @Input({ required: true }) history!: ShipmentHistoryDto;
  @Input({ required: true }) isMostRecent!: boolean;

  ShipmentOperationType = ShipmentOperationType;
}
