import { NgFor } from "@angular/common";
import { Component, EventEmitter, Input, Output, inject } from "@angular/core";
import {
  FormArray,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatPseudoCheckboxModule } from "@angular/material/core";
import {
  MAT_FORM_FIELD_DEFAULT_OPTIONS,
  MatFormFieldDefaultOptions,
  MatFormFieldModule,
} from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import {
  faCheck,
  faEye,
  faEyeSlash,
  faPencil,
  faPlus,
  faTimes,
} from "@fortawesome/pro-light-svg-icons";
import { TranslateModule } from "@ngx-translate/core";
import { PBAutocompleteModule } from "@portbase/material/autocomplete";
import { ButtonIconDirective } from "@portbase/material/button";
import { AuthenticationService } from "../../../core/authentication/authentication.service";
import { PluralTranslatePipe } from "../../../core/pipes/plural-translate.pipe";

@Component({
  selector: "pbe-email-address-list",
  standalone: true,
  imports: [
    NgFor,
    MatCheckboxModule,
    MatPseudoCheckboxModule,
    MatInputModule,
    MatFormFieldModule,
    MatButtonModule,
    MatSlideToggleModule,
    ReactiveFormsModule,
    TranslateModule,
    FontAwesomeModule,
    ButtonIconDirective,
    PBAutocompleteModule,
    PluralTranslatePipe,
  ],
  providers: [
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: <MatFormFieldDefaultOptions>{
        appearance: "outline",
        floatLabel: "always",
      },
    },
  ],
  templateUrl: "./email-address-list.component.html",
  styleUrl: "./email-address-list.component.scss",
})
export class EmailAddressListComponent {
  @Input({ required: true }) notificationType!: string;
  @Input({ required: true }) isUserSubscribed!: boolean;
  @Input({ required: true }) canEdit!: boolean;

  @Output() toggleSubscription = new EventEmitter<boolean>();

  profile = inject(AuthenticationService).getProfile().result;

  faPencil = faPencil;
  faCheck = faCheck;
  faEye = faEye;
  faEyeSlash = faEyeSlash;
  faPlus = faPlus;
  faTimes = faTimes;

  showDetails = false;

  @Input() emailList: string[] = [];
  @Output() emailListChange = new EventEmitter<string[]>();

  formGroup = new FormGroup({
    email: new FormArray(
      this.emailList.map(
        (email) =>
          new FormGroup({
            email: new FormControl(email, Validators.email),
          }),
      ),
    ),
  });

  addAnotherEmailAddress() {
    this.formGroup.controls.email.push(
      new FormGroup({
        email: new FormControl("", Validators.email),
      }),
    );
  }

  clickShowDetails() {
    if (this.canEdit) {
      const emails = this.emailList.length > 0 ? this.emailList : [""];
      this.formGroup = new FormGroup({
        email: new FormArray(
          emails.map(
            (email) =>
              new FormGroup({
                email: new FormControl(email, Validators.email),
              }),
          ),
        ),
      });
    }
    this.showDetails = true;
  }

  clickHideDetails() {
    if (this.canEdit) {
      // Submit list of email addresses
      const list = this.formGroup.controls.email.value
        .map((email) => email.email as string)
        .filter(Boolean);

      this.emailListChange.emit(list);
    }

    this.showDetails = false;
  }
}
