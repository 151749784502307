<div class="block">
  <mat-form-field [formGroup]="searchForm">
    <mat-label>
      {{ "services.exportManifest.createPage.vesselVisit.title" | translate }}
    </mat-label>

    <fa-icon
      matSuffix
      class="autocomplete-input-icon"
      size="lg"
      [icon]="faMagnifyingGlass"
    ></fa-icon>

    <input
      #inputRef
      matInput
      [placeholder]="
          'services.exportManifest.createPage.vesselVisit.placeholder'
            | translate
        "
      formControlName="search"
      [matAutocomplete]="auto"
    />
  </mat-form-field>

  <mat-autocomplete
    #auto="matAutocomplete"
    [hideSingleSelectionIndicator]="true"
    [displayWith]="displayFn"
    class="flex flex-col gap-2"
    (optionSelected)="onOptionSelected($event.option.value)"
  >
    <mat-option
      *ngFor="let vesselVisit of vesselVisits().data"
      [value]="vesselVisit"
      [disabled]="isCallReferenceNumberInUse(vesselVisit.callReferenceNumber)"
      [ngClass]="{
          'crn-in-use': isCallReferenceNumberInUse(
            vesselVisit.callReferenceNumber
          )
        }"
      class="!min-h-16"
    >
      <div class="flex justify-between items-start flex-row w-full gap-2">
        <div class="flex flex-col gap-1 w-1/3 min-w-52">
          <div class="font-medium font-sans text-sm text-gray-800">
            {{ vesselVisit.vesselName }} ({{ vesselVisit.vesselImoCode }})
          </div>
          <div class="font-normal font-sans text-sm text-gray-800">
            {{ vesselVisit.callReferenceNumber }}
          </div>
        </div>

        <div class="flex flex-col gap-1 w-1/3 items-start">
          <div class="flex flex-row gap-2">
            <div class="font-medium font-sans text-sm text-gray-800">
              {{ getArrivalTimeLabel(vesselVisit) }}
            </div>
            <div class="font-normal font-sans text-sm text-gray-600">
              {{ getArrivalTime(vesselVisit) | date: "dd MMM HH:mm" }}
            </div>
          </div>

          <div class="flex flex-row gap-2">
            <div class="font-medium font-sans text-sm text-gray-800">
              {{ getDepartureTimeLabel(vesselVisit) }}
            </div>
            <div class="font-normal font-sans text-sm text-gray-600">
              {{ getDepartureTime(vesselVisit) | date: "dd MMM HH:mm" }}
            </div>
          </div>
        </div>

        <div class="flex flex-col gap-1 w-1/3 items-start">
          <div class="font-medium font-sans text-sm text-gray-800">
            {{
              "general.vesselStatus.short." + vesselVisit.vesselStatus
                | translate
            }}
          </div>

          @if (isCallReferenceNumberInUse(vesselVisit.callReferenceNumber)) {
            <div class=" font-normal font-sans text-sm text-gray-800">
              {{
                "services.exportManifest.createPage.vesselVisit.manifestExists"
                  | translate
              }}
            </div>
          }
        </div>

        <button
          class="flex !pointer-events-auto flex-row pl-3 my-auto float-right text-sm font-medium text-pb-blue-700 hover:underline hover:text-pb-blue-900 active:text-pb-blue-950"
          [ngClass]="{
              invisible: !isCallReferenceNumberInUse(
                vesselVisit.callReferenceNumber
              )
            }"
          (click)="
              redirectToManifestDetails(
                $event,
                getManifestIdByCallReferenceNumber(
                  vesselVisit.callReferenceNumber
                )
              )
            "
        >
          {{
            "services.exportManifest.createPage.vesselVisit.view" | translate
          }}
          <fa-icon class="ml-2" [icon]="faArrowRight"></fa-icon>
        </button>
      </div>
    </mat-option>
  </mat-autocomplete>
</div>
