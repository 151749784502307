import { HttpClient } from "@angular/common/http";
import { Injectable, inject } from "@angular/core";
import { injectQuery, queryOptions } from "@ngneat/query";
import { AppConfigService } from "../../core/services/app-config.service";
import { ArrivalAtExitStatus } from "./shipment.service";

export interface RejectionReason {
  code: string;
  description: string;
}

export interface RejectionReasonResponse {
  rejectionType: "DEC" | "DRN";
  reasons: RejectionReason[];
}

@Injectable({ providedIn: "root" })
export class RejectionReasonsService {
  #http = inject(HttpClient);
  #query = injectQuery();

  private readonly _appConfigService = inject(AppConfigService);
  private readonly _baseUrl = `${this._appConfigService.getConfig().apiUrl}`;

  rejectionReasonsQueryOptions(id: string | null, status: ArrivalAtExitStatus) {
    return queryOptions({
      queryKey: ["shipments", id, "rejection-reasons"] as const,
      queryFn: () => {
        return this.#http.get<RejectionReasonResponse>(
          `${this._baseUrl}/shipments/${id}/rejection-reason`
        );
      },
      enabled:
        id !== null &&
        status === ArrivalAtExitStatus.EXPORT_SHIPMENT_REJECTED_AT_CUSTOMS,
    });
  }

  getRejectionReasonsForShipmentId(id: string | null) {
    return this.#query(
      this.rejectionReasonsQueryOptions(
        id,
        ArrivalAtExitStatus.ARRIVAL_AT_EXIT_NOT_YET_REPORTED_TO_CUSTOMS
      )
    );
  }
}
