import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";

export function maxOneMonthOldValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const creationDate = new Date(control.value);
    const now = new Date();
    const oneMonthAgo = new Date();
    oneMonthAgo.setMonth(now.getMonth() - 1);

    return creationDate >= oneMonthAgo && creationDate <= now
      ? null
      : { maxOneMonthOld: true };
  };
}
