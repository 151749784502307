import { DOCUMENT } from "@angular/common";
import { Inject, Injectable, Renderer2, RendererFactory2 } from "@angular/core";
import { BeamerConfig, BeamerParameters } from "./beamer-config.model";
import { BEAMER_CONFIG } from "./beamer.module";

interface Beamer {
  update(parameters: BeamerParameters): void;
}

declare const Beamer: Beamer;

export interface BeamerServiceInterface {
  inject(): void;
  isDefined(): boolean;
  updateLanguage(language: string): void;
}

@Injectable()
export class BeamerService implements BeamerServiceInterface {
  private renderer: Renderer2;

  constructor(
    readonly rendererFactory: RendererFactory2,
    @Inject(DOCUMENT) private readonly document: Document,
    @Inject(BEAMER_CONFIG) private config: BeamerConfig,
  ) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  inject(): void {
    if (!this.config.parameters?.product_id) {
      throw new Error(
        `Beamer 'product_id' is not configured. Please make sure the 'product_id' is set in the configuration.`,
      );
    }

    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
    (this.document.defaultView as any).beamer_config = this.config.parameters;

    const script = this.renderer.createElement("script");
    script.src = "https://app.getbeamer.com/js/beamer-embed.js";
    script.type = "text/javascript";
    script.defer = "defer";

    this.renderer.appendChild(this.document.body, script);
  }

  isDefined(): boolean {
    return typeof Beamer !== "undefined";
  }

  updateLanguage(language: string): void {
    if (!this.isDefined()) {
      return;
    }

    // Note: this will also initialize Beamer if not already initialized
    Beamer.update({ language });
  }

  updateUserId(iamcUserId: string, serviceId: string): void {
    if (!this.isDefined()) {
      setTimeout(() => {
        this.updateUserId(iamcUserId, serviceId);
      }, 1000);
      return;
    }

    // Note: this will also initialize Beamer if not already initialized
    Beamer.update({
      user_id: serviceId + "_" + iamcUserId,
      filter: `${serviceId};all;pcs`,
    });
  }
}
