<div class="h-full flex flex-row w-full">
  <pb-sidenav
    [navigationOptions]="navigationOptions"
    [navigationListTitle]="'navigation.servicesTitle' | translate"
  >
    <div class="flex-grow">
      <router-outlet></router-outlet>
    </div>
  </pb-sidenav>
</div>
