<div class="h-full flex flex-col">
  <mat-toolbar>
    <div class="flex flex-row w-full">
      <div class="flex-grow flex flex-row items-center gap-4">
        <button mat-icon-button [icon]="faTimes" (click)="onClose()"></button>
        <h1 class="text-xl text-gray-700 ml-6 items-center">
          {{ "services.arrivalAtExit.editPage.header" | translate }}
        </h1>
      </div>
      <div class="lg:pr-16">
        <button
          class="border border-red-500 shadow-sm rounded text-red-700 px-4 py-2 text-sm font-medium hover:bg-red-200 active:bg-red-300"
          (click)="onDelete()"
        >
          <fa-icon [icon]="faTrash"></fa-icon>
          <span class="ml-3">{{
              "services.arrivalAtExit.editPage.buttons.delete" | translate
            }}</span>
        </button>
      </div>
    </div>
  </mat-toolbar>
  <div class="bg-slate-100 flex-grow p-8 w-full flex-col">
    @if (shipment !== undefined && form !== undefined) {
      <pbe-arrival-at-exit-form
        [showCargoHandlingAgentField]="userCanAssignCargoHandlingAgent"
        [form]="form"
        (cancel)="onClose()"
        (save)="onSave()"
        (sendToCustoms)="onSendToCustoms()"
        [isSaving]="isSaving()"
        [isSendingToCustoms]="isSendingToCustoms()"
      >
        <div class="mt-3 mb-3 text-sm">
          <label class="font-medium">{{
              "services.arrivalAtExit.detailsPage.detailsTab.fields.arrivalAtExitStatus"
                | translate
            }}</label>
          <div class="mt-2">
            <pbe-aax-status-indicator
              [status]="shipment.arrivalAtExitStatus"
            ></pbe-aax-status-indicator>

            <pbe-rejection-reasons [shipment]="shipment" />
          </div>
          @if (
            shipment.arrivalAtExitStatus !==
            ArrivalAtExitStatus.ARRIVAL_AT_EXIT_NOT_YET_REPORTED_TO_CUSTOMS
            ) {
            <div class="mt-4">
              <label class="font-medium">{{
                  "services.arrivalAtExit.detailsPage.detailsTab.fields.arrivalTimeAndDate"
                    | translate
                }}</label>
              <div class="text-sm text-slate-800 mt-2">
                {{ shipment.arrivalTimeAndDate | date: "dd-MM-yyyy HH:mm" }}
              </div>
            </div>
          }
        </div>
      </pbe-arrival-at-exit-form>
    }
  </div>
</div>
