<mat-action-list>
  <pbe-filter-list-item
    [label]="'services.exportManifest.filters.vesselStatus' | translate"
    [filter]="vesselStatusFilter"
    [formattedFilter]="mapVesselStatusFilters(vesselStatusFilter)"
    (clearButtonClick)="filterIconButtonClick('vesselStatusFilter')"
    [tp]="vesselStatusFilterList"
    [tpData]="vesselStatusFilterOptions"
    [tpVariation]="'popper'"
    [tpPlacement]="'bottom-start'"
    [tpPopperWidth]="260"
    [tpOffset]="[0, 8]"
    (tpVisible)="vesselStatusesFilterVisible = !vesselStatusesFilterVisible"
    [disabled]="disableAllFilters"
  />
  <pbe-filter-list-item
    [label]="'services.exportManifest.filters.manifestStatus' | translate"
    [filter]="manifestStatusFilter"
    [formattedFilter]="mapManifestStatusFilters(manifestStatusFilter)"
    (clearButtonClick)="filterIconButtonClick('manifestStatusFilter')"
    [tp]="manifestStatusFilterList"
    [tpData]="manifestStatusFilterOptions"
    [tpVariation]="'popper'"
    [tpPlacement]="'bottom-start'"
    [tpPopperWidth]="260"
    [tpOffset]="[0, 8]"
    (tpVisible)="manifestStatusesFilterVisible = !manifestStatusesFilterVisible"
    [disabled]="disableAllFilters"
  />
  <pbe-filter-list-item
    [label]="'services.exportManifest.filters.departureDateRange' | translate"
    [filter]="departureDateRange"
    [formattedFilter]="mapDateRangeFilters(departureDateRange)"
    (clearButtonClick)="filterIconButtonClick('departureDateRange')"
    (click)="showDateRangePicker()"
    [disabled]="disableAllFilters"
  />
  @if (showOrganisationsFilter) {
    <pbe-filter-list-item
      [label]="'services.exportManifest.filters.organisation' | translate"
      [filter]="organisationFilter"
      [formattedFilter]="mapOrganisationFilters(organisationFilter)"
      (clearButtonClick)="filterIconButtonClick('organisationFilter')"
      [tp]="organisationFilterList"
      [tpData]="organisationFilterOptions"
      [tpVariation]="'popper'"
      [tpPlacement]="'bottom-start'"
      [tpOffset]="[0, 8]"
      (tpVisible)="organisationFilterVisible = !organisationFilterVisible"
      [isAdmin]="true"
    />
  }
  <ng-template #vesselStatusFilterList>
    <div>
      <div matSubheader>
        {{ "services.exportManifest.filters.vesselStatus" | translate }}
      </div>
      <mat-selection-list
        #selection
        (selectionChange)="
          vesselStatusFilterSelectionChange(selection.selectedOptions)
        "
      >
        <ng-container *ngFor="let option of vesselStatusFilterOptions">
          <mat-list-option
            [value]="option"
            [selected]="vesselStatusFilter?.includes(option.value)"
            color="primary"
            togglePosition="before"
          >
            {{ option.label }}
          </mat-list-option>
        </ng-container>
      </mat-selection-list>
    </div>
  </ng-template>
  <ng-template #manifestStatusFilterList>
    <div>
      <div matSubheader>
        {{ "services.exportManifest.filters.manifestStatus" | translate }}
      </div>
      <mat-selection-list
        #selection
        (selectionChange)="
          manifestStatusFilterSelectionChange(selection.selectedOptions)
        "
      >
        <ng-container *ngFor="let option of manifestStatusFilterOptions">
          <mat-list-option
            [value]="option"
            [selected]="manifestStatusFilter?.includes(option.value)"
            color="primary"
            togglePosition="before"
          >
            {{ option.label }}
          </mat-list-option>
        </ng-container>
      </mat-selection-list>
    </div>
  </ng-template>
  <ng-template #organisationFilterList>
    <div class="w-fit min-w-[260px] flex flex-col">
      <div matSubheader>
        {{ "services.exportManifest.filters.organisation" | translate }}
      </div>

      <div class="flex flex-grow w-full mt-[-1.25rem] px-3.5">
        <mat-form-field class="w-full" [formGroup]="searchForm">
          <input
            matInput
            [placeholder]="
              'services.exportManifest.filters.organisationSearchPlaceholder'
                | translate
            "
            id="search"
            formControlName="search"
          />
          @if (searchForm.value.search) {
            <div matSuffix>
              <button
                mat-icon-button
                type="button"
                [icon]="faTimes"
                (click)="clearSearchQuery()"
              ></button>
            </div>
          }
        </mat-form-field>
      </div>

      @if (filteredOrganisationOptions.length > 0) {
        <mat-selection-list
          #selection
          (selectionChange)="
            organisationFilterSelectionChange(selection.selectedOptions)
          "
          class="max-h-[250px] overflow-y-auto"
        >
          <ng-container *ngFor="let option of filteredOrganisationOptions">
            <mat-list-option
              [value]="option"
              [selected]="organisationFilter?.includes(option.value)"
              color="primary"
              togglePosition="before"
            >
              {{ option.label }}
            </mat-list-option>
          </ng-container>
        </mat-selection-list>
      } @else {
        <div class="flex justify-center items-center h-10">
          {{
            "services.exportManifest.filters.emptyOrganisationSearch"
              | translate
          }}
        </div>
      }
    </div>
  </ng-template>
</mat-action-list>
