import { CommonModule } from "@angular/common";
import { Component, ElementRef, Input, OnInit, ViewChild } from "@angular/core";
import { FormGroup, ReactiveFormsModule } from "@angular/forms";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatButtonModule } from "@angular/material/button";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { faChevronDown } from "@fortawesome/pro-light-svg-icons";
import {
  faCircleExclamation,
  faWarning,
} from "@fortawesome/pro-regular-svg-icons";
import { TippyDirective } from "@ngneat/helipopper";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { ButtonIconDirective } from "@portbase/material/button";
import Fuse from "fuse.js";
import { TextareaAutoresizeDirective } from "../../../directives/textarea-autoresize.directive";
import { ExportManifestService, ExportManifestShipmentDocumentType } from "../../services/export-manifest.service";

const fuseOptions = {
  threshold: 0.4,
  shouldSort: true,
  minMatchCharLength: 3,
  keys: ["value", "label"],
};

export interface AutocompleteOption {
  label: string;
  value: string | boolean;
}

@Component({
  selector: "pbe-em-document-type-form-input",
  standalone: true,
  imports: [
    CommonModule,
    MatFormFieldModule,
    TranslateModule,
    MatInputModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    ButtonIconDirective,
    MatButtonModule,
    TippyDirective,
    MatAutocompleteModule,
    TextareaAutoresizeDirective,
  ],
  templateUrl: './document-type-form-input.component.html',
  styleUrl: './document-type-form-input.component.scss'
})
export class PortbaseExportEmDocumentTypeFormInputComponent {
  @Input({ required: true }) formGroup!: FormGroup;
  @Input({ required: true }) controlName!: string;

  @ViewChild("textareaRef") textareaRef!: ElementRef<HTMLTextAreaElement>;

  faCircleExclamation = faCircleExclamation;
  faWarning = faWarning;
  faChevronDown = faChevronDown;

  documentTypes: Array<{
    value: ExportManifestShipmentDocumentType;
    label: string;
  }>;

  searchableListForDocumentTypes?: Fuse<{ value: string; label: string }>;

  constructor(private translateService: TranslateService, private exportManifestService: ExportManifestService) {
    this.documentTypes = this.exportManifestService.getDocumentTypeOptions()

    this.searchableListForDocumentTypes = new Fuse(
      this.documentTypes,
      fuseOptions,
    );
  }

  getFilteredOptionsForDocumentTypes(
    query: string | undefined,
  ): { value: string; label: string }[] {
    return query && query.length > 2
      ? this.searchableListForDocumentTypes
          ?.search(query)
          .map((result) => result.item) ?? []
      : this.documentTypes;
  }

  displayFn = (option: AutocompleteOption | string): string => {
    const foundType = this.documentTypes.find((type) => type.value === option);
    return foundType ? foundType.label : (option as string);
  };

  get isFocused(): boolean {
    if (!this.textareaRef) {
      return false;
    }

    return this.textareaRef.nativeElement === document.activeElement;
  }

  get errorLabel(): string | undefined {
    const control = this.formGroup.get(this.controlName);

    if (control === null || control.untouched) {
      return undefined;
    }

    if (control.hasError("required")) {
      return this.translateService.instant("general.forms.errors.required");
    }

    if (control.hasError("invalidDocumentType")) {
      return this.translateService.instant(
        "services.exportManifest.createPage.errors.invalidDocumentType",
      );
    }

    return undefined;
  }

  get isDisabled() {
    return (
      this.formGroup.controls[this.controlName].disabled &&
      !this.formGroup.controls[this.controlName].invalid
    );
  }
}
