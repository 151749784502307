<pbe-shipment-history-wrapper
  [history]="history"
  [isMostRecent]="isMostRecent"
>
  @switch (history.operationType) {
    @case (ShipmentOperationType.AAX_SHIPMENT_DETAILS_CHANGED) {
      <pbe-history-item-details-changed
        [history]="history"
      ></pbe-history-item-details-changed>
    }

    @case (ShipmentOperationType.AAX_SHIPMENT_CARGO_HANDLING_AGENT_CHANGED) {
      <pbe-history-item-cargo-handling-agent-changed [history]="history">
      </pbe-history-item-cargo-handling-agent-changed>
    }

    @case (ShipmentOperationType.AAX_EXPORT_SHIPMENT_ACCEPTED_AT_CUSTOMS) {
      <pbe-history-item-accepted
        [history]="history"
      ></pbe-history-item-accepted>
    }

    @case (ShipmentOperationType.AAX_EXPORT_SHIPMENT_RELEASED_AT_CUSTOMS) {
      <pbe-history-item-released
        [history]="history"
      ></pbe-history-item-released>
    }

    @case (ShipmentOperationType.AAX_EXPORT_SHIPMENT_REJECTED_AT_CUSTOMS) {
      <pbe-history-item-rejection
        [history]="history"
      ></pbe-history-item-rejection>
    }

    @case (
      ShipmentOperationType.AAX_INSPECTION_OF_EXPORT_SHIPMENTS_AT_CUSTOMS
      ) {
      <pbe-history-item-inspection
        [history]="history"
      ></pbe-history-item-inspection>
    }

    @default {
      <p class="flex gap-x-1.5 text-gray-700 font-sans font-medium text-base">
        {{
          "services.arrivalAtExit.detailsPage.historyTab.operations." +
          history.operationType | translate
        }}
      </p>
    }
  }
</pbe-shipment-history-wrapper>
