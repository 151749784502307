import { CommonModule } from "@angular/common";
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  computed,
  inject,
} from "@angular/core";
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatTabsModule } from "@angular/material/tabs";
import { MatToolbarModule } from "@angular/material/toolbar";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { faAngleDown, faCheck } from "@fortawesome/pro-light-svg-icons";
import {
  faCircle,
  faCircleDot,
  faPencil,
  faTimes,
} from "@fortawesome/pro-regular-svg-icons";
import { injectQueryClient } from "@ngneat/query";
import { TranslateModule } from "@ngx-translate/core";
import { ButtonIconDirective } from "@portbase/material/button";
import { PortbaseExportFormInputComponent } from "../../components";
import { PortbaseExportTabBarComponent } from "../../components/tab-bar/tab-bar.component";
import { AuthenticationService } from "../../core/authentication/authentication.service";
import { OrganisationService } from "../../core/services/organisation.service";
import { formatArrivalLocationAddress } from "../../core/utils/address-formatter.utils";
import { ArrivalLocationService } from "../services/arrival-location.service";
import { PortbaseExportCargoHandlingAgentInputComponent } from "../components/cargo-handling-agent-input/cargo-handling-agent-input.component";
import { PortbaseExportRejectionReasonsComponent } from "../components/rejection-reasons/rejection-reasons.component";
import { PortbaseExportAaxStatusIndicatorComponent } from "../components/status-indicator/status-indicator.component";
import { ShipmentHistoryComponent } from "../history/shipment-history/shipment-history.component";
import { InspectionTypeService } from "../services/inspection-type.service";
import { ShipmentHistoryService } from "../services/shipment-history.service";
import {
  ArrivalAtExitStatus,
  ShipmentDto,
  ShipmentService,
} from "../services/shipment.service";

@Component({
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    MatToolbarModule,
    MatButtonModule,
    PortbaseExportTabBarComponent,
    PortbaseExportAaxStatusIndicatorComponent,
    PortbaseExportRejectionReasonsComponent,
    PortbaseExportFormInputComponent,
    FontAwesomeModule,
    ButtonIconDirective,
    MatTabsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    ShipmentHistoryComponent,
    PortbaseExportCargoHandlingAgentInputComponent,
  ],
  selector: "pbe-arrival-at-exit-details-page",
  templateUrl: './details-page.component.html',
  styleUrl: './details-page.component.scss'
})
export class ArrivalAtExitDetailsPageComponent implements OnChanges {
  @Input({ required: true }) shipment!: ShipmentDto;

  faPencil = faPencil;
  faTimes = faTimes;
  faCircle = faCircle;
  faCircleDot = faCircleDot;
  faAngleDown = faAngleDown;
  faCheck = faCheck;

  queryClient = injectQueryClient();

  sendToCustoms = inject(ShipmentService).sendToCustoms();
  sendToCustomsResult = this.sendToCustoms.result;

  arrivalLocationsService = inject(ArrivalLocationService);

  organisationService = inject(OrganisationService);

  authenticationData = inject(AuthenticationService).authenticated;

  inspectionTypeService = inject(InspectionTypeService);
  inspectionTypeQuery =
    this.inspectionTypeService.getInspectionTypeForShipmentId(null);
  inspectionType = this.inspectionTypeQuery.result;

  shipmentHistoryService = inject(ShipmentHistoryService);
  shipmentHistoryQuery =
    this.shipmentHistoryService.getHistoryForShipmentId(null);
  shipmentHistory = this.shipmentHistoryQuery.result;

  ArrivalAtExitStatus = ArrivalAtExitStatus;

  @Output() detailsClose = new EventEmitter();

  @Input() userCanAssignCargoHandlingAgent = false;

  activeTabIndex = 0;
  tabs = ["details", "history"];

  cargoHandlingAgentForm = new FormGroup({
    agent: new FormControl({
      label: "",
      value: "",
    }),
  });

  isEditingCargoHandlingAgent = false;

  assignCargoHandlingAgent =
    this.organisationService.assignCargoHandlingAgentToShipment();
  assignToCargoHandlingAgentResult = this.assignCargoHandlingAgent.result;

  isSendingToCustoms = computed(() => {
    const isCallingSendToCustoms = this.sendToCustomsResult().isPending;
    const isCallingAssignCargoHandlingAgent =
      this.assignToCargoHandlingAgentResult().isPending;

    return isCallingSendToCustoms || isCallingAssignCargoHandlingAgent;
  });

  ngOnChanges(changes: SimpleChanges): void {
    if (changes["shipment"].currentValue) {
      const shipment = changes["shipment"].currentValue as ShipmentDto;
      this.inspectionTypeQuery.updateOptions(
        this.inspectionTypeService.inspectionTypeQueryOptions(
          shipment.shipmentId,
          shipment.arrivalAtExitStatus,
        ),
      );

      this.shipmentHistoryQuery.updateOptions(
        this.shipmentHistoryService.shipmentHistoryQueryOptions(
          shipment.shipmentId,
        ),
      );

      this.cargoHandlingAgentForm = new FormGroup({
        agent: new FormControl(
          {
            label:
              this.organisationService.getCargoHandlingAgentForId(
                this.shipment.assignedToOrg,
              )?.name ?? "",
            value: this.shipment.assignedToOrg,
          },
          [Validators.required],
        ),
      });
    }
  }

  onClose() {
    this.detailsClose.emit();
  }

  getArrivalLocationAsString(): string {
    const arrivalLocation =
      this.arrivalLocationsService.getArrivalLocationForId(
        this.shipment.arrivalLocationId,
      );
    if (!arrivalLocation) {
      return "";
    }
    return formatArrivalLocationAddress(arrivalLocation);
  }

  getCargoHandlingAgentAsString(): string {
    const cargoHandlingAgent =
      this.organisationService.getCargoHandlingAgentForId(
        this.shipment.assignedToOrg,
      );
    if (!cargoHandlingAgent) {
      return "";
    }
    return cargoHandlingAgent.name;
  }

  getTerminalAsString(): string {
    const terminal = this.organisationService.getTerminalForId(
      this.shipment.registeredByOrg,
    );
    if (!terminal) {
      return "";
    }
    return terminal.name;
  }

  resendShipment() {
    this.sendToCustoms.mutateAsync({
      id: this.shipment.shipmentId,
    });
  }

  canResend(entity: ShipmentDto) {
    return (
      entity.registeredByOrg === this.authenticationData().data?.credentials &&
      (entity.arrivalAtExitStatus === ArrivalAtExitStatus.CUSTOMS_UNAVAILABLE ||
        entity.arrivalAtExitStatus === ArrivalAtExitStatus.INTERNAL_ERROR)
    );
  }

  async saveAndSendWithUpdatedCargoHandlingAgent() {
    if (
      this.cargoHandlingAgentForm.valid &&
      this.cargoHandlingAgentForm.value.agent &&
      this.shipment.assignedToOrg !==
        this.cargoHandlingAgentForm.value.agent.value
    ) {
      await this.assignCargoHandlingAgent.mutateAsync({
        shipmentId: this.shipment.shipmentId,
        cargoHandlingAgent: this.cargoHandlingAgentForm.value.agent.value,
      });
      this.isEditingCargoHandlingAgent = false;

      this.queryClient.refetchQueries({
        predicate: (query) =>
          query.queryKey[0] === "shipments" && query.isActive(),
      });
    }

    await this.sendToCustoms.mutateAsync({
      id: this.shipment.shipmentId,
    });

    this.detailsClose.emit();
  }

  canUpdateCargoHandlingAgent() {
    return (
      (this.userCanAssignCargoHandlingAgent &&
        this.shipment.arrivalAtExitStatus ===
          ArrivalAtExitStatus.EXPORT_SHIPMENT_ACCEPTED_AT_CUSTOMS) ||
      this.shipment.arrivalAtExitStatus ===
        ArrivalAtExitStatus.CUSTOMS_UNAVAILABLE
    );
  }
}
