import { CommonModule } from "@angular/common";
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MatTabsModule } from "@angular/material/tabs";
import { MatToolbarModule } from "@angular/material/toolbar";
import { ActivatedRoute, Router } from "@angular/router";
import { faTimes } from "@fortawesome/pro-regular-svg-icons";
import { TranslateModule } from "@ngx-translate/core";
import { ButtonIconDirective } from "@portbase/material/button";
import { PortbaseDataItemComponent } from "../../components/data-item/data-item.component";
import { PortbaseExportTabBarComponent } from "../../components/tab-bar/tab-bar.component";
import { PortbaseExportEmStatusIndicatorComponent } from "../components/status-indicator/status-indicator.component";
import {
  ExportManifestDetailsDto,
  ExportManifestService,
  ExportManifestStatus,
} from "../services/export-manifest.service";
import { ExportManifestShipmentsComponent } from "./components/export-shipments/export-shipments.component";

@Component({
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    MatToolbarModule,
    MatButtonModule,
    PortbaseExportTabBarComponent,
    PortbaseExportEmStatusIndicatorComponent,
    ButtonIconDirective,
    MatTabsModule,
    PortbaseDataItemComponent,
    ExportManifestShipmentsComponent,
  ],
  selector: "pbe-export-manifest-details-page",
  templateUrl: "./details-page.component.html",
  styleUrl: "./details-page.component.scss",
})
export class ExportManifestDetailsPageComponent implements OnChanges {
  @Input({ required: true }) exportManifest!: ExportManifestDetailsDto;
  @Input() inEditMode = false;

  @Output() detailsClose = new EventEmitter();

  faTimes = faTimes;

  ExportManifestStatus = ExportManifestStatus;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private exportManifestService: ExportManifestService,
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes["inEditMode"]) {
      const shouldRedirectToEdit = changes["inEditMode"]
        .currentValue as boolean;

      if (shouldRedirectToEdit) {
        this.redirectToManifestEdit();
      }
    }
  }

  redirectToManifestEdit() {
    if (!this.exportManifest) {
      return;
    }

    this.router.navigate([], {
      relativeTo: this.route,
      queryParamsHandling: "merge",
      queryParams: {
        view: "edit",
        id: this.exportManifest.id,
      },
    });
  }

  async resendManifest() {
    await this.exportManifestService.sendToCustoms().mutateAsync({
      id: this.exportManifest.id,
    });
  }

  canResend(entity: ExportManifestDetailsDto) {
    return entity.manifestStatus === ExportManifestStatus.INTERNAL_ERROR;
  }
}
