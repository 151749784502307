<pb-sidenav
  [primary]="false"
  [canCollapse]="false"
  [hasLogo]="false"
  [navigationListTitle]="
      'services.exportManifest.navigation.title' | translate
    "
  [navigationOptions]="[
      {
        link: '../urgent',
        label:
          'services.exportManifest.navigation.urgent'
          | translate
            : {
                count: exportManifestService.getUrgentManifestsCount()
              },
        icon: faClockFour
      },
      {
        link: '../all',
        label: 'services.exportManifest.navigation.all' | translate,
        icon: faShip
      }
    ]"
  [additionalContentTitle]="
      'services.exportManifest.filters.title' | translate
    "
>
  <div additionalContent>
    <pbe-export-manifest-filter-list
      [departureDateRange]="departureDateRange"
      [vesselStatusFilter]="vesselStatuses"
      [manifestStatusFilter]="manifestStatuses"
      [disableAllFilters]="disableAllFilters"
    ></pbe-export-manifest-filter-list>
  </div>

  <div class="flex-grow flex flex-col border-l border-l-black/20">
    <pbe-operational-view-table-controls
      [paginationData]="paginatedManifests().data"
      [isLoading]="paginatedManifests().isInitialLoading"
      [search]="search ?? ''"
      [page]="page"
      [pageSize]="pageSize"
      [orderBy]="orderBy"
      [orderDirection]="orderDirection"
    />
    <div class="bg-slate-100 flex-grow p-10">
      @if (paginatedManifests().isInitialLoading) {
        <pbe-overview-table-loading-state service="exportManifest" />
      }
      @if (paginatedManifests().data?.content; as manifests) {
        @if (manifests.length === 0) {
          <pbe-overview-table-empty-state
            service="exportManifest"
            [emptyStateVariant]="getEmptyStateVariant()"
          />
        } @else {
          <pbe-export-manifest-operational-view-table
            [manifests]="manifests"
            [search]="search"
          />
        }
      }
    </div>
  </div>
</pb-sidenav>
