<div
  class="flex flex-col items-center w-full h-full justify-center  text-center"
>
  <div class="bg-gray-200 w-24 h-24 pt-6 text-center rounded-full">
    <fa-icon class="text-5xl block text-gray-400" [icon]="faShip"></fa-icon>
  </div>
  <h3 class="font-medium mt-3">
    {{
      "services." + service + ".overview." + emptyStateVariant + ".title"
        | translate
    }}
  </h3>
  @if (emptyStateVariant !== "emptyNoFilter") {
    <p class="text-xs text-gray-600 mt-1">
      {{
        "services." +
        service +
        ".overview." +
        emptyStateVariant +
        ".description" | translate
      }}
    </p>
  }
  @if (emptyStateVariant === "emptyQuickFilter") {
    <button
      mat-stroked-button
      [icon]="faMagnifyingGlass"
      class="mt-3"
      (click)="
            router.navigate(['../all'], {
              relativeTo: this.route,
              queryParamsHandling: 'merge'
            })
          "
    >
      {{
        "services." + service + ".overview.emptyQuickFilter.button"
          | translate
      }}
    </button>
  } @else if (emptyStateVariant === "emptyNoFilter") {
    <button
      mat-stroked-button
      [icon]="faPlus"
      class="mt-3"
      (click)="
            router.navigate([], {
              relativeTo: this.route,
              queryParamsHandling: 'merge',
              queryParams: { view: 'create' }
            })
          "
    >
      {{
        "services." + service + ".overview.emptyNoFilter.button" | translate
      }}
    </button>
  }
</div>
