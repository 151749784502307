<div class="h-full flex flex-col items-stretch">
  <mat-toolbar>
    <div class="flex flex-row items-center gap-4">
      <button mat-icon-button [icon]="faTimes" (click)="onClose()"></button>
      <h1 class="text-xl text-gray-700 ml-6 items-center">
        {{ "services.arrivalAtExit.detailsPage.header" | translate }}
      </h1>
    </div>
  </mat-toolbar>
  <div class="bg-slate-100 flex-grow w-full flex-col">
    <pbe-tab-bar
      [activeTabIndex]="activeTabIndex"
      (changeTabIndex)="activeTabIndex = $event"
      [tabLabels]="[
          'services.arrivalAtExit.detailsPage.detailsTab.header' | translate,
          'services.arrivalAtExit.detailsPage.historyTab.header' | translate
        ]"
    ></pbe-tab-bar>

    @if (activeTabIndex === 0 && shipment !== undefined) {
      <div class="mt-10 bg-white p-8 shadow-sm border max-w-3xl mx-auto">
        <div class="w-full">
          <div class="max-w-96">
            <h2 class="text-lg font-medium mb-4">
              {{
                "services.arrivalAtExit.createPage.headers.document"
                  | translate
              }}
            </h2>
            <div class="mb-3 text-sm">
              <label class="font-medium">{{
                  "services.arrivalAtExit.detailsPage.detailsTab.fields.documentType"
                    | translate
                }}</label>
              <div>
                {{
                  "services.arrivalAtExit.createPage.fields.documentTypeOptions." +
                  shipment.documentType | translate
                }}
              </div>
            </div>
            <div class="mb-3 text-sm">
              <label class="font-medium">{{
                  "services.arrivalAtExit.detailsPage.detailsTab.fields.documentNumber"
                    | translate
                }}</label>
              <div>{{ shipment.documentNumber }}</div>
            </div>
            <div class="mb-3 text-sm">
              <label class="font-medium">{{
                  "services.arrivalAtExit.detailsPage.detailsTab.fields.reference"
                    | translate
                }}</label>
              <div>{{ shipment.reference || "-" }}</div>
            </div>
            <div class="mb-3 text-sm">
              <label class="font-medium">{{
                  "services.arrivalAtExit.detailsPage.detailsTab.fields.arrivalAtExitStatus"
                    | translate
                }}</label>
              <div class="mt-2">
                <pbe-aax-status-indicator
                  [status]="shipment.arrivalAtExitStatus"
                ></pbe-aax-status-indicator>

                @if (
                  shipment.arrivalAtExitStatus ===
                  ArrivalAtExitStatus.EXPORT_SHIPMENT_REJECTED_AT_CUSTOMS
                  ) {
                  <pbe-rejection-reasons [shipment]="shipment" />
                } @else {
                  <div
                    class="text-xs text-slate-600 mt-2"
                    [innerHTML]="
                        'general.shipmentStatus.long.' +
                          shipment.arrivalAtExitStatus
                          | translate: { inspectionType: inspectionType().data }
                      "
                  ></div>
                }
              </div>
            </div>

            <h2 class="text-lg font-medium mt-8 mb-4">
              {{
                "services.arrivalAtExit.createPage.headers.arrival"
                  | translate
              }}
            </h2>
            <div class="mb-3 text-sm">
              <label class="font-medium">{{
                  "services.arrivalAtExit.detailsPage.detailsTab.fields.arrivalLocation"
                    | translate
                }}</label>
              <div>
                {{ getArrivalLocationAsString() }}
              </div>
            </div>
            <div class="mb-3 text-sm">
              <label class="font-medium">{{
                  "services.arrivalAtExit.detailsPage.detailsTab.fields.creationDate"
                    | translate
                }}</label>
              <div>
                {{ shipment.creationDate | date: "dd-MM-yyyy HH:mm" }}
              </div>
            </div>
            <div class="mb-3 text-sm">
              <label class="font-medium">{{
                  "services.arrivalAtExit.detailsPage.detailsTab.fields.arrivalTimeAndDate"
                    | translate
                }}</label>
              @if (shipment.arrivalTimeAndDate !== null) {
                <div>
                  {{ shipment.arrivalTimeAndDate | date: "dd-MM-yyyy HH:mm" }}
                </div>
              } @else {
                <div>
                  {{
                    "services.arrivalAtExit.detailsPage.detailsTab.emptyValues.arrivalTimeAndDate"
                      | translate
                  }}
                </div>
              }
            </div>
            @if (
              shipment.registeredByOrg !== shipment.assignedToOrg ||
              canUpdateCargoHandlingAgent()
              ) {
              <div>
                @if (userCanAssignCargoHandlingAgent) {
                  <h2 class="text-lg font-medium mt-8 mb-4">
                    {{
                      "services.arrivalAtExit.createPage.headers.agent"
                        | translate
                    }}
                  </h2>
                  @if (isEditingCargoHandlingAgent) {
                    <div class="flex flex-row gap-4">
                      <pbe-cargo-handling-agent-input
                        [form]="cargoHandlingAgentForm"
                      ></pbe-cargo-handling-agent-input>
                    </div>
                  } @else {
                    <div class="text-sm">
                      <label class="font-medium">{{
                          "services.arrivalAtExit.detailsPage.detailsTab.fields.cargoHandlingAgent"
                            | translate
                        }}</label>
                      <div>{{ getCargoHandlingAgentAsString() }}</div>
                    </div>
                    @if (canUpdateCargoHandlingAgent()) {
                      <button
                        class="text-xs font-medium text-pb-blue-700 hover:underline hover:text-pb-blue-900 active:text-pb-blue-950"
                        (click)="isEditingCargoHandlingAgent = true"
                      >
                        <fa-icon [icon]="faPencil"></fa-icon>
                        {{
                          "services.arrivalAtExit.detailsPage.buttons.change"
                            | translate
                        }}
                      </button>
                    }
                  }
                } @else {
                  <h2 class="text-lg font-medium mt-8 mb-4">
                    {{
                      "services.arrivalAtExit.createPage.headers.terminal"
                        | translate
                    }}
                  </h2>
                  <div class="mb-3 text-sm">
                    <label class="font-medium">{{
                        "services.arrivalAtExit.detailsPage.detailsTab.fields.terminal"
                          | translate
                      }}</label>
                    <div>{{ getTerminalAsString() }}</div>
                    <p class="mt-3">
                      {{
                        "services.arrivalAtExit.detailsPage.detailsTab.createdByTerminal"
                          | translate
                      }}
                    </p>
                  </div>
                }
              </div>
            }
          </div>
          @if (isEditingCargoHandlingAgent) {
            <div class="flex flex-row justify-end mt-8">
              <div>
                <button
                  mat-raised-button
                  [disabled]="isSendingToCustoms()"
                  (click)="saveAndSendWithUpdatedCargoHandlingAgent()"
                >
                  {{
                    "services.arrivalAtExit.createPage.buttons.saveAndSend"
                      | translate
                  }}
                </button>
              </div>
            </div>
          } @else if (canResend(shipment)) {
            <div class="flex flex-row justify-end mt-8">
              <div>
                <button
                  mat-raised-button
                  (click)="resendShipment()"
                  [disabled]="isSendingToCustoms()"
                >
                  {{
                    "services.arrivalAtExit.createPage.buttons.resend"
                      | translate
                  }}
                </button>
              </div>
            </div>
          }
        </div>
      </div>
    }

    @if (activeTabIndex === 1) {
      <div class="mt-10 max-w-3xl mx-auto flex flex-col gap-y-6">
        <div
          class="bg-white shadow-sm border p-8 max-h-[65vh] overflow-y-auto"
        >
          <div class="w-full flex flex-col gap-5">
            @if (shipmentHistory().data) {
              @for (history of shipmentHistory().data; track history.id) {
                <pbe-shipment-history
                  [history]="history"
                  [isMostRecent]="$index === 0"
                ></pbe-shipment-history>
              }
            }
          </div>
        </div>
      </div>
    }
  </div>
</div>
