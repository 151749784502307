<pb-autocomplete
  [label]="'services.arrivalAtExit.createPage.fields.agent' | translate"
  [placeholder]="
      'services.arrivalAtExit.createPage.fields.agentPlaceholder' | translate
    "
  [formGroup]="form"
  controlName="agent"
  [optionalLabel]="'general.forms.optionalLabel' | translate"
  [suggestions]="
      getFilteredSuggestionsForCargoHandlingAgents(form.value.agent)
    "
></pb-autocomplete>
