import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  computed,
  inject,
} from "@angular/core";
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from "@angular/forms";

import { CommonModule } from "@angular/common";
import { MatButtonModule } from "@angular/material/button";
import { MatToolbarModule } from "@angular/material/toolbar";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { faTimes } from "@fortawesome/pro-regular-svg-icons";
import { TranslateModule } from "@ngx-translate/core";
import { ButtonIconDirective } from "@portbase/material/button";
import { DOCUMENT_TYPE_PREFERENCE_KEY } from "../../constants";
import { autocompleteSuggestionValidator } from "../../core/validators/autocomplete-suggestion.validator";
import { PreferencesService } from "../../preferences/services/preferences.service";
import { ArrivalAtExitFormComponent } from "../forms/arrival-at-exit-form.component";
import { ArrivalAtExitStatus, ShipmentService } from "../services/shipment.service";
import { documentNumberValidator } from "../validators/document-number.validator";

@Component({
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TranslateModule,
    ButtonIconDirective,
    FontAwesomeModule,
    MatButtonModule,
    MatToolbarModule,
    ArrivalAtExitFormComponent,
  ],
  selector: "pbe-arrival-at-exit-create-page",
  templateUrl: "./create-page.component.html",
  styleUrl: "./create-page.component.scss"
})
export class ArrivalAtExitCreatePageComponent implements OnInit {
  faTimes = faTimes;

  createShipment = inject(ShipmentService).createShipment();
  createShipmentResult = this.createShipment.result;

  createShipmentForSendingDirectly = inject(ShipmentService).createShipment({
    refetchOnSuccess: false,
    showNotificationOnSuccess: false,
  });

  createShipmentForSendingDirectlyResult =
    this.createShipmentForSendingDirectly.result;

  sendToCustoms = inject(ShipmentService).sendToCustoms();
  sendToCustomsResult = this.sendToCustoms.result;

  ArrivalAtExitStatus = ArrivalAtExitStatus;

  isSaving = computed(() => {
    const isCallingCreate = this.createShipmentResult().isPending;
    return isCallingCreate;
  });

  isSendingToCustoms = computed(() => {
    const isCallingSendToCustoms = this.sendToCustomsResult().isPending;
    const isCallingCreateDirectly =
      this.createShipmentForSendingDirectlyResult().isPending;

    return isCallingSendToCustoms || isCallingCreateDirectly;
  });

  preferencesService = inject(PreferencesService);

  preferredDocumentType = this.preferencesService.getPreference<string>(
    DOCUMENT_TYPE_PREFERENCE_KEY,
  );

  @Input() userCanAssignCargoHandlingAgent = false;

  @Output() createCancel = new EventEmitter();
  @Output() createSuccess = new EventEmitter();

  form!: FormGroup;

  ngOnInit(): void {
    this.form = new FormGroup({
      documentType: new FormControl(this.preferredDocumentType ?? "EX", {
        validators: [Validators.required],
      }),
      documentNumber: new FormControl("", {
        validators: [Validators.required, documentNumberValidator()],
      }),
      reference: new FormControl("", {
        validators: [Validators.maxLength(25)],
      }),
      arrivalLocation: new FormControl("", {
        validators: [Validators.required, autocompleteSuggestionValidator()],
      }),
      agent: new FormControl(""),
    });
  }

  async onSave() {
    if (this.form.valid) {
      const { documentNumber, reference, documentType, agent } =
        this.form.value;

      await this.createShipment.mutateAsync({
        arrivalLocationId: this.form.value.arrivalLocation.value,
        documentNumber,
        reference,
        documentType,
        assignedToOrg: agent?.value,
      });

      this.createSuccess.emit();
    } else {
      this.form.markAllAsTouched();
    }
  }

  async onSendToCustoms() {
    if (this.form.valid) {
      const { documentNumber, reference, documentType, agent } =
        this.form.value;

      const { id } = await this.createShipmentForSendingDirectly.mutateAsync({
        arrivalLocationId: this.form.value.arrivalLocation.value,
        documentNumber,
        reference,
        documentType,
        assignedToOrg: agent?.value,
      });

      await this.sendToCustoms.mutateAsync({ id });

      this.createSuccess.emit();
    } else {
      this.form.markAllAsTouched();
    }
  }

  onCancel() {
    this.createCancel.emit();
  }
}
