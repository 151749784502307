import { Component, Input, OnInit } from "@angular/core";
import { DatePipe } from "@angular/common";
import { FaIconComponent } from "@fortawesome/angular-fontawesome";
import { MatTableModule } from "@angular/material/table";
import { TranslateModule } from "@ngx-translate/core";
import { faChevronRight } from "@fortawesome/pro-regular-svg-icons";
import { ActivatedRoute, Router } from "@angular/router";
import { HighlightPipe } from "../../../../core/pipes/highlight.pipe";
import { PortbaseExportEmStatusIndicatorComponent } from "../../../components/status-indicator/status-indicator.component";
import { ExportManifestListDto } from "../../../services/export-manifest.service";
import { OrganisationService } from "../../../../core/services/organisation.service";

@Component({
  selector: "pbe-export-manifest-customer-service-view-table",
  standalone: true,
  imports: [
    DatePipe,
    FaIconComponent,
    MatTableModule,
    TranslateModule,
    HighlightPipe,
    PortbaseExportEmStatusIndicatorComponent,
  ],
  templateUrl: "./customer-service-view-table.component.html",
  styleUrl: "./customer-service-view-table.component.scss",
})
export class CustomerServiceViewTableComponent {
  @Input({ required: true }) manifests!: ExportManifestListDto[];
  @Input() search: string | null = null;

  constructor(private router: Router, private route: ActivatedRoute, private organisationService: OrganisationService ) {
  }

  faChevronRight = faChevronRight;

  displayedColumns = [
    "callReferenceNumber",
    "vesselName",
    "registeredByOrg",
    "voyageNumber",
    "departureDate",
    "vesselStatus",
    "manifestStatus",
    "actions",
  ];

  openDetailsPage(entity: ExportManifestListDto) {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParamsHandling: "merge",
      queryParams: {
        view: "details",
        id: entity.id,
      },
    });
  }

  mapOrganisationShortNameToName(orgShortName: string) {
    return this.organisationService.getManifestOrganisationForId(orgShortName)?.name;
  }
}
