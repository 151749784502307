var StatusCodeColor = /* @__PURE__ */(StatusCodeColor2 => {
  StatusCodeColor2["Success"] = "#69AB32";
  StatusCodeColor2["Warning"] = "#F0BB4B";
  StatusCodeColor2["Danger"] = "#E95F5D";
  return StatusCodeColor2;
})(StatusCodeColor || {});
function getStatusCodeColor(status) {
  if (status < 300) {
    return "#69AB32" /* Success */;
  }
  if (status < 400) {
    return "#F0BB4B" /* Warning */;
  }
  return "#E95F5D" /* Danger */;
}
export { StatusCodeColor, getStatusCodeColor };
