import { Component, inject } from "@angular/core";

import { CommonModule } from "@angular/common";
import { TranslateModule } from "@ngx-translate/core";
import {
  AuthenticationService,
  ServiceSubscriptionDescriptionCode,
} from "../core/authentication/authentication.service";
import { PortbaseExportTopbarComponent } from "../core/topbar.component";
import { ArrivalAtExitPreferencesFormComponent } from "./forms/arrival-at-exit-preferences-form/arrival-at-exit-preferences-form.component";
import { ExportManifestPreferencesFormComponent } from "./forms/export-manifest-preferences-form/export-manifest-preferences-form.component";

@Component({
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    PortbaseExportTopbarComponent,
    ArrivalAtExitPreferencesFormComponent,
    ExportManifestPreferencesFormComponent,
  ],
  selector: "pbe-preferences",
  templateUrl: './preferences.component.html',
  styleUrl: './preferences.component.scss'
})
export class PreferencesComponent {
  userRoles = inject(AuthenticationService).userRoles;
  hasAax = inject(AuthenticationService).hasAccessToAax;
  hasEm = inject(AuthenticationService).hasAccessToExportManifest;

  ServiceSubscriptionDescriptionCode = ServiceSubscriptionDescriptionCode;

  getSubHeaderVariant(): string {
    if (this.hasAax() && this.hasEm()) {
      return "both";
    } else if (this.hasAax()) {
      return "aaxOnly";
    }
    return "emOnly";
  }
}
