<pb-sidenav
  [primary]="false"
  [canCollapse]="false"
  [hasLogo]="false"
  [navigationListTitle]="
      'services.arrivalAtExit.navigation.title' | translate
    "
  [navigationOptions]="[
      {
        link: '../all',
        label: 'services.arrivalAtExit.navigation.all' | translate,
        icon: faDoorOpen
      },
      {
        link: '../draft',
        label: 'services.arrivalAtExit.navigation.notYetSent' | translate,
        icon: faInbox
      },
      {
        link: '../flagged',
        label: 'services.arrivalAtExit.navigation.flagged' | translate,
        icon: faShieldExclamation
      }
    ]"
  [additionalContentTitle]="
      'services.arrivalAtExit.filters.title' | translate
    "
>
  <div additionalContent>
    <pbe-arrival-at-exit-filter-list
      [showStatusesFilter]="quickFilter === null"
      [showOrganisationsFilter]="true"
      [creationDateRange]="creationDateRange"
      [statusFilter]="statuses"
      [organisationFilter]="organisations"
      [organisationFilterOptions]="organisationFilterOptions()"
    ></pbe-arrival-at-exit-filter-list>
  </div>

  <div class="flex-grow flex flex-col border-l border-l-black/20">
    @if (paginatedShipments().data; as paginationData) {
      <pbe-arrival-at-exit-customer-service-view-table-controls
        [paginationData]="paginationData"
        [search]="search ?? ''"
        [page]="page"
        [pageSize]="pageSize"
        [orderBy]="orderBy"
        [orderDirection]="orderDirection"
      />
    }
    <div class="bg-slate-100 flex-grow p-10">
      @if (paginatedShipments().data?.content; as shipments) {
        @if (shipments.length === 0) {
          <pbe-overview-table-empty-state
            [emptyStateVariant]="getEmptyStateVariant()"
            service="arrivalAtExit"
          ></pbe-overview-table-empty-state>
        } @else {
          <pbe-arrival-at-exit-customer-service-view-table
            [shipments]="shipments"
            [search]="search"
          ></pbe-arrival-at-exit-customer-service-view-table>
        }
      }
    </div>
  </div>
</pb-sidenav>
