<div class="flex flex-col gap-1">
  <p class="flex gap-x-1.5 text-gray-700 font-sans font-medium text-base">
    {{
      "services.arrivalAtExit.detailsPage.historyTab.operations." +
      history.operationType | translate: { field: getHeader() }
    }}
  </p>
  <div class="flex gap-1 items-center">
    @if (getChanges().oldValue) {
      <div
        class="text-red-500 font-semibold text-sm font-sans px-1 text-center bg-red-100 rounded-md"
      >
        {{ getChanges().oldValue }}
      </div>

      <p class="text-gray-600 font-semibold">-></p>
    }

    <div
      class="text-green-500 font-semibold text-sm font-sans px-1 text-center bg-green-100 rounded-md"
    >
      {{ getChanges().newValue }}
    </div>
  </div>
</div>
