import { HttpClient } from "@angular/common/http";
import { Injectable, inject } from "@angular/core";
import { injectMutation, injectQuery, injectQueryClient } from "@ngneat/query";
import { TranslateService } from "@ngx-translate/core";
import { AppConfigService } from "../../core/services/app-config.service";
import { NotificationService } from "../../core/services/notification.service";
import { WINDOW } from "../../window";

export enum NotificationType {
  // Arrival At Exit
  AaxInspection = "AAX_INS",
  AaxRejection = "AAX_RJ",
  AaxReleased = "AAX_RE",
  AaxCustomsOutage = "AAX_OUTAGE",

  // Export Manifest
  MfxRejection = "MFX_RJ",
  MfxDue = "MFX_DUE",
}

export const ArrivalNotificationTypes = [
  NotificationType.AaxInspection,
  NotificationType.AaxRejection,
  NotificationType.AaxReleased,
  NotificationType.AaxCustomsOutage,
] as const;

export const ExportNotificationTypes = [
  NotificationType.MfxRejection,
  NotificationType.MfxDue,
] as const;

export interface PersonalNotificationSettings {
  // Arrival At Exit
  AAX_INS: boolean; // AaxInspection
  AAX_RJ: boolean; // AaxRejection
  AAX_RE: boolean; // AaxReleased
  AAX_OUTAGE: boolean; // AaxCustomsOutage

  // Export Manifest
  MFX_RJ: boolean; // MfxRejection
  MFX_DUE: boolean; // MfxDue
}

export type OrganisationNotificationSettingsResponse = Record<
  string,
  NotificationType[]
>;

export type OrganisationNotificationSettings = Record<
  NotificationType,
  string[]
>;

@Injectable({ providedIn: "root" })
export class PreferencesService {
  #http = inject(HttpClient);
  #mutation = injectMutation();
  #query = injectQuery();
  #queryClient = injectQueryClient();

  window = inject(WINDOW);

  notificationService = inject(NotificationService);
  translateService = inject(TranslateService);

  private readonly _appConfigService = inject(AppConfigService);
  private readonly _baseUrl = `${this._appConfigService.getConfig().apiUrl}`;

  public getPreference<T>(key: string): T | null {
    if (!this.window?.localStorage) {
      return null;
    }

    const value = this.window.localStorage.getItem(key);

    if (value) {
      return JSON.parse(value);
    }

    return null;
  }

  public setPreference<T>(key: string, value: T): void {
    if (!this.window?.localStorage) {
      return;
    }

    this.window.localStorage.setItem(key, JSON.stringify(value));
  }

  public setPersonalNotificationPreferencesForAax() {
    return this.#mutation({
      mutationFn: (settings: PersonalNotificationSettings) =>
        this.#http.put<unknown>(
          `${this._baseUrl}/preferences/user-preferences`,
          { subscribedFlows: this.mapSettingsToSubscribedFlows(settings) },
        ),
      onSuccess: () => {
        this.#queryClient.invalidateQueries({
          queryKey: [
            "preferences",
            "aax-notification-settings",
            "organisation",
          ],
        });

        this.notificationService.show({
          type: "success",
          closable: true,
          title: this.translateService.instant(
            "preferences.notifications.notifications.personal.title",
          ),
          message: this.translateService.instant(
            "preferences.notifications.notifications.personal.message",
          ),
        });
      },
    });
  }

  public setOrganisationNotificationPreferencesForAax() {
    return this.#mutation({
      mutationFn: (settings: OrganisationNotificationSettings) => {
        // convert settings into structure with email as keys
        const settingsRequestBody = Object.entries(settings).reduce(
          (acc, [typeString, emails]) => {
            const type = typeString as NotificationType;
            emails.forEach((email) => {
              if (!acc[email]) {
                acc[email] = [];
              }

              acc[email].push(type);
            });
            return acc;
          },
          {} as Record<string, NotificationType[]>,
        );

        return this.#http.put<unknown>(
          `${this._baseUrl}/preferences/organisation-preferences`,
          {
            emailAddressMap: settingsRequestBody,
          },
        );
      },
      onSuccess: () => {
        this.#queryClient.invalidateQueries({
          queryKey: [
            "preferences",
            "aax-notification-settings",
            "organisation",
          ],
        });

        this.notificationService.show({
          type: "success",
          closable: true,
          title: this.translateService.instant(
            "preferences.notifications.notifications.organisation.title",
          ),
          message: this.translateService.instant(
            "preferences.notifications.notifications.organisation.message",
          ),
        });
      },
    });
  }
  public setOrganisationArrivalLocationAliases() {
    return this.#mutation({
      mutationFn: (aliases: Record<string, string>) => {
        return this.#http.put<unknown>(
          `${this._baseUrl}/preferences/organisation-arrival-location-aliases`,
          {
            aliases,
          },
        );
      },
      onSuccess: () => {
        this.#queryClient.refetchQueries({
          queryKey: ["preferences", "arrival-location-aliases"],
        });

        this.notificationService.show({
          type: "success",
          closable: true,
          title: this.translateService.instant(
            "preferences.notifications.arrivalLocationAliases.saveSuccess.title",
          ),
          message: this.translateService.instant(
            "preferences.notifications.arrivalLocationAliases.saveSuccess.message",
          ),
        });
      },
    });
  }

  public getOrganisationNotificationPreferencesForAax() {
    return this.#query({
      queryKey: [
        "preferences",
        "aax-notification-settings",
        "organisation",
      ] as const,
      queryFn: () =>
        this.#http.get<{
          emailAddressMap: Record<string, NotificationType[]>;
        }>(`${this._baseUrl}/preferences/organisation-preferences`),
      select: (data) =>
        Object.entries(data.emailAddressMap).reduce(
          (acc, [email, types]) => {
            types.forEach((type) => {
              if (!acc[type]) {
                acc[type] = [];
              }

              acc[type].push(email);
            });
            return acc;
          },
          {} as Record<NotificationType, string[]>,
        ),
      enabled: true,
    });
  }

  public getOrganisationArrivalLocationAliases() {
    return this.#query({
      queryKey: ["preferences", "arrival-location-aliases"] as const,
      queryFn: () =>
        this.#http.get<Record<string, string>>(
          `${this._baseUrl}/preferences/organisation-arrival-location-aliases`,
        ),
      enabled: true,
      refetchOnWindowFocus: false,
    });
  }

  public convertToPersonalNotificationSettings(
    settings: Record<NotificationType, string[]>,
    userEmail: string,
  ): PersonalNotificationSettings {
    return {
      [NotificationType.AaxInspection]:
        settings[NotificationType.AaxInspection]?.includes(userEmail) ?? false,
      [NotificationType.AaxRejection]:
        settings[NotificationType.AaxRejection]?.includes(userEmail) ?? false,
      [NotificationType.AaxReleased]:
        settings[NotificationType.AaxReleased]?.includes(userEmail) ?? false,
      [NotificationType.AaxCustomsOutage]:
        settings[NotificationType.AaxCustomsOutage]?.includes(userEmail) ??
        false,
      [NotificationType.MfxRejection]:
        settings[NotificationType.MfxRejection]?.includes(userEmail) ?? false,
      [NotificationType.MfxDue]:
        settings[NotificationType.MfxDue]?.includes(userEmail) ?? false,
    };
  }

  private mapSettingsToSubscribedFlows(
    settings: PersonalNotificationSettings,
  ): NotificationType[] {
    const subscribedFlows = [];

    // Arrival At Exit
    if (settings[NotificationType.AaxInspection])
      subscribedFlows.push(NotificationType.AaxInspection);
    if (settings[NotificationType.AaxRejection])
      subscribedFlows.push(NotificationType.AaxRejection);
    if (settings[NotificationType.AaxReleased])
      subscribedFlows.push(NotificationType.AaxReleased);
    if (settings[NotificationType.AaxCustomsOutage])
      subscribedFlows.push(NotificationType.AaxCustomsOutage);

    // Export Manifest
    if (settings[NotificationType.MfxRejection])
      subscribedFlows.push(NotificationType.MfxRejection);

    if (settings[NotificationType.MfxDue])
      subscribedFlows.push(NotificationType.MfxDue);

    return subscribedFlows;
  }
}
