import { AsyncPipe } from "@angular/common";
import { Component, Input, inject } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import {
  MatDialogActions,
  MatDialogClose,
  MatDialogRef,
} from "@angular/material/dialog";
import { faTimes } from "@fortawesome/pro-light-svg-icons";
import { TranslateModule } from "@ngx-translate/core";
import { ButtonIconDirective } from "@portbase/material/button";
import { SessionMonitoringService } from "@portbase/session-monitoring";
import { map } from "rxjs";
import { WINDOW } from "../../../window";
import { AuthenticationService } from "../../authentication/authentication.service";

@Component({
  selector: "pbe-confirmation-dialog",
  standalone: true,
  imports: [
    MatDialogActions,
    MatDialogClose,
    MatButtonModule,
    ButtonIconDirective,
    TranslateModule,
    AsyncPipe,
  ],
  template: `<div class="p-5 flex flex-col items-stretch mb-4 max-w-xl">
      <div class="flex flex-row justify-between items-start">
        <h3 class="text-lg font-medium mb-2">
          {{ "general.dialogs.sessionExpiration.title" | translate }}
        </h3>
        <button mat-dialog-close mat-icon-button [icon]="faTimes"></button>
      </div>
      <div
        [innerHTML]="
          'general.dialogs.sessionExpiration.description'
            | translate: { count: timeoutCount | async }
        "
      ></div>
    </div>
    <mat-dialog-actions [align]="'end'" class="p-0">
      <button mat-stroked-button (click)="remainLoggedIn()">
        {{ "general.dialogs.sessionExpiration.keepAlive" | translate }}
      </button>
      <button mat-raised-button color="warn" (click)="logout()">
        {{ "general.dialogs.sessionExpiration.logout" | translate }}
      </button>
    </mat-dialog-actions>`,
})
export class SessionExpirationDialogComponent {
  session = inject(SessionMonitoringService);
  authenticationService = inject(AuthenticationService);
  window = inject(WINDOW);
  @Input() dialogRef?: MatDialogRef<SessionExpirationDialogComponent>;

  faTimes = faTimes;

  timeoutCount = this.session.onTimeoutWarning.pipe(
    map((countdown) => this._formatCountdown(countdown)),
  );

  logout(): void {
    this.session.stop();
    this.window.location.assign(this.authenticationService.logoutUrl);
  }

  remainLoggedIn(): void {
    this.session.stop();
    this.session.refresh().subscribe(() => {
      this.session.watch();
      this.dialogRef?.close();
    });
  }

  private _formatCountdown(countdown: number): string {
    const minutes = Math.floor(countdown / 60);
    const seconds = countdown - minutes * 60;
    return `${minutes > 0 ? `${minutes}m` : ``} ${seconds}s`;
  }
}
