import { TippyConfig } from '@ngneat/helipopper/lib/tippy.types';

type Variation = TippyConfig['variations'][0];

export const pbTooltipVariation: Variation = {
  theme: 'material',
  arrow: false,
  animation: 'fade',
  delay: 200,
  trigger: 'mouseenter click',
  placement: 'bottom-start',
  interactive: true
};
