import { HttpResponse, http } from "msw";
import { ServiceSubscriptionDescriptionCode } from "../../../../app/core/authentication/authentication.service";

export const accountManagementApiHandlers = [
  http.get("https://acc-mgt.accept.portbase.com/acc-mgt/users/profile", () =>
    HttpResponse.json({
      loginName: "test",
      emailAddress: "dev@user.com",
      firstName: "Test",
      lastName: "User",
      language: "en",
      organisationShortname: "CHA1",
      organisationFullname: "Cargo Handling Agent 1",
      organisationTypes: [],
    }),
  ),
  http.get("https://acc-mgt.accept.portbase.com/acc-mgt/users/services", () =>
    HttpResponse.json([
      {
        url: "https://localhost:4200/export-manifest",
        packageDescription: "Agent",
        descriptionCode:
          ServiceSubscriptionDescriptionCode.ExportManifestForCargoHandlingAgent,
        description: "Export Manifest",
      },
      {
        url: "https://localhost:4200/arrival-at-exit",
        packageDescription: "Agent",
        descriptionCode:
          ServiceSubscriptionDescriptionCode.ArrivalAtExitForCargoHandlingAgent,
        description: "Arrival at Exit",
      },
    ]),
  ),
];
