import { Component, inject, Input } from "@angular/core";
import { MatTableModule } from "@angular/material/table";

import { CommonModule } from "@angular/common";
import { MatButtonModule } from "@angular/material/button";
import { ActivatedRoute, Router } from "@angular/router";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { faChevronRight } from "@fortawesome/pro-regular-svg-icons";
import { TranslateModule } from "@ngx-translate/core";
import { AuthenticationService } from "../../../../core/authentication/authentication.service";
import { HighlightPipe } from "../../../../core/pipes/highlight.pipe";
import { PortbaseExportEmStatusIndicatorComponent } from "../../../components/status-indicator/status-indicator.component";
import {
  ExportManifestListDto,
  ExportManifestStatus,
} from "../../../services/export-manifest.service";

@Component({
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    PortbaseExportEmStatusIndicatorComponent,
    MatTableModule,
    FontAwesomeModule,
    MatButtonModule,
    HighlightPipe,
  ],
  selector: "pbe-export-manifest-operational-view-table",
  styleUrl: "./operational-view-table.component.scss",
  templateUrl: "./operational-view-table.component.html"
})
export class ExportManifestOperationalViewTableComponent {
  @Input({ required: true }) manifests!: ExportManifestListDto[];
  @Input() search: string | null = null;

  authenticationData = inject(AuthenticationService).authenticated;

  router = inject(Router);
  route = inject(ActivatedRoute);

  faChevronRight = faChevronRight;

  displayedColumns = [
    "callReferenceNumber",
    "vesselName",
    "voyageNumber",
    "departureDate",
    "vesselStatus",
    "manifestStatus",
    "actions",
  ];

  openDetailsPage(entity: ExportManifestListDto) {
    const shouldGoToEdit = this.canEdit(entity);

    this.router.navigate([], {
      relativeTo: this.route,
      queryParamsHandling: "merge",
      queryParams: {
        view: shouldGoToEdit ? "edit" : "details",
        id: entity.id,
      },
    });
  }

  canEdit(entity: ExportManifestListDto) {
    const status = entity.manifestStatus;

    return (
      status ===
        ExportManifestStatus.EXPORT_MANIFEST_NOT_YET_REPORTED_TO_CUSTOMS ||
      status === ExportManifestStatus.EXPORT_MANIFEST_ACCEPTED_AT_CUSTOMS ||
      status === ExportManifestStatus.EXPORT_MANIFEST_REJECTED_AT_CUSTOMS ||
      status ===
        ExportManifestStatus.EXPORT_MANIFEST_UPDATED_NOT_YET_REPORTED_TO_CUSTOMS
    );
  }
}
