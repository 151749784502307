import { JsonPipe } from "@angular/common";
import {
  AfterViewInit,
  Component,
  OnDestroy,
  ViewChild,
  inject,
} from "@angular/core";
import { MatDrawer, MatSidenavModule } from "@angular/material/sidenav";
import { ActivatedRoute, Router } from "@angular/router";
import { Subscription } from "rxjs";
import {
  AuthenticationService,
  UserRights,
} from "../../../../core/authentication/authentication.service";
import { ExportManifestCreatePageComponent } from "../../../details-page/create-page/create-page.component";
import { ExportManifestDetailsPageComponent } from "../../../details-page/details-page.component";
import { ExportManifestEditPageComponent } from "../../../details-page/edit-page/edit-page.component";
import {
  ExportManifestService,
  ExportManifestStatus,
} from "../../../services/export-manifest.service";

type ExportManifestViewQueryParam = "create" | "edit" | "details" | null;

@Component({
  standalone: true,
  selector: "pbe-export-manifest-operational-right-drawer",
  imports: [
    ExportManifestDetailsPageComponent,
    ExportManifestCreatePageComponent,
    ExportManifestEditPageComponent,
    MatSidenavModule,
    JsonPipe,
  ],
  templateUrl: "./operational-right-drawer.component.html",
  styleUrl: "./operational-right-drawer.component.scss"
})
export class ExportManifestOperationalRightDrawerComponent
  implements OnDestroy, AfterViewInit
{
  viewQueryParam: ExportManifestViewQueryParam = null;
  entityIdQueryParam: string | null = null;

  UserRights = UserRights;
  userRights = inject(AuthenticationService).userRights;

  router = inject(Router);
  route = inject(ActivatedRoute);

  exportManifestService = inject(ExportManifestService);
  exportManifestByIdQuery = this.exportManifestService.getExportManifestById(
    this.entityIdQueryParam,
  );
  exportManifest = this.exportManifestByIdQuery.result;

  @ViewChild("drawer") public drawer!: MatDrawer;

  _paramsSubscription?: Subscription;

  ngAfterViewInit() {
    this._paramsSubscription = this.route.queryParamMap.subscribe((params) => {
      this.viewQueryParam = params.get("view") as ExportManifestViewQueryParam;
      if (this.viewQueryParam === "details" || this.viewQueryParam === "edit") {
        this.entityIdQueryParam = this.route.snapshot.queryParamMap.get("id");
        this.exportManifestByIdQuery.updateOptions(
          this.exportManifestService.getExportManifestByIdQueryOptions(
            this.entityIdQueryParam,
            this.entityIdQueryParam !== null,
          ),
        );
      }
      const openRightDrawer = this.viewQueryParam !== null;
      if (openRightDrawer) {
        setTimeout(() => {
          this.drawer.open();
        });
      }
    });
  }

  ngOnDestroy() {
    this._paramsSubscription?.unsubscribe();
  }

  closeRightDrawer() {
    this.drawer.close();
  }

  removeQueryParams() {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParamsHandling: "merge",
      queryParams: { view: null, id: null },
    });
  }

  inEditMode(status?: ExportManifestStatus) {
    if (status === undefined) {
      return false;
    }

    switch (status) {
      case ExportManifestStatus.EXPORT_MANIFEST_NOT_YET_REPORTED_TO_CUSTOMS:
      case ExportManifestStatus.EXPORT_MANIFEST_ACCEPTED_AT_CUSTOMS:
      case ExportManifestStatus.EXPORT_MANIFEST_REJECTED_AT_CUSTOMS:
      case ExportManifestStatus.EXPORT_MANIFEST_UPDATED_NOT_YET_REPORTED_TO_CUSTOMS:
        return true;
      default:
        return false;
    }
  }
}
