import { Component, Input, inject } from "@angular/core";
import { MatTableModule } from "@angular/material/table";

import { CommonModule } from "@angular/common";
import { MatButtonModule } from "@angular/material/button";
import { ActivatedRoute, Router } from "@angular/router";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { faChevronRight } from "@fortawesome/pro-regular-svg-icons";
import { TranslateModule } from "@ngx-translate/core";
import { PortbaseExportAaxStatusIndicatorComponent } from "../../../../components";
import { AuthenticationService } from "../../../../core/authentication/authentication.service";
import { OrganisationService } from "../../../../core/services/organisation.service";
import { ArrivalAtExitStatus, ShipmentDto } from "../../../services/shipment.service";
import { resolveDocumentType } from "../../../utils/resolve-document-type.utils";
import { HighlightPipe } from "../../../../core/pipes/highlight.pipe";

@Component({
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    PortbaseExportAaxStatusIndicatorComponent,
    MatTableModule,
    FontAwesomeModule,
    MatButtonModule,
    HighlightPipe,
  ],
  selector: "pbe-arrival-at-exit-customer-service-view-table",
  templateUrl: './customer-service-view-table.component.html',
  styleUrl: "./customer-service-view-table.component.scss"
})
export class ArrivalAtExitCustomerServiceViewTableComponent {
  @Input({ required: true }) shipments!: ShipmentDto[];
  @Input() search: string | null = null;

  authenticationData = inject(AuthenticationService).authenticated;

  organisationService = inject(OrganisationService);

  router = inject(Router);
  route = inject(ActivatedRoute);

  ArrivalAtExitStatus = ArrivalAtExitStatus;

  faChevronRight = faChevronRight;

  displayedColumns = [
    "organisationName",
    "documentNumber",
    "documentType",
    "creationDate",
    "reference",
    "arrivalAtExitStatus",
    "actions",
  ];

  openDetailsPage(entity: ShipmentDto) {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParamsHandling: "merge",
      queryParams: {
        view: "details",
        id: entity.shipmentId,
      },
    });
  }

  mapOrganisationName(orgShortName: string) {
    return this.organisationService.getOrganisationForId(orgShortName)?.name;
  }

  mapDocumentType(documentType: string) {
    return resolveDocumentType(documentType);
  }
}
