import { CommonModule } from "@angular/common";
import {
  Component,
  DestroyRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import {
  FormArray,
  FormControl,
  FormGroup,
  ValidationErrors,
  Validators,
} from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatDialog } from "@angular/material/dialog";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { ButtonIconDirective } from "@portbase/material/button";
import { isEqual, omit } from "lodash";
import { debounceTime } from "rxjs";
import { ArrivalAtExitStatus } from "../../../../arrival-at-exit/services/shipment.service";
import { PortbaseDataItemComponent } from "../../../../components/data-item/data-item.component";
import { DialogService } from "../../../../core/services/dialog/dialog.service";
import {
  Commodity,
  CreateExportManifestShipmentDto,
  ExportManifestAaxShipmentDto,
  ExportManifestShipmentDocumentType,
  ExportManifestShipmentDto,
  PortOfDischarge,
} from "../../../services/export-manifest.service";
import { documentNumberValidator } from "../../../validators/document-number.validator";
import { documentTypeValidator } from "../../../validators/document-type-autocomplete.validator";
import { CopyShipmentsDialogComponent } from "../copy-shipments-dialog/copy-shipments-dialog.component";
import { duplicateDocumentNumberValidator } from "../../../validators/duplicate-document-number.validator";
import { ExportManifestShipmentsTableControlsComponent } from "../export-shipments-table-controls/export-shipments-table-controls.component";
import { ExportManifestShipmentsTableComponent } from "../export-shipments-table/export-shipments-table.component";
import { weightValidator } from "../../../validators/weight.validator";
import { autocompleteSuggestionValidator } from "../../../../core/validators/autocomplete-suggestion.validator";
import { MANIFEST_DOCUMENT_TYPE_PREFERENCE_KEY } from "../../../../constants";
import { PreferencesService } from "../../../../preferences/services/preferences.service";

export interface ExportManifestShipmentFormControls {
  selected: FormControl<boolean>;
  documentType: FormControl<ExportManifestShipmentDocumentType>;
  documentNumber: FormControl<string>;
  referenceNumber: FormControl<string>;
  weight: FormControl<string>;
  arrivalAtExitStatus: FormControl<ArrivalAtExitStatus>;
  partShipment: FormControl<boolean>;
  copiedFromMael: FormControl<boolean>;
  commodity: FormControl<Commodity>;
  portOfDischarge: FormControl<PortOfDischarge>;
}

export interface ExportManifestShipmentFormValue {
  documentType: ExportManifestShipmentDocumentType;
  documentNumber: string;
  referenceNumber: string;
  weight: string;
  arrivalAtExitStatus?: ArrivalAtExitStatus;
  copiedFromMael: boolean;
  partShipment: boolean;
  commodity: Commodity | null;
  portOfDischarge: PortOfDischarge | null;
}

const WEIGHT_PATTERN = /^\d+$/;

const DOCUMENT_NUMBER_MAX_LENGTH = 27;

const REFERENCE_NUMBER_MAX_LENGTH = 25;

const AUTO_SAVE_DEBOUNCE_TIME = 300; // 300 milliseconds

@Component({
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    MatButtonModule,
    FontAwesomeModule,
    ButtonIconDirective,
    MatFormFieldModule,
    MatInputModule,
    PortbaseDataItemComponent,
    ExportManifestShipmentsTableComponent,
    ExportManifestShipmentsTableControlsComponent,
  ],
  selector: "pbe-export-manifest-shipments",
  templateUrl: './export-shipments.component.html',
  styleUrl: './export-shipments.component.scss'
})
export class ExportManifestShipmentsComponent implements OnChanges {
  @Input({ required: true }) initialShipments!: ExportManifestShipmentDto[];

  @Input() readonly = false;
  @Input() inEditMode = false;
  @Input() vesselIsInRotterdam = false;

  @Output() pendingChanges = new EventEmitter<
    CreateExportManifestShipmentDto[]
  >();

  @Output() formValidityChange = new EventEmitter<boolean>();

  formShipments: ExportManifestShipmentFormValue[] = [];

  form = new FormGroup({
    shipments: new FormArray<FormGroup<ExportManifestShipmentFormControls>>([]),
  });

  hasChangesInitialized = false;

  preferredDocumentType = this.preferencesService.getPreference<string>(
    MANIFEST_DOCUMENT_TYPE_PREFERENCE_KEY,
  );

  constructor(
    private dialog: MatDialog,
    private destroyRef: DestroyRef,
    private dialogService: DialogService,
    private translateService: TranslateService,
    private preferencesService: PreferencesService
  ) {
    this.form.valueChanges
      .pipe(
        debounceTime(AUTO_SAVE_DEBOUNCE_TIME),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe(() => {
        const rawFormShipments = this.form.controls.shipments.getRawValue();

        const containsSignificantChanges = !isEqual(
          rawFormShipments.map((shipment) => omit(shipment, "selected")),
          this.formShipments.map((shipment) => omit(shipment, "selected")),
        );

        if (!containsSignificantChanges) {
          return;
        }

        if (!this.isFormValid) {
          this.markAllAsTouched(this.form);
          return;
        }

        this.formShipments = [...rawFormShipments];

        this.emitChanges(
          this.formShipments.map(this.mapShipmentFormValueToDto),
        );
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.hasChangesInitialized) {
      return;
    }

    if (changes["initialShipments"]) {
      const initialShipments = changes["initialShipments"]
        .currentValue as ExportManifestShipmentDto[];

      this.formShipments = initialShipments.map(this.mapShipmentDtoToFormValue);

      this.form.setControl(
        "shipments",
        new FormArray(
          this.formShipments.map((shipment) =>
            this.createShipmentFormGroup(shipment),
          ),
        ),
      );

      if (this.readonly) {
        this.form.disable();
      }

      if (this.inEditMode) {
        this.form.controls.shipments.controls.forEach((control) => {
          control.controls.documentType.disable();
          control.controls.documentNumber.disable();
        });
      }

      this.hasChangesInitialized = true;
    }
  }

  getUsedDocumentNumbers(): string[] {
    return this.formShipments
      .filter((shipment) => shipment !== null)
      .map((shipment) => shipment?.documentNumber)
      .filter(Boolean) as string[];
  }

  async openCopyShipmentsDialog() {
    const dialogRef = this.dialog.open(CopyShipmentsDialogComponent, {
      data: { usedDocumentNumbers: this.getUsedDocumentNumbers() },
    });

    dialogRef
      .afterClosed()
      .subscribe((shipments: ExportManifestAaxShipmentDto[] | null) => {
        if (!shipments) {
          return;
        }

        const newShipments = shipments.map((shipment) => ({
          documentNumber: shipment.documentNumber,
          documentType: shipment.documentType,
          referenceNumber: shipment.reference,
          arrivalAtExitStatus: shipment.arrivalAtExitStatus,
          copiedFromMael: true,
          weight: "",
          partShipment: false,
          seaHarbourStatistics: null,
        })) as ExportManifestShipmentDto[];

        for (const shipment of newShipments) {
          const formShipment = this.mapShipmentDtoToFormValue(shipment);

          this.form.controls.shipments.push(
            this.createShipmentFormGroup(formShipment),
          );
        }

        this.formShipments.push(
          ...newShipments.map(this.mapShipmentDtoToFormValue),
        );

        // If the form is invalid, we don't want to emit the changes
        if (!this.isFormValid) {
          this.markAllAsTouched(this.form);
          return;
        }

        this.emitChanges(
          this.formShipments.map(this.mapShipmentFormValueToDto),
        );
      });
  }

  addShipment() {
    this.form.controls.shipments.push(
      this.createShipmentFormGroup(this.createEmptyShipment()),
    );

    this.formShipments = this.form.controls.shipments.getRawValue();
  }

  async deleteSelectedShipments() {
    if (!this.selectedShipments.length) {
      return;
    }

    const shouldDelete = await this.dialogService.showDeleteConfirmationDialog({
      title: this.translateService.instant(
        "services.exportManifest.deleteShipmentsDialog.title",
      ),
      description: this.translateService.instant(
        "services.exportManifest.deleteShipmentsDialog.description",
        { count: this.selectedShipments.length },
      ),
      confirmationButtonLabel: this.translateService.instant(
        "services.exportManifest.deleteShipmentsDialog.confirmButton",
      ),
      cancelButtonLabel: this.translateService.instant(
        "services.exportManifest.deleteShipmentsDialog.cancelButton",
      ),
      confirmationButtonColor: "warn",
    });

    if (!shouldDelete) {
      return;
    }

    for (const shipment of this.selectedShipments) {
      this.form.controls.shipments.removeAt(
        this.form.controls.shipments.value.indexOf(shipment),
      );
    }

    this.formShipments = this.form.controls.shipments.getRawValue();

    this.pendingChanges.emit(
      this.formShipments.map(this.mapShipmentFormValueToDto),
    );
  }

  markAllAsTouched(formGroup: FormGroup | FormArray): void {
    Object.keys(formGroup.controls).forEach((key) => {
      const control = formGroup.get(key);

      if (control instanceof FormGroup || control instanceof FormArray) {
        this.markAllAsTouched(control);
      } else {
        control?.markAsTouched();
      }
    });
  }

  get selectedShipments() {
    return this.form.controls.shipments.value.filter(
      (shipment) => shipment.selected,
    );
  }

  get isFormValid() {
    const errors = this.findErrorsRecursive(this.form);

    // Used for document number validation
    const warningKeys = [
      "invalidYear",
      "invalidCountry",
      "invalidAlphaNumeric",
      "invalidCheckDigit",
      "invalidChecksum",
      "minlength",
      "maxlength",
    ];

    const isFormValid = errors.every((error) => {
      const errorKeys = Object.keys(error);
      return errorKeys.every((key) => warningKeys.includes(key));
    });

    if (this.form.hasError("duplicateDocumentNumbers")) {
      this.formValidityChange.emit(true);
      return false;
    }

    this.formValidityChange.emit(!isFormValid);
    return isFormValid;
  }

  get disableTableControls() {
    return (
      !this.isFormValid || this.selectedShipments.length > 0 || this.readonly
    );
  }

  private findErrorsRecursive(
    formToInvestigate: FormGroup | FormArray,
  ): ValidationErrors[] {
    const errors: ValidationErrors[] = [];

    const recursiveFunc = (form: FormGroup | FormArray) => {
      Object.keys(form.controls).forEach((field) => {
        const control = form.get(field);

        if (control?.errors) {
          errors.push(control.errors);
        }

        if (control instanceof FormGroup) {
          recursiveFunc(control);
        } else if (control instanceof FormArray) {
          recursiveFunc(control);
        }
      });
    };

    recursiveFunc(formToInvestigate);
    return errors;
  }

  private createEmptyShipment(): ExportManifestShipmentFormValue {
    return {
      documentType: this.preferredDocumentType as ExportManifestShipmentDocumentType ?? "EX" as ExportManifestShipmentDocumentType,
      documentNumber: "",
      referenceNumber: "",
      weight: "",
      arrivalAtExitStatus: "" as ArrivalAtExitStatus,
      partShipment: false,
      copiedFromMael: false,
      commodity: null,
      portOfDischarge: null,
    };
  }

  private createShipmentFormGroup(shipment: ExportManifestShipmentFormValue) {
    const formGroup = new FormGroup({
      selected: new FormControl(false),
      documentType: new FormControl(shipment.documentType || "", {
        validators: [Validators.required, documentTypeValidator()],
      }),
      documentNumber: new FormControl(shipment.documentNumber || "", {
        validators: [
          Validators.required,
          Validators.maxLength(DOCUMENT_NUMBER_MAX_LENGTH),
          duplicateDocumentNumberValidator(
            () => this.getUsedDocumentNumbers(),
            () => shipment.documentNumber,
          ),
          documentNumberValidator(),
        ],
      }),
      referenceNumber: new FormControl(shipment.referenceNumber || "", {
        validators: [Validators.maxLength(REFERENCE_NUMBER_MAX_LENGTH)],
      }),
      weight: new FormControl(shipment.weight || "", {
        validators: [Validators.pattern(WEIGHT_PATTERN), Validators.maxLength(10)],
      }),
      arrivalAtExitStatus: new FormControl(shipment.arrivalAtExitStatus),
      partShipment: new FormControl(shipment.partShipment || false),
      copiedFromMael: new FormControl(shipment.copiedFromMael || false),
      commodity: new FormControl(shipment.commodity || null),
      portOfDischarge: new FormControl(shipment.portOfDischarge || null),
    });

    if (this.vesselIsInRotterdam) {
      formGroup.controls.weight.addValidators(
        weightValidator({ requiredByVesselInRotterdam: true }),
      );
      formGroup.controls.commodity.addValidators([
        Validators.required,
        autocompleteSuggestionValidator(),
      ]);
      formGroup.controls.portOfDischarge.addValidators([
        Validators.required,
        autocompleteSuggestionValidator(),
      ]);

      formGroup.controls.weight.updateValueAndValidity();
      formGroup.controls.commodity.updateValueAndValidity();
      formGroup.controls.portOfDischarge.updateValueAndValidity();
    } else {
      formGroup.controls.commodity.disable();
      formGroup.controls.portOfDischarge.disable();
    }

    return formGroup as FormGroup<ExportManifestShipmentFormControls>;
  }

  private emitChanges(shipments: CreateExportManifestShipmentDto[]) {
    this.pendingChanges.emit(
      shipments.filter(
        (shipment) =>
          (shipment.documentType as string) !== "" &&
          shipment.documentNumber !== "",
      ),
    );
  }

  private mapShipmentFormValueToDto(
    shipment: ExportManifestShipmentFormValue,
  ): CreateExportManifestShipmentDto {
    return {
      documentType: shipment.documentType,
      documentNumber: shipment.documentNumber,
      referenceNumber: shipment.referenceNumber,
      weight: shipment.weight,
      partShipment: shipment.partShipment,
      copiedFromMael: shipment.copiedFromMael ?? false,
      commodityId: shipment.commodity?.id || null,
      portOfDischargeId: shipment.portOfDischarge?.id || null,
    };
  }

  private mapShipmentDtoToFormValue(
    shipment: ExportManifestShipmentDto,
  ): ExportManifestShipmentFormValue {
    const seaHarbourStatistics = shipment.seaHarbourStatistics
      ? {
          commodity: {
            id: shipment.seaHarbourStatistics.commodityId,
            code: shipment.seaHarbourStatistics.commodityCode,
            description: shipment.seaHarbourStatistics.commodityDescription,
          },
          portOfDischarge: {
            id: shipment.seaHarbourStatistics.portOfDischargeId,
            portName: shipment.seaHarbourStatistics.portOfDischargeName,
            portCode: shipment.seaHarbourStatistics.portOfDischargeCode,
          },
        }
      : null;

    return {
      documentType: shipment.documentType,
      documentNumber: shipment.documentNumber,
      referenceNumber: shipment.referenceNumber,
      weight: shipment.weight,
      arrivalAtExitStatus: shipment.arrivalAtExitStatus,
      partShipment: shipment.partShipment || false,
      copiedFromMael: shipment.copiedFromMael ?? false,
      commodity: seaHarbourStatistics?.commodity || null,
      portOfDischarge: seaHarbourStatistics?.portOfDischarge || null,
    };
  }
}
