import { Component, Input, inject } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { ActivatedRoute, Router } from "@angular/router";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { faMagnifyingGlass, faPlus } from "@fortawesome/pro-light-svg-icons";
import { faShip } from "@fortawesome/pro-regular-svg-icons";
import { TranslateModule } from "@ngx-translate/core";
import { ButtonIconDirective } from "@portbase/material/button";

export type EmptyStateVariant = "empty" | "emptyQuickFilter" | "emptyNoFilter";

@Component({
  standalone: true,
  imports: [
    FontAwesomeModule,
    TranslateModule,
    MatButtonModule,
    ButtonIconDirective,
  ],
  selector: "pbe-overview-table-empty-state",
  templateUrl: './empty-state.component.html',
  styleUrl: "./empty-state.component.scss"
})
export class OverviewTableEmptyStateComponent {
  router = inject(Router);
  route = inject(ActivatedRoute);
  faMagnifyingGlass = faMagnifyingGlass;
  faShip = faShip;
  faPlus = faPlus;

  @Input() emptyStateVariant: EmptyStateVariant = "emptyQuickFilter";
  @Input() service!: "exportManifest" | "arrivalAtExit";
}
