import { HttpClient } from "@angular/common/http";
import { Injectable, inject } from "@angular/core";
import { injectQuery, queryOptions } from "@ngneat/query";
import { AppConfigService } from "../../core/services/app-config.service";
import { InspectionType } from "./inspection-type.service";
import { RejectionReasonResponse } from "./rejection-reasons.service";

export enum ShipmentOperationType {
  AAX_SHIPMENT_CREATED = "AAX_SHIPMENT_CREATED",
  AAX_SHIPMENT_SENT_TO_CUSTOMS = "AAX_SHIPMENT_SENT_TO_CUSTOMS",
  AAX_SHIPMENT_DETAILS_CHANGED = "AAX_SHIPMENT_DETAILS_CHANGED",
  AAX_SHIPMENT_CARGO_HANDLING_AGENT_CHANGED = "AAX_SHIPMENT_CARGO_HANDLING_AGENT_CHANGED",
  AAX_INSPECTION_OF_EXPORT_SHIPMENTS_AT_CUSTOMS = "AAX_INSPECTION_OF_EXPORT_SHIPMENTS_AT_CUSTOMS",
  AAX_EXPORT_SHIPMENT_REJECTED_AT_CUSTOMS = "AAX_EXPORT_SHIPMENT_REJECTED_AT_CUSTOMS",
  AAX_EXPORT_SHIPMENT_RELEASED_AT_CUSTOMS = "AAX_EXPORT_SHIPMENT_RELEASED_AT_CUSTOMS",
  AAX_EXPORT_SHIPMENT_ACCEPTED_AT_CUSTOMS = "AAX_EXPORT_SHIPMENT_ACCEPTED_AT_CUSTOMS",
}

export type ShipmentCreatedPayload = never;

export interface ShipmentDetailsChangedPayload {
  attribute:
    | "documentType"
    | "documentNumber"
    | "reference"
    | "arrivalLocationId";
  change: ValueHolder;
}

export interface ExportShipmentRejectedAtCustomsPayload {
  documentNumber: string;
  rejectionReason: RejectionReasonResponse;
}

export interface InspectionOfExportShipmentsAtCustomsPayload {
  documentNumber: string;
  inspectionType: InspectionType;
}

export type ShipmentSentToCustomsPayload = never;

export type ExportShipmentAcceptedAtCustomsPayload = never;

export type ExportShipmentReleasedAtCustomsPayload = never;

export interface ShipmentCargoHandlingAgentChangedPayload {
  change: ValueHolder;
}

export interface ValueHolder<L = string, R = string> {
  oldValue: L;
  newValue: R;
}

export interface ShipmentHistoryDto<Payload = ShipmentOperationType> {
  id: string;
  shipmentId: string;
  organisationShortName: string;
  userFullName: string | null;
  operationDateTime: string;
  operationType: ShipmentOperationType;
  operationPayload: Payload extends ShipmentOperationType.AAX_SHIPMENT_CREATED
    ? ShipmentCreatedPayload
    : Payload extends ShipmentOperationType.AAX_EXPORT_SHIPMENT_RELEASED_AT_CUSTOMS
      ? ExportShipmentReleasedAtCustomsPayload
      : Payload extends ShipmentOperationType.AAX_EXPORT_SHIPMENT_ACCEPTED_AT_CUSTOMS
        ? ExportShipmentAcceptedAtCustomsPayload
        : Payload extends ShipmentOperationType.AAX_SHIPMENT_SENT_TO_CUSTOMS
          ? ShipmentSentToCustomsPayload
          : Payload extends ShipmentOperationType.AAX_SHIPMENT_DETAILS_CHANGED
            ? ShipmentDetailsChangedPayload
            : Payload extends ShipmentOperationType.AAX_SHIPMENT_CARGO_HANDLING_AGENT_CHANGED
              ? ShipmentCargoHandlingAgentChangedPayload
              : Payload extends ShipmentOperationType.AAX_INSPECTION_OF_EXPORT_SHIPMENTS_AT_CUSTOMS
                ? InspectionOfExportShipmentsAtCustomsPayload
                : Payload extends ShipmentOperationType.AAX_EXPORT_SHIPMENT_REJECTED_AT_CUSTOMS
                  ? ExportShipmentRejectedAtCustomsPayload
                  : never;
}

@Injectable({ providedIn: "root" })
export class ShipmentHistoryService {
  #http = inject(HttpClient);
  #query = injectQuery();

  private readonly _appConfigService = inject(AppConfigService);
  private readonly _baseUrl = `${this._appConfigService.getConfig().apiUrl}`;

  shipmentHistoryQueryOptions(id: string | null) {
    return queryOptions({
      queryKey: ["shipments", id, "history"] as const,
      queryFn: () =>
        this.#http.get<ShipmentHistoryDto[]>(
          `${this._baseUrl}/shipments/${id}/history`,
        ),
      enabled: id !== null,
    });
  }

  getHistoryForShipmentId(id: string | null) {
    return this.#query(this.shipmentHistoryQueryOptions(id));
  }
}
