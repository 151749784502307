<div
  class="flex flex-row justify-between items-center"
  (click)="triggerRef.openPanel(); textareaRef.focus()"
>
  <ng-container [formGroup]="formGroup">
    <textarea
      wrap="soft"
      matInput
      [placeholder]="
        'services.exportManifest.createPage.fields.portOfDischargePlaceholder' +
          (formGroup.get(controlName)?.hasError('required')
            ? 'Required'
            : 'NotApplicable') | translate
      "
      [formControlName]="controlName"
      [matAutocomplete]="auto"
      [matAutocompleteDisabled]="isDisabled"
      class="w-full resize-none overflow-hidden placeholder:font-normal placeholder:text-gray-400 placeholder:text-sm font-normal text-gray-800 text-sm disabled:cursor-not-allowed"
      autocomplete="off"
      pbTextareaAutoresize
      #triggerRef="matAutocompleteTrigger"
      #textareaRef
      rows="1"
    ></textarea>

    @if (
      formGroup.controls[controlName].invalid &&
      formGroup.controls[controlName].touched
    ) {
      <fa-icon
        matSuffix
        size="lg"
        [icon]="faCircleExclamation"
        class="py-1.5 px-2 text-red-700 rounded-full hover:bg-red-100"
        [tp]="errorLabel"
        tpVariation="tooltipArrow"
        [tpOffset]="[0, 0]"
      ></fa-icon>
    } @else if (!isDisabled) {
      <fa-icon
        matSuffix
        size="lg"
        [icon]="faChevronDown"
        class="py-1.5 px-2 rounded-full text-blue-700 group-hover:bg-blue-100"
        [ngClass]="{
          'bg-blue-200 group-hover:bg-blue-200': isFocused
        }"
      ></fa-icon>
    }

    <mat-autocomplete
      #auto="matAutocomplete"
      [hideSingleSelectionIndicator]="true"
      [displayWith]="displayFn"
      class="em-port-of-discharge-autocomplete"
    >
      <mat-option *ngFor="let port of suggestionsForPorts" [value]="port">
        {{ port.portCode }} - {{ port.portName }}
      </mat-option>
    </mat-autocomplete>
  </ng-container>
</div>
