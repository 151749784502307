import { format } from "date-fns";

export function formatDateRange(
  start: Date,
  end: Date,
  dateFormat = "dd-MM-yyyy"
): string {
  return `${format(start, dateFormat)} - ${format(end, dateFormat)} `;
}

export function formatDate(date: Date, dateFormat = "dd-MM-yyyy"): string {
  return format(date, dateFormat);
}
export function formatDateForApi(date: Date): string {
  return date.toISOString();
}

export function formatDateAndTime(
  date: Date,
  dateFormat = "dd-MM-yyyy HH:mm:ss"
): string {
  return format(date, dateFormat);
}
