<div
  class="fixed w-screen h-screen top-0 left-0 z-[1] pointer-events-none"
>
  <div
    class="bg-black transition-opacity opacity-0 w-full h-full"
    [class]="
        drawer.opened
          ? 'opacity-50 pointer-events-auto'
          : 'opacity-0 pointer-events-none'
      "
    (click)="closeRightDrawer()"
  ></div>
  <mat-drawer
    (closed)="removeQueryParams()"
    #drawer
    mode="over"
    class="w-screen max-w-5xl z-10 pointer-events-auto"
    position="end"
  >
    <div class="bg-white h-full w-full  z-10">
      @if (drawer.opened) {
        @switch (viewQueryParam) {
          @case ("create") {
            <pbe-arrival-at-exit-create-page
              (createCancel)="closeRightDrawer()"
              (createSuccess)="closeRightDrawer()"
              [userCanAssignCargoHandlingAgent]="
                  userRights().includes(UserRights.AssignCargoHandlingAgent)
                "
            ></pbe-arrival-at-exit-create-page>
          }
          @case ("details") {
            @if (shipment().data !== undefined) {
              <pbe-arrival-at-exit-details-page
                (detailsClose)="closeRightDrawer()"
                [shipment]="shipment().data!"
                [userCanAssignCargoHandlingAgent]="
                    userRights().includes(UserRights.AssignCargoHandlingAgent)
                  "
              ></pbe-arrival-at-exit-details-page>
            }
          }
          @case ("edit") {
            @if (shipment().data !== undefined) {
              <pbe-arrival-at-exit-edit-page
                (editClose)="closeRightDrawer()"
                (editSuccess)="closeRightDrawer()"
                [shipment]="shipment().data!"
                [userCanAssignCargoHandlingAgent]="
                    userRights().includes(UserRights.AssignCargoHandlingAgent)
                  "
              ></pbe-arrival-at-exit-edit-page>
            }
          }
        }
      }
    </div>
  </mat-drawer>
</div>
