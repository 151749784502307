import { AfterViewInit, Component, DestroyRef, ViewChild } from "@angular/core";
import { ExportManifestDetailsPageComponent } from "../../../details-page/details-page.component";
import { MatDrawer, MatSidenavModule } from "@angular/material/sidenav";
import { AuthenticationService, UserRights } from "../../../../core/authentication/authentication.service";
import { ActivatedRoute, Router } from "@angular/router";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { ExportManifestService } from "../../../services/export-manifest.service";
import { ExportManifestCreatePageComponent } from "../../../details-page/create-page/create-page.component";
import { ExportManifestEditPageComponent } from "../../../details-page/edit-page/edit-page.component";

type ExportManifestViewQueryParam = "details" | null;

@Component({
  selector: "pbe-export-manifest-customer-service-right-drawer",
  standalone: true,
  imports: [
    ExportManifestCreatePageComponent,
    ExportManifestDetailsPageComponent,
    ExportManifestEditPageComponent,
    MatSidenavModule,
  ],
  templateUrl: "./customer-service-right-drawer.component.html",
  styleUrl: "./customer-service-right-drawer.component.scss",
})
export class CustomerServiceRightDrawerComponent implements AfterViewInit {
  entityIdQueryParam: string | null = null;

  UserRights = UserRights;
  userRights = this.authenticationService.userRights;

  exportManifestByIdQuery = this.exportManifestService.getExportManifestById(
    this.entityIdQueryParam,
  );
  exportManifest = this.exportManifestByIdQuery.result;

  @ViewChild("drawer") public drawer!: MatDrawer;

  viewQueryParam: ExportManifestViewQueryParam = null;

  constructor(private router: Router, private route: ActivatedRoute, private authenticationService: AuthenticationService, private exportManifestService: ExportManifestService, private destroyRef: DestroyRef) {
  }

  ngAfterViewInit() {
    this.route.queryParamMap.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((params) => {
      this.viewQueryParam = params.get("view") as ExportManifestViewQueryParam;
      if (this.viewQueryParam === "details") {
        this.entityIdQueryParam = this.route.snapshot.queryParamMap.get("id");
        this.exportManifestByIdQuery.updateOptions(
          this.exportManifestService.getExportManifestByIdQueryOptions(
            this.entityIdQueryParam,
            this.entityIdQueryParam !== null,
          ),
        );
      }
      const openRightDrawer = this.viewQueryParam !== null;
      if (openRightDrawer) {
        setTimeout(() => {
          this.drawer.open();
        });
      }
    });
  }

  closeRightDrawer() {
    this.drawer.close();
  }

  removeQueryParams() {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParamsHandling: "merge",
      queryParams: { view: null, id: null },
    });
  }
}
