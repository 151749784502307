import { CommonModule, DatePipe } from "@angular/common";
import { Component, EventEmitter, Input, Output } from "@angular/core";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatPseudoCheckboxModule } from "@angular/material/core";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { TranslateModule } from "@ngx-translate/core";
import { PortbaseExportAaxStatusIndicatorComponent } from "../../../../components";
import { PortbaseDataItemComponent } from "../../../../components/data-item/data-item.component";
import { ExportManifestAaxShipmentDto } from "../../../services/export-manifest.service";

@Component({
  standalone: true,
  imports: [
    TranslateModule,
    CommonModule,
    PortbaseDataItemComponent,
    PortbaseExportAaxStatusIndicatorComponent,
    MatCheckboxModule,
    MatPseudoCheckboxModule,
    MatSlideToggleModule,
    DatePipe,
  ],
  selector: "pbe-export-manifest-copy-shipment-list-item",
  templateUrl: './copy-shipment-list-item.component.html',
  styleUrl: "./copy-shipment-list-item.component.scss"
})
export class CopyShipmentListItemComponent {
  @Input({ required: true }) shipment!: ExportManifestAaxShipmentDto;
  @Input({ required: true }) selected!: boolean;
  @Output() toggleSelected = new EventEmitter();

  formatDocumentTypeAndNumber() {
    return `${this.shipment?.documentType} | ${this.shipment?.documentNumber}`;
  }
}
