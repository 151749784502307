import { NgIf } from "@angular/common";
import { Component, inject } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MatDividerModule } from "@angular/material/divider";
import { MatListModule } from "@angular/material/list";
import { MatToolbarModule } from "@angular/material/toolbar";
import { RouterModule } from "@angular/router";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import {
  faBullhorn,
  faCheck,
  faHeadset,
  faUserGear,
} from "@fortawesome/pro-light-svg-icons";
import { TippyDirective } from "@ngneat/helipopper";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { AmplitudeModule } from "@portbase/amplitude";
import { BeamerService } from "@portbase/beamer";
import { ButtonIconDirective } from "@portbase/material/button";
import { LANGUAGE_PREFERENCE_KEY } from "../constants";
import { WINDOW } from "../window";
import { AuthenticationService } from "./authentication/authentication.service";

@Component({
  standalone: true,
  imports: [
    FontAwesomeModule,
    RouterModule,
    NgIf,
    MatButtonModule,
    ButtonIconDirective,
    MatToolbarModule,
    MatListModule,
    TranslateModule,
    MatDividerModule,
    TippyDirective,
    AmplitudeModule,
  ],
  selector: "pbe-topbar",
  templateUrl: './topbar.component.html',
  styleUrl: "./topbar.component.scss"
})
export class PortbaseExportTopbarComponent {
  faBullhorn = faBullhorn;
  faHeadset = faHeadset;
  faUserGear = faUserGear;
  faCheck = faCheck;
  window = inject(WINDOW);

  translateService = inject(TranslateService);
  authenticationService = inject(AuthenticationService);
  beamerService = inject(BeamerService);

  profile = this.authenticationService.getProfile().result;

  setLanguage(lang: "en" | "nl") {
    this.translateService.use(lang);
    if (this.window.localStorage) {
      this.window.localStorage.setItem(LANGUAGE_PREFERENCE_KEY, lang);
    }
  }

  logout() {
    this.window.location.href = this.authenticationService.logoutUrl;
  }
}
