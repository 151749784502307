import { init, track, Types } from '@amplitude/analytics-browser';
import { Inject, Injectable } from '@angular/core';
import { AMPLITUDE_CONFIG } from './amplitude-config.token';
import { AmplitudeConfig } from './amplitude-config.model';
import { AmplitudeServiceInterface } from './amplitude-service.interface';

const DEFAULT_BROWSER_OPTIONS: Types.BrowserOptions = {
  flushIntervalMillis: 200,
  trackingOptions: {
    ipAddress: false
  }
};

@Injectable()
export class AmplitudeService implements AmplitudeServiceInterface {

  private initialized = false;

  constructor(@Inject(AMPLITUDE_CONFIG) private readonly config: AmplitudeConfig) {
  }

  isEnabled(): boolean {
    return this.config && this.config.enabled;
  }

  init(apiKey: string): void {
    if (this.initialized) {
      throw new Error('Amplitude is already initialized. Please make sure \'init\' is only called once.');
    }

    init(apiKey, undefined, DEFAULT_BROWSER_OPTIONS);
    this.initialized = true;
  }

  trackEvent(eventType: string, eventProperties?: Record<string, unknown>): Promise<Types.Result|void> {
    if (!this.isEnabled()) {
      return Promise.resolve();
    }

    return track(eventType, eventProperties).promise;
  }

}
