import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";
import { ExportManifestShipmentDocumentType } from "../services/export-manifest.service";

const DOCUMENT_TYPES: ExportManifestShipmentDocumentType[] = [
  "AAD",
  "ATA",
  "CO",
  "EU",
  "EX",
  "ICT",
  "PPE",
  "RAR",
  "REN",
  "REX",
  "RTR",
  "RT1",
  "RT2",
  "TAR",
  "TIR-D",
  "TIR-R",
  "TT1",
  "TT2",
  "ZZZ",
];

export function documentTypeValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (control.value === null || control.value === "") {
      return null;
    }

    if (DOCUMENT_TYPES.includes(control.value)) {
      return null;
    }

    return { invalidDocumentType: true };
  };
}
