import { APP_INITIALIZER, ModuleWithProviders, NgModule } from '@angular/core';
import { AmplitudeEventDirective } from './amplitude-event.directive';
import { amplitudeInitializer } from './amplitude-initializer';
import { AmplitudeService } from './amplitude.service';
import { AmplitudeLocationEventDirective } from './amplitude-location-event.directive';
import { AMPLITUDE_CONFIG } from './amplitude-config.token';

@NgModule({
  declarations: [
    AmplitudeEventDirective,
    AmplitudeLocationEventDirective
  ],
  exports: [
    AmplitudeEventDirective,
    AmplitudeLocationEventDirective
  ],
})
export class AmplitudeModule {
  static forRoot(): ModuleWithProviders<AmplitudeModule> {
    return {
      ngModule: AmplitudeModule,
      providers: [
        {
          provide: APP_INITIALIZER,
          useFactory: amplitudeInitializer,
          deps: [AMPLITUDE_CONFIG, AmplitudeService],
          multi: true
        },
        AmplitudeService
      ]
    }
  };
}
