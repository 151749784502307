<pbe-export-manifest-shipments-table-controls
  [disableActions]="disableTableControls"
  [shipmentsPendingDeletion]="selectedShipments.length"
  (openCopyShipmentsDialog)="openCopyShipmentsDialog()"
  (addShipment)="addShipment()"
  (deleteSelectedShipments)="deleteSelectedShipments()"
></pbe-export-manifest-shipments-table-controls>

<pbe-export-manifest-shipments-table
  [readonly]="readonly"
  [formGroup]="form"
  [vesselIsInRotterdam]="vesselIsInRotterdam"
></pbe-export-manifest-shipments-table>

@if (formShipments.length < 1) {
  <div class="w-full mt-20 flex justify-center items-center flex-col">
    <p
      class="font-sans text-gray-700 normal text-sm"
      [innerHTML]="
            'services.exportManifest.shipmentsTable.manualEntry' | translate
          "
    ></p>
    <p
      class="font-sans text-gray-700 normal text-sm"
      [innerHTML]="
            'services.exportManifest.shipmentsTable.uploadExcel' | translate
          "
    ></p>
    <p
      class="font-sans text-gray-700 normal text-sm"
      [innerHTML]="
            'services.exportManifest.shipmentsTable.copyFromExisting'
              | translate
          "
    ></p>
  </div>
}
