import {
  AfterViewInit,
  DestroyRef,
  Directive,
  ElementRef,
  HostListener,
  OnInit,
  Optional,
  Renderer2,
  Self,
} from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { NgControl } from "@angular/forms";

@Directive({
  selector: "[pbTextareaAutoresize]",
  standalone: true,
})
export class TextareaAutoresizeDirective implements OnInit, AfterViewInit {
  private minHeight = 20;

  constructor(
    private elementRef: ElementRef,
    @Optional() @Self() private ngControl: NgControl,
    private renderer: Renderer2,
    private destroyRef: DestroyRef,
  ) {}

  ngOnInit(): void {
    if (this.ngControl) {
      this.ngControl.valueChanges
        ?.pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe(() => {
          this.adjustHeight();
        });
    }
  }

  ngAfterViewInit(): void {
    setTimeout(() => this.adjustHeight(), 0);
  }

  @HostListener("input") onInput(): void {
    this.adjustHeight();
  }

  private adjustHeight(): void {
    const textarea = this.elementRef.nativeElement;

    this.renderer.setStyle(textarea, "height", "auto");

    const newHeight = Math.max(textarea.scrollHeight, this.minHeight);
    this.renderer.setStyle(textarea, "height", `${newHeight}px`);
  }
}
