import { AmplitudeConfig } from './amplitude-config.model';
import { AmplitudeService } from './amplitude.service';

export function amplitudeInitializer(
  config: AmplitudeConfig,
  service: AmplitudeService) {
  return (): void => {
    if (!config || !config.enabled) {
      return;
    }

    if (!config.apiKey) {
      throw new Error('Amplitude API key was not defined. Please provide a valid AMPLITUDE_CONFIG with an api key.');
    }

    service.init(config.apiKey);
  }
}
