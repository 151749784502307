import { Component, effect, inject } from "@angular/core";
import {
  AuthenticationService,
  UserRights,
} from "../../core/authentication/authentication.service";
import { OrganisationService } from "../../core/services/organisation.service";
import { PreferencesService } from "../../preferences/services/preferences.service";
import { ArrivalLocationService } from "../services/arrival-location.service";

@Component({
  selector: "pbe-data-prefetcher",
  standalone: true,
  template: `<ng-container></ng-container>`,
})
export class DataPrefetcherComponent {
  // Always preload arrival locations, as the information is basically always required for detail pages.
  arrivalLocations = inject(ArrivalLocationService).getArrivalLocations()
    .result;

  arrivalLocationAliases =
    inject(PreferencesService).getOrganisationArrivalLocationAliases().result;

  userRoles = inject(AuthenticationService).userRoles;
  userRights = inject(AuthenticationService).userRights;

  // Always preload cargo handling agents, as the information is basically always required for detail pages for terminal.
  cargoHandlingAgentsService = inject(OrganisationService);
  cargoHandlingAgentsQuery =
    this.cargoHandlingAgentsService.getCargoHandlingAgents();
  cargoHandlingAgents = this.cargoHandlingAgentsQuery.result;

  // Always preload organisations, as the information is basically always required for table views & filters.
  organisationsQuery =
    this.cargoHandlingAgentsService.getOrganisationsForCustomerService();
  organisations = this.organisationsQuery.result;

  constructor() {
    effect(() => {
      if (this.userRights().includes(UserRights.AssignCargoHandlingAgent)) {
        this.cargoHandlingAgentsQuery.updateOptions(
          this.cargoHandlingAgentsService.getCargoHandlingAgentsQueryOptions(
            true,
          ),
        );
      }

      if (this.userRights().includes(UserRights.SeeCustomerServiceView)) {
        this.organisationsQuery.updateOptions(
          this.cargoHandlingAgentsService.getOrganisationsForCustomerServiceQueryOptions(
            true,
          ),
        );
      }
    });
  }
}
