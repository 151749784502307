<div class="flex flex-col mt-4">
  <h2 class="text-lg font-medium">Export Manifest</h2>
  <div class="flex flex-col gap-5 max-w-md">
    <pbe-form-select
      label="Default document type"
      [form]="form"
      controlName="defaultDocumentType"
      placeholder="Select default document type..."
      [options]="this.documentTypeOptions"
    />

    <div class="flex flex-col w-full">
      <h2 class="text-base font-medium">
        {{ "preferences.notifications.title" | translate }}
      </h2>
      <p class="text-sm my-3">
        {{ "preferences.notifications.description" | translate }}
      </p>

      @if (
        personalNotificationSettings &&
        organisationNotificationSettings().data
        ) {
        @for (type of notificationTypes; track $index) {
          <pbe-email-address-list
            [notificationType]="type"
            [emailList]="
                  organisationNotificationSettings().data?.[type] ?? []
                "
            [isUserSubscribed]="personalNotificationSettings[type]"
            [canEdit]="
                  userRights().includes(UserRights.ConfigureEmailAddresses)
                "
            (toggleSubscription)="
                  updatePersonalNotificationSettings(type, $event)
                "
            (emailListChange)="
                  updateOrganisationNotificationSettings(type, $event)
                "
          />

          @if ($index < notificationTypes.length - 1) {
            <hr />
          }
        }
      }
    </div>
  </div>
</div>
