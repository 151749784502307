<table mat-table [dataSource]="manifests">
  <ng-container matColumnDef="callReferenceNumber">
    <th mat-header-cell *matHeaderCellDef>
      {{
        "services.exportManifest.overview.table.headers.callReferenceNumber"
          | translate
      }}
    </th>
    <td
      mat-cell
      *matCellDef="let element"
      [innerHTML]="element.callReferenceNumber | highlight: search"
    ></td>
  </ng-container>
  <ng-container matColumnDef="vesselName">
    <th mat-header-cell *matHeaderCellDef>
      {{
        "services.exportManifest.overview.table.headers.vesselName"
          | translate
      }}
    </th>
    <td
      mat-cell
      *matCellDef="let element"
      [innerHTML]="element.vesselName | highlight: search"
    ></td>
  </ng-container>
  <ng-container matColumnDef="registeredByOrg">
    <th mat-header-cell *matHeaderCellDef>
      {{
        "services.exportManifest.overview.table.headers.registeredByOrg"
          | translate
      }}
    </th>
    <td
      mat-cell
      *matCellDef="let element"
    >{{ mapOrganisationShortNameToName(element.registeredByOrg) }}</td>
  </ng-container>
  <ng-container matColumnDef="voyageNumber">
    <th mat-header-cell *matHeaderCellDef>
      {{
        "services.exportManifest.overview.table.headers.voyageNumber"
          | translate
      }}
    </th>
    <td mat-cell *matCellDef="let element">
      {{ element.voyageNumber }}
    </td>
  </ng-container>
  <ng-container matColumnDef="departureDate">
    <th mat-header-cell *matHeaderCellDef>
      {{
        "services.exportManifest.overview.table.headers.departureDate"
          | translate
      }}
    </th>
    <td mat-cell *matCellDef="let element">
      {{ element.departureDate | date: "dd-MM-yyyy" }}
    </td>
  </ng-container>
  <ng-container matColumnDef="vesselStatus">
    <th mat-header-cell *matHeaderCellDef>
      {{
        "services.exportManifest.overview.table.headers.vesselStatus"
          | translate
      }}
    </th>
    <td mat-cell *matCellDef="let element">
      {{ "general.vesselStatus.short." + element.vesselStatus | translate }}
    </td>
  </ng-container>
  <ng-container matColumnDef="manifestStatus">
    <th mat-header-cell *matHeaderCellDef>
      {{
        "services.exportManifest.overview.table.headers.manifestStatus"
          | translate
      }}
    </th>
    <td mat-cell *matCellDef="let element">
      <pbe-em-status-indicator [status]="element.manifestStatus" />
    </td>
  </ng-container>

  <ng-container matColumnDef="actions">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell *matCellDef="let element">
      <button
        class="flex flex-row pl-3 float-right text-sm font-medium text-pb-blue-700 hover:underline hover:text-pb-blue-900 active:text-pb-blue-950"
        (click)="openDetailsPage(element)"
      >
        {{"services.exportManifest.overview.table.actions.details"
        | translate}}
        <fa-icon class="ml-4" [icon]="faChevronRight"></fa-icon>
      </button>
    </td>
  </ng-container>
  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
</table>
