import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";

export function duplicateDocumentNumberValidator(
  getUsedDocumentNumbers: () => string[],
  getInitialDocumentNumber: () => string | undefined,
): ValidatorFn {
  return (control: AbstractControl<string>): ValidationErrors | null => {
    const { value } = control;

    if (
      getUsedDocumentNumbers().includes(value) &&
      value !== getInitialDocumentNumber()
    ) {
      return { duplicateDocumentNumber: true };
    }
    return null;
  };
}
