import { CommonModule, NgClass, NgFor, NgIf } from "@angular/common";
import { Component, Input } from "@angular/core";
import { FormGroup, ReactiveFormsModule } from "@angular/forms";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatButtonModule } from "@angular/material/button";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { faCircleExclamation } from "@fortawesome/pro-regular-svg-icons";
import { TippyDirective } from "@ngneat/helipopper";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { ButtonIconDirective } from "@portbase/material/button";
import { TextareaAutoresizeDirective } from "../../../directives/textarea-autoresize.directive";

@Component({
  selector: "pbe-em-reference-form-input",
  standalone: true,
  imports: [
    NgIf,
    NgFor,
    CommonModule,
    MatFormFieldModule,
    TranslateModule,
    MatInputModule,
    ReactiveFormsModule,
    NgClass,
    MatAutocompleteModule,
    TextareaAutoresizeDirective,
    FontAwesomeModule,
    ButtonIconDirective,
    MatButtonModule,
    TippyDirective,
  ],
  templateUrl: './reference-form-input.component.html',
  styleUrl: './reference-form-input.component.scss'
})
export class PortbaseExportEmReferenceFormInputComponent {
  @Input({ required: true }) formGroup!: FormGroup;
  @Input({ required: true }) controlName!: string;

  faCircleExclamation = faCircleExclamation;

  constructor(private translateService: TranslateService) {}

  get errorLabel(): string | undefined {
    const control = this.formGroup.get(this.controlName);

    if (control === null || control.untouched) {
      return undefined;
    }

    if (control.hasError("maxlength")) {
      return this.translateService.instant(
        "services.exportManifest.createPage.errors.maxlength",
        { requiredLength: 25 },
      );
    }

    return "Error";
  }

  get hasErrors(): boolean {
    const control = this.formGroup.get(this.controlName);
    return control !== null && control.invalid && control.touched;
  }
}
