import { Inject, Injectable, InjectionToken } from "@angular/core";
import { AmplitudeConfig } from "@portbase/amplitude";

export interface Configuration {
  production: boolean;
  apiUrl: string;
  loginUrl: string;
  tokenApiUrl: string;
  accountManagementApiUrl: string;
  securityApiProfileUrl: string;
  amplitude: AmplitudeConfig;
  wpcsBaseUrl: string;
  useMockApi: boolean;
}

export const APP_CONFIG = new InjectionToken<Configuration>("APP_CONFIG");

@Injectable({ providedIn: "root" })
export class AppConfigService {
  constructor(
    @Inject(APP_CONFIG) private readonly configuration: Configuration,
  ) {}

  getConfig(): Configuration {
    if (!this.configuration) {
      throw Error(
        "Configuration was not loaded yet. Call 'load()' to load the configuration.",
      );
    }
    return this.configuration;
  }
}
