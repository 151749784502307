import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Injectable, inject } from "@angular/core";
import {
  injectMutation,
  injectQuery,
  injectQueryClient,
  keepPreviousData,
  queryOptions,
} from "@ngneat/query";
import { TranslateService } from "@ngx-translate/core";
import { AppConfigService } from "../../core/services/app-config.service";
import { NotificationService } from "../../core/services/notification.service";

export enum ArrivalAtExitStatus {
  ARRIVAL_AT_EXIT_NOT_YET_REPORTED_TO_CUSTOMS = "ARRIVAL_AT_EXIT_NOT_YET_REPORTED_TO_CUSTOMS",
  ARRIVAL_AT_EXIT_REPORTED_TO_CUSTOMS = "ARRIVAL_AT_EXIT_REPORTED_TO_CUSTOMS",
  INSPECTION_OF_EXPORT_SHIPMENTS_AT_CUSTOMS = "INSPECTION_OF_EXPORT_SHIPMENTS_AT_CUSTOMS",
  EXPORT_SHIPMENT_RELEASED_AT_CUSTOMS = "EXPORT_SHIPMENT_RELEASED_AT_CUSTOMS",
  EXPORT_SHIPMENT_ACCEPTED_AT_CUSTOMS = "EXPORT_SHIPMENT_ACCEPTED_AT_CUSTOMS",
  EXPORT_SHIPMENT_REJECTED_AT_CUSTOMS = "EXPORT_SHIPMENT_REJECTED_AT_CUSTOMS",
  CUSTOMS_UNAVAILABLE = "CUSTOMS_UNAVAILABLE",
  INTERNAL_ERROR = "INTERNAL_ERROR",
}

export interface ShipmentsQueryOptions {
  page?: number;
  size?: number;
  search?: string;
  direction?: string;
  statuses?: ArrivalAtExitStatus[];
  fromCreationDate?: string;
  toCreationDate?: string;
}

export interface PaginatedShipmentsResponseDto {
  content: ShipmentDto[];
  empty: boolean;
  first: boolean;
  last: boolean;
  size: number;
  totalElements: number;
  totalPages: number;
}

export interface ShipmentDto {
  creationDate: string;
  shipmentId: string;
  registeredByOrg: string;
  assignedToOrg: string;
  documentNumber: string;
  documentType: "CO" | "EU" | "EX" | "TG1" | "TG2";
  arrivalAtExitStatus: ArrivalAtExitStatus;
  arrivalTimeAndDate: string;
  arrivalLocationId: string;
  reference: string;
  messageReferenceId: string | null;
}

export interface CreateShipmentDto {
  documentNumber: string;
  documentType: "CO" | "EU" | "EX";
  reference: string;
  arrivalLocationId: string;
  assignedToOrg?: string;
}

export interface CreateShipmentResponseDto {
  id: string;
}

export interface SendShipmentToCustomsRequestDto {
  id: string;
}

export interface UpdateShipmentDto extends CreateShipmentDto {
  id: string;
}

export interface DeleteShipmentDto {
  shipmentId: string;
}

interface MutationOptions {
  showNotificationOnSuccess: boolean;
  refetchOnSuccess: boolean;
}

const defaultMutationOptions: MutationOptions = {
  refetchOnSuccess: true,
  showNotificationOnSuccess: true,
};

@Injectable({ providedIn: "root" })
export class ShipmentService {
  #notificationService = inject(NotificationService);
  #translate = inject(TranslateService);
  #http = inject(HttpClient);
  #query = injectQuery();
  #queryClient = injectQueryClient();
  #mutation = injectMutation();

  private readonly _appConfigService = inject(AppConfigService);
  private readonly _baseUrl = `${this._appConfigService.getConfig().apiUrl}`;

  getShipmentsQueryOptions(options: ShipmentsQueryOptions, enabled: boolean) {
    const sanitizedOptions: ShipmentsQueryOptions = Object.fromEntries(
      Object.entries(options).filter(([, value]) => value !== undefined),
    );

    return queryOptions({
      queryKey: ["shipments", sanitizedOptions] as const,
      queryFn: () => {
        return this.#http.get<PaginatedShipmentsResponseDto>(
          `${this._baseUrl}/shipments`,
          {
            params: sanitizedOptions as Record<string, string>,
          },
        );
      },
      enabled: enabled,
    });
  }

  getCustomerServiceShipmentsQueryOptions(
    options: ShipmentsQueryOptions,
    enabled: boolean,
  ) {
    const sanitizedOptions: ShipmentsQueryOptions = Object.fromEntries(
      Object.entries(options).filter(([, value]) => value !== undefined),
    );

    return queryOptions({
      queryKey: ["admin", "shipments", sanitizedOptions] as const,
      queryFn: () => {
        return this.#http.get<PaginatedShipmentsResponseDto>(
          `${this._baseUrl}/admin/shipments`,
          {
            params: sanitizedOptions as Record<string, string>,
          },
        );
      },
      enabled: enabled,
    });
  }

  getShipments(page: number, size: number) {
    return this.#query({
      ...this.getShipmentsQueryOptions({ page, size }, false),
      placeholderData: keepPreviousData,
      refetchOnWindowFocus: false,
    });
  }

  getCustomerServiceShipments(page: number, size: number) {
    return this.#query({
      ...this.getCustomerServiceShipmentsQueryOptions({ page, size }, false),
      placeholderData: keepPreviousData,
      refetchOnWindowFocus: false,
    });
  }

  getShipmentByIdQueryOptions(id: string | null, enabled: boolean) {
    return queryOptions({
      queryKey: ["shipments", id] as const,
      queryFn: () => {
        return this.#http.get<ShipmentDto>(`${this._baseUrl}/shipments/${id}`);
      },
      enabled,
    });
  }

  getShipmentById(id: string | null) {
    return this.#query({
      ...this.getShipmentByIdQueryOptions(id, false),
    });
  }

  createShipment({
    refetchOnSuccess,
    showNotificationOnSuccess,
  }: MutationOptions = defaultMutationOptions) {
    return this.#mutation({
      mutationFn: ({
        arrivalLocationId,
        documentNumber,
        documentType,
        reference,
        assignedToOrg,
      }: CreateShipmentDto) =>
        this.#http.post<CreateShipmentResponseDto>(
          `${this._baseUrl}/shipments`,
          {
            documentNumber: documentNumber.toUpperCase(),
            documentType,
            reference,
            arrivalLocationId,
            assignedToOrg,
          },
        ),
      onSuccess: () => {
        if (showNotificationOnSuccess) {
          this.#notificationService.show({
            type: "success",
            closable: true,
            title: this.#translate.instant(
              "notifications.aaxSaveSuccess.title",
            ),
            message: this.#translate.instant(
              "notifications.aaxSaveSuccess.message",
            ),
          });
        }
        if (refetchOnSuccess) {
          this.#queryClient.removeQueries({
            queryKey: ["shipments"],
            type: "inactive",
          });
          this.#queryClient.refetchQueries({
            queryKey: ["shipments"],
            type: "active",
          });
        }
      },
      onError: (error: HttpErrorResponse) => {
        this.#notificationService.show({
          type: "error",
          closable: true,
          title: this.#translate.instant("notifications.aaxSaveError.title"),
          message: error.error?.detail,
        });
      },
    });
  }

  sendToCustoms({
    refetchOnSuccess,
    showNotificationOnSuccess,
  }: MutationOptions = defaultMutationOptions) {
    return this.#mutation({
      mutationFn: ({ id }: SendShipmentToCustomsRequestDto) =>
        this.#http.patch(
          `${this._baseUrl}/shipments/${id}/arrival-at-exit-status`,
          {
            arrivalAtExitStatus:
              ArrivalAtExitStatus.ARRIVAL_AT_EXIT_REPORTED_TO_CUSTOMS,
          },
        ),
      onSuccess: () => {
        if (showNotificationOnSuccess) {
          this.#notificationService.show({
            type: "success",
            closable: true,
            title: this.#translate.instant(
              "notifications.aaxSendSuccess.title",
            ),
            message: this.#translate.instant(
              "notifications.aaxSendSuccess.message",
            ),
          });
        }
        if (refetchOnSuccess) {
          this.#queryClient.removeQueries({
            queryKey: ["shipments"],
            type: "inactive",
          });
          this.#queryClient.refetchQueries({
            queryKey: ["shipments"],
            type: "active",
          });
        }
      },
      onError: (error: HttpErrorResponse) => {
        this.#notificationService.show({
          type: "error",
          closable: true,
          title: this.#translate.instant("notifications.aaxSendError.title"),
          message: error.error?.detail,
        });
      },
    });
  }

  updateShipment({
    refetchOnSuccess,
    showNotificationOnSuccess,
  }: MutationOptions = defaultMutationOptions) {
    return this.#mutation({
      mutationFn: ({
        documentNumber,
        documentType,
        reference,
        arrivalLocationId,
        id,
      }: UpdateShipmentDto) =>
        this.#http.put<UpdateShipmentDto>(`${this._baseUrl}/shipments/${id}`, {
          documentNumber: documentNumber.toUpperCase(),
          documentType,
          reference,
          arrivalLocationId,
        }),
      onSuccess: () => {
        if (showNotificationOnSuccess) {
          this.#notificationService.show({
            type: "success",
            closable: true,
            title: this.#translate.instant(
              "notifications.aaxSaveSuccess.title",
            ),
            message: this.#translate.instant(
              "notifications.aaxSaveSuccess.message",
            ),
          });
        }
        if (refetchOnSuccess) {
          this.#queryClient.removeQueries({
            queryKey: ["shipments"],
            type: "inactive",
          });
          this.#queryClient.refetchQueries({
            queryKey: ["shipments"],
            type: "active",
          });
        }
      },
      onError: (error: HttpErrorResponse) => {
        this.#notificationService.show({
          type: "error",
          closable: true,
          title: this.#translate.instant("notifications.aaxSaveError.title"),
          message: error.error?.detail,
        });
      },
    });
  }

  refetchShipments() {
    this.#queryClient.refetchQueries({
      queryKey: ["shipments"],
      type: "active",
    });
  }

  deleteShipment() {
    return this.#mutation({
      mutationFn: ({ shipmentId }: DeleteShipmentDto) =>
        this.#http.delete<void>(`${this._baseUrl}/shipments`, {
          body: { shipmentId },
        }),
      onSuccess: (_, { shipmentId }) => {
        this.#notificationService.show({
          type: "success",
          closable: true,
          title: this.#translate.instant(
            "notifications.aaxDeleteSuccess.title",
          ),
          message: "",
        });
        this.#queryClient.removeQueries({
          queryKey: ["shipments", shipmentId],
        });
        this.#queryClient.removeQueries({
          queryKey: ["shipments"],
          type: "inactive",
        });
        this.#queryClient.refetchQueries({
          queryKey: ["shipments"],
          type: "active",
        });
      },
      onError: (error: HttpErrorResponse) => {
        this.#notificationService.show({
          type: "error",
          closable: true,
          title: this.#translate.instant("notifications.aaxDeleteError.title"),
          message: error.error?.detail,
        });
      },
    });
  }
}
