<div class="text-xs text-slate-600 mt-4">
  @if (rejectionReason) {
    <h1>
      {{
        "services.exportManifest.editPage.rejection.title"
          | translate
      }}
    </h1>
    <div class="text-xs text-slate-600 mt-2">
      <ul class="list-disc ml-4">
        @for (reason of rejectionReason.reasons; track reason.code) {
          <li>
            <strong class="font-medium">{{ reason.code }}</strong> -
            {{ "rejectionReasons." + reason.code | translate }}
          </li>
        }
      </ul>
      <p class="mt-3">
        {{
          "services.exportManifest.editPage.rejection.moreDetails" | translate
        }}
        <a
          class="underline"
          target="_blank"
          [href]="
              'rejectionReasons.' +
                (rejectionReason.rejectionType === 'DEC'
                  ? 'codebookUrl'
                  : 'codebookDrnUrl') | translate
            "
        >
          {{
            "services.exportManifest.editPage.rejection.moreDetailsLink"
              | translate
          }}
        </a>
      </p>
    </div>
  }
</div>
