import { Directive, ElementRef, Input, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { AmplitudeService } from './amplitude.service';

@Directive({
  selector: '[pbAmplitudeEvent]'
})
export class AmplitudeEventDirective implements OnInit, OnDestroy {
  @Input('pbAmplitudeEvent') eventType!: string;
  @Input() eventProperties?: Record<string, unknown>;
  @Input() eventDisabled = false;

  private _unregisterListenerFn?: () => void;

  constructor(private readonly elementRef: ElementRef,
              private readonly renderer: Renderer2,
              private readonly amplitudeService: AmplitudeService) {
  }

  ngOnInit() {
    this._registerListener();
  }

  private _registerListener(): void {
    if (this.amplitudeService.isEnabled()) {
      this._unregisterListenerFn = this.renderer.listen(this.elementRef.nativeElement, 'click', () => this._trackEvent());
    }
  }

  private _trackEvent(): void {
    if (!this.eventDisabled) {
      this.amplitudeService.trackEvent(this.eventType, this.eventProperties);
    }
  }

  private _unregisterListener(): void {
    if (this._unregisterListenerFn) {
      this._unregisterListenerFn();
    }
  }

  ngOnDestroy() {
    this._unregisterListener();
  }
}
