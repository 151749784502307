import { inject } from "@angular/core";
import { toObservable } from "@angular/core/rxjs-interop";
import { CanActivateFn, Router } from "@angular/router";
import { map, skipWhile, take } from "rxjs";
import {
  AuthenticationService,
  ServiceSubscriptionDescriptionCode,
} from "../authentication/authentication.service";

export const hasAccessToOneOfServicesGuard =
  (servicesList: ServiceSubscriptionDescriptionCode[]): CanActivateFn =>
  () => {
    const services = inject(AuthenticationService).services;
    const router = inject(Router);

    return toObservable(services).pipe(
      skipWhile((services) => !services.length),
      take(1),
      map((servicesResponse) => {
        const hasAccess = servicesList.some((service) =>
          servicesResponse.includes(service),
        );

        if (!hasAccess) {
          // This can cause infinite loops if the user does not have access to any of the services
          router.navigate(["/"]);
        }

        return hasAccess;
      }),
    );
  };
