<div class="flex flex-row justify-between items-center">
  <ng-container [formGroup]="formGroup">
    <input
      matInput
      [id]="controlName"
      [formControlName]="controlName"
      oninput="this.value = this.value.trim().toUpperCase();"
      [maxLength]="27"
      [minLength]="0"
      [placeholder]="
            'services.exportManifest.createPage.fields.documentNumberPlaceholder'
              | translate
          "
      class="placeholder:font-normal placeholder:text-gray-400 placeholder:text-sm font-normal text-gray-800 text-sm disabled:cursor-not-allowed"
    />
  </ng-container>

  @if (
    formGroup.controls[controlName].invalid &&
    formGroup.controls[controlName].touched
    ) {
    @if (hasErrors(controlName)) {
      <fa-icon
        matSuffix
        size="lg"
        [icon]="faCircleExclamation"
        class="py-1.5 px-2 text-red-700 rounded-full hover:bg-red-100"
        [tp]="errorLabel"
        tpVariation="tooltipArrow"
        [tpOffset]="[0, 0]"
      ></fa-icon>
    } @else {
      <fa-icon
        matSuffix
        size="lg"
        [icon]="faCircleExclamation"
        class="py-1.5 px-2 text-yellow-600 rounded-full hover:bg-yellow-100"
        [tp]="errorLabel"
        tpVariation="tooltipArrow"
        [tpOffset]="[0, 0]"
      ></fa-icon>
    }
  }
</div>
