<div class="flex flex-col mt-4 w-full">
  <h2 class="text-lg font-medium">
    {{ "preferences.arrivalAtExit.title" | translate }}
  </h2>

  @if (form) {
    <div class="flex flex-col gap-5 w-full">
      <div class="max-w-md">
        <pbe-form-select
          [hideRequiredMarker]="true"
          [label]="
              'preferences.arrivalAtExit.fields.defaultDocumentType' | translate
            "
          [form]="form"
          controlName="defaultDocumentType"
          [placeholder]="
              'preferences.arrivalAtExit.fields.defaultDocumentTypePlaceholder'
                | translate
            "
          [options]="[
              {
                value: 'EX',
                label:
                  'services.arrivalAtExit.createPage.fields.documentTypeOptions.EX'
                  | translate
              },
              {
                value: 'CO',
                label:
                  'services.arrivalAtExit.createPage.fields.documentTypeOptions.CO'
                  | translate
              }
            ]"
        />
      </div>

      <pbe-arrival-location-aliases-form />
      <div class="flex flex-col w-full">
        <h2 class="text-base font-medium">
          {{ "preferences.notifications.title" | translate }}
        </h2>
        <p class="text-sm my-3">
          {{ "preferences.notifications.description" | translate }}
        </p>

        @if (
          personalNotificationSettings &&
          organisationNotificationSettings().data
          ) {
          @for (type of notificationTypes; track $index) {
            <pbe-email-address-list
              [notificationType]="type"
              [emailList]="
                  organisationNotificationSettings().data?.[type] ?? []
                "
              [isUserSubscribed]="personalNotificationSettings[type]"
              [canEdit]="
                  userRights().includes(UserRights.ConfigureEmailAddresses)
                "
              (toggleSubscription)="
                  updatePersonalNotificationSettings(type, $event)
                "
              (emailListChange)="
                  updateOrganisationNotificationSettings(type, $event)
                "
            />

            @if ($index < notificationTypes.length - 1) {
              <hr />
            }
          }
        }
      </div>
    </div>
  }
</div>
