import { TippyConfig } from "@ngneat/helipopper/lib/tippy.types";

type Variation = TippyConfig["variations"][0];

export const pbTooltipArrowVariation: Variation = {
  theme: "material",
  arrow: true,
  animation: "fade",
  delay: 0,
  trigger: "mouseenter",
  placement: "top",
  interactive: true,
};
