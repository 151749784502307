import { CommonModule } from "@angular/common";
import { Component, EventEmitter, Input, Output } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MatTableModule } from "@angular/material/table";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import {
  faCopy,
  faPlus,
  faTrashCan,
  faUpload,
} from "@fortawesome/pro-light-svg-icons";
import { TranslateModule } from "@ngx-translate/core";
import { ButtonIconDirective } from "@portbase/material/button";

@Component({
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    MatTableModule,
    FontAwesomeModule,
    MatButtonModule,
    ButtonIconDirective,
  ],
  selector: "pbe-export-manifest-shipments-table-controls",
  templateUrl: './export-shipments-table-controls.component.html',
  styleUrl: './export-shipments-table-controls.component.scss'
})
export class ExportManifestShipmentsTableControlsComponent {
  @Input() disableActions = false;

  @Input() shipmentsPendingDeletion = 0;

  @Output() addShipment = new EventEmitter<void>();
  @Output() openCopyShipmentsDialog = new EventEmitter<void>();
  @Output() deleteSelectedShipments = new EventEmitter<void>();

  faPlus = faPlus;
  faCopy = faCopy;
  faUpload = faUpload;
  faTrashCan = faTrashCan;
}
