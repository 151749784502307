<div class="flex flex-col gap-1">
  <div class="flex text-gray-700 font-sans font-medium text-base">
    {{
      "services.arrivalAtExit.detailsPage.historyTab.operations." +
      history.operationType
        | translate
        : { documentNumber: history.operationPayload.documentNumber }
    }}
  </div>

  @if (history.operationPayload.rejectionReason) {
    <div class="text-xs text-slate-600">
      <h1>
        {{ "services.arrivalAtExit.editPage.rejection.title" | translate }}
      </h1>
      <div class="text-xs text-slate-600 mt-2">
        <ul class="list-disc ml-4">
          @for (
              reason of history.operationPayload.rejectionReason.reasons;
            track reason.code
            ) {
            <li>
              <strong class="font-medium">{{ reason.code }}</strong> -
              {{ "rejectionReasons." + reason.code | translate }}
            </li>
          }
        </ul>
        <p class="mt-3">
          {{
            "services.arrivalAtExit.editPage.rejection.moreDetails"
              | translate
          }}
          <a
            class="underline"
            target="_blank"
            [href]="
                  'rejectionReasons.' +
                    (history.operationPayload.rejectionReason.rejectionType ===
                    'DEC'
                      ? 'codebookUrl'
                      : 'codebookDrnUrl') | translate
                "
          >
            {{
              "services.arrivalAtExit.editPage.rejection.moreDetailsLink"
                | translate
            }}
          </a>
        </p>
      </div>
    </div>
  }
</div>
