<div
  class="flex flex-col items-center w-full h-full justify-center  text-center"
>
  <fa-icon
    class="text-6xl  block animate-spin-slow text-blue-900"
    [icon]="loadingIcon"
  />
  <p class="text-sm text-gray-600 mt-4">
    {{
      "services." + service + ".overview.loading.description" | translate
    }}
  </p>
</div>
