<div class="flex flex-col gap-1">
  <p class="text-gray-700 font-sans font-medium text-base">
    {{
      "services.arrivalAtExit.detailsPage.historyTab.operations." +
      history.operationType
        | translate
        : { documentNumber: history.operationPayload.documentNumber }
    }}
  </p>

  @if (history.operationPayload.inspectionType) {
    <p class="text-xs text-slate-600">
      Inspection type: {{ history.operationPayload.inspectionType }}
    </p>
  }
</div>
