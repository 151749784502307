import {
  Component,
  DestroyRef,
  Input,
  OnChanges,
  SimpleChanges,
  inject,
} from "@angular/core";
import { FormControl, FormGroup, ReactiveFormsModule } from "@angular/forms";
import { PortbaseExportFormInputComponent } from "../../../../components";

import { CommonModule, NgFor } from "@angular/common";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { MatButtonModule } from "@angular/material/button";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatListModule } from "@angular/material/list";
import { ActivatedRoute, Router } from "@angular/router";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import {
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faMagnifyingGlass,
  faPlus,
  faTimes,
} from "@fortawesome/pro-light-svg-icons";
import { TippyDirective } from "@ngneat/helipopper";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { ButtonIconDirective } from "@portbase/material/button";
import { debounceTime } from "rxjs/operators";
import { AuthenticationService } from "../../../../core/authentication/authentication.service";
import { NotificationService } from "../../../../core/services/notification.service";
import { searchValidator } from "../../../../export-manifest/validators/search.validator";
import {
  ArrivalAtExitStatus,
  PaginatedShipmentsResponseDto,
  ShipmentService,
} from "../../../services/shipment.service";

export type OrderDirection = "asc" | "desc";
export type OrderByOption = "creationDate" | "creationDate";

@Component({
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    ReactiveFormsModule,
    TippyDirective,
    MatListModule,
    ButtonIconDirective,
    MatFormFieldModule,
    MatInputModule,
    FontAwesomeModule,
    MatButtonModule,
    PortbaseExportFormInputComponent,
    NgFor,
  ],
  selector: "pbe-operational-view-table-controls",
  templateUrl: './operational-view-table-controls.component.html',
  styleUrl: './operational-view-table-controls.component.scss'
})
export class OperationalViewTableControlsComponent implements OnChanges {
  faMagnifyingGlass = faMagnifyingGlass;
  faPlus = faPlus;
  faTimes = faTimes;
  faChevronRight = faChevronRight;
  faChevronLeft = faChevronLeft;
  faChevronDown = faChevronDown;

  @Input({ required: true }) orderBy!: OrderByOption;
  @Input({ required: true }) orderDirection!: OrderDirection;
  @Input({ required: true }) search!: string;
  @Input({ required: true }) page!: number;
  @Input({ required: true }) pageSize!: number;
  @Input() filterStatusIsCustomsUnavailable = false;

  notificationService = inject(NotificationService);
  translate = inject(TranslateService);
  shipmentService = inject(ShipmentService);
  sendToCustoms = this.shipmentService.sendToCustoms({
    refetchOnSuccess: false,
    showNotificationOnSuccess: false,
  }).mutateAsync;

  searchForm: FormGroup = new FormGroup({
    search: new FormControl("", [searchValidator]),
  });

  authData = inject(AuthenticationService).authenticated().data;

  @Input() paginationData!: PaginatedShipmentsResponseDto | undefined;
  @Input() isLoading!: boolean;

  constructor(
    public router: Router,
    public route: ActivatedRoute,
    private destroyRef: DestroyRef,
  ) {
    this.searchForm
      .get("search")
      ?.valueChanges.pipe(
        debounceTime(300),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe(() => {
        if (this.searchForm.valid) {
          this.submitSearchQuery();
        }
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes["search"]) {
      const searchValue = changes["search"].currentValue as string;

      if (!searchValue && searchValue === "") {
        return;
      }

      this.searchForm.get("search")?.setValue(searchValue);
    }
  }

  clearSearchQuery(event: Event) {
    this.searchForm.controls["search"].setValue(null);
    this.submitSearchQuery(event);
  }

  submitSearchQuery(event?: Event) {
    event?.preventDefault();

    const value = this.searchForm.value.search;
    const shouldRemoveParam = !value || value.length === 0;

    this.router.navigate([], {
      relativeTo: this.route,
      queryParamsHandling: "merge",
      queryParams: {
        search: shouldRemoveParam ? null : value,
      },
    });
  }

  submitOrderDirection(orderDirection: OrderDirection) {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParamsHandling: "merge",
      queryParams: {
        orderDirection,
      },
    });
  }

  previousPage() {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParamsHandling: "merge",
      queryParams: {
        page: Math.max(this.page - 1, 0),
      },
    });
  }

  nextPage() {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParamsHandling: "merge",
      queryParams: {
        page: this.page + 1,
      },
    });
  }

  getPaginationTranslationVariables() {
    const totalElements = this.paginationData?.totalElements ?? 0;

    return {
      total: totalElements,
      first: this.page * this.pageSize + 1,
      last: Math.min((this.page + 1) * this.pageSize, totalElements),
    };
  }

  async resendAllCustomsUnavailableToCustoms() {
    const resubmissionCalls = (this.paginationData?.content ?? [])
      .filter(
        // only shipments with status CUSTOMS_UNAVAILABLE
        (shipment) =>
          shipment.arrivalAtExitStatus ===
          ArrivalAtExitStatus.CUSTOMS_UNAVAILABLE,
      )
      .filter(
        // only shpments this organisation can edit
        (shipment) => shipment.registeredByOrg === this.authData?.credentials,
      )
      .map((shipment) => this.sendToCustoms({ id: shipment.shipmentId }));
    await Promise.all(resubmissionCalls);
    this.shipmentService.refetchShipments();
    this.notificationService.show({
      type: "success",
      closable: true,
      title: this.translate.instant(
        "notifications.aaxResendUnavailableSuccess.title",
      ),
      message: this.translate.instant(
        "notifications.aaxResendUnavailableSuccess.message",
      ),
    });
  }

  shouldShowResendAllCustomsUnavailableButton() {
    const hasRelevantShipments = (this.paginationData?.content ?? []).some(
      (shipment) =>
        shipment.arrivalAtExitStatus ===
          ArrivalAtExitStatus.CUSTOMS_UNAVAILABLE &&
        shipment.registeredByOrg === this.authData?.credentials,
    );
    return this.filterStatusIsCustomsUnavailable && hasRelevantShipments;
  }
}
