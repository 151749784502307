import { Configuration } from "../app/core/services/app-config.service";

export const environment: Configuration = {
  production: false,
  apiUrl: "https://mael-mleb.bulk.export.accept.portbase.com",
  loginUrl: "https://login.accept.portbase.com",
  tokenApiUrl: "https://supporting.accept.portbase.com",
  wpcsBaseUrl: "https://www.accept.portbase.com",
  accountManagementApiUrl: "https://acc-mgt.accept.portbase.com/acc-mgt",
  securityApiProfileUrl:
    "https://security-api.accept.portbase.com/security-api/security-profile/v1",
  useMockApi: false,
  amplitude: {
    enabled: true,
    apiKey: "53921331dfcffe7201d8478d6f716012",
  },
};
