import { inject } from "@angular/core";
import { toObservable } from "@angular/core/rxjs-interop";
import { CanActivateFn, Router } from "@angular/router";
import { map, skipWhile, take } from "rxjs";
import {
  AuthenticationService,
  UserRights,
} from "../authentication/authentication.service";

export const canAccessUserRightGuardedRoute =
  (userRight: UserRights): CanActivateFn =>
  () => {
    const userRights = inject(AuthenticationService).userRights;
    const router = inject(Router);

    return toObservable(userRights).pipe(
      skipWhile((rights) => !rights.length),
      take(1),
      map((rights) => {
        const hasRights = rights.includes(userRight);

        if (!hasRights) {
          router.navigate(["/"]);
        }

        return hasRights;
      }),
    );
  };
