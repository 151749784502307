import { CommonModule } from "@angular/common";
import { Component, DestroyRef, inject } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { FormControl, FormGroup, ReactiveFormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogClose,
} from "@angular/material/dialog";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { faShip, faTimes } from "@fortawesome/pro-light-svg-icons";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { ButtonIconDirective } from "@portbase/material/button";
import { debounceTime, distinctUntilChanged } from "rxjs";
import { PortbaseExportTabBarComponent } from "../../../../components";
import {
  ExportManifestAaxShipmentDto,
  ExportManifestService,
  MAX_MANIFEST_AAX_SHIPMENTS_RESULT_SIZE,
} from "../../../services/export-manifest.service";
import { CopyShipmentListItemComponent } from "../copy-shipment-list-item/copy-shipment-list-item.component";

export interface CopyShipmentsDialogData {
  usedDocumentNumbers: string[];
}

@Component({
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    FontAwesomeModule,
    ButtonIconDirective,
    PortbaseExportTabBarComponent,
    CopyShipmentListItemComponent,
    MatDialogActions,
    MatDialogClose,
    MatButtonModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatInputModule,
  ],
  selector: "pbe-export-manifest-copy-shipments-dialog",
  templateUrl: './copy-shipments-dialog.component.html',
  styleUrl: './copy-shipments-dialog.component.scss'
})
export class CopyShipmentsDialogComponent {
  translate = inject(TranslateService);

  data: CopyShipmentsDialogData = inject(MAT_DIALOG_DATA);

  selection: ExportManifestAaxShipmentDto[] = [];

  faTimes = faTimes;
  faShip = faShip;

  activeTabIndex = 0;

  searchForm = new FormGroup({
    search: new FormControl(""),
  });

  manifestService = inject(ExportManifestService);
  shipmentsQuery = this.manifestService.getAaxShipments();
  shipmentsResult = this.shipmentsQuery.result;

  destroyRef = inject(DestroyRef);

  constructor() {
    this.searchForm.controls.search.valueChanges
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe(() => {
        this.shipmentsQuery.updateOptions(
          this.manifestService.getAaxShipmentsQueryOptions({
            search: this.searchForm.value.search ?? "",
            size: MAX_MANIFEST_AAX_SHIPMENTS_RESULT_SIZE,
          }),
        );
      });
  }

  getAllShipmentsLabel() {
    return `${this.translate.instant("services.exportManifest.copyShipmentsDialog.tabs.allShipments")} (${this.getFilteredShipments()?.length})`;
  }

  getSelectedLabel() {
    return `${this.translate.instant("services.exportManifest.copyShipmentsDialog.tabs.selectedShipments")} (${this.selection.length})`;
  }

  isShipmentSelected(shipment: ExportManifestAaxShipmentDto) {
    return (
      this.selection.findIndex(
        (s) => s.documentNumber === shipment.documentNumber,
      ) !== -1
    );
  }

  toggleSelected(shipment: ExportManifestAaxShipmentDto) {
    if (
      this.selection.findIndex(
        (s) => s.documentNumber === shipment.documentNumber,
      ) !== -1
    ) {
      this.selection = this.selection.filter(
        (s) => s.documentNumber !== shipment.documentNumber,
      );
    } else {
      this.selection.push(shipment);
    }
  }

  getFilteredShipments() {
    return this.shipmentsResult().data?.content.filter(
      (shipment) =>
        !this.data.usedDocumentNumbers.includes(shipment.documentNumber),
    );
  }
}
