import { CommonModule } from "@angular/common";
import { Component, DestroyRef, Input, OnInit } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import {
  FormArray,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from "@angular/forms";
import { MatOptionModule } from "@angular/material/core";
import { MatListModule } from "@angular/material/list";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { faCircleQuestion } from "@fortawesome/pro-light-svg-icons";
import { TippyDirective } from "@ngneat/helipopper";
import { TranslateModule } from "@ngx-translate/core";
import { CheckboxChangeEvent, CheckboxModule } from "primeng/checkbox";
import { TableModule } from "primeng/table";
import { PortbaseExportAaxStatusIndicatorComponent } from "../../../../arrival-at-exit/components/status-indicator/status-indicator.component";
import { TextareaAutoresizeDirective } from "../../../../directives/textarea-autoresize.directive";
import { PortbaseExportEmDocumentNumberFormInputComponent } from "../../../components/document-number-form-input/document-number-form-input.component";
import { PortbaseExportEmDocumentTypeFormInputComponent } from "../../../components/document-type-form-input/document-type-form-input.component";
import { PortbaseExportEmPartshipmentFormSelectComponent } from "../../../components/partshipment-form-select/partshipment-form-select.component";
import { PortbaseExportEmReferenceFormInputComponent } from "../../../components/reference-form-input/reference-form-input.component";
import { PortbaseExportEmWeightFormInputComponent } from "../../../components/weight-form-input/weight-form-input.component";
import { ExportManifestShipmentDocumentType } from "../../../services/export-manifest.service";
import { isMRNDocumentType } from "../../../validators/document-number.validator";
import { ExportManifestShipmentFormControls } from "../export-shipments/export-shipments.component";
import { ExportManifestShipmentsTableControlsComponent } from "../export-shipments-table-controls/export-shipments-table-controls.component";
import {
  PortbaseExportEmCommodityFormInputComponent
} from "../../../components/commodity-form-input/commodity-form-input.component";
import {
  PortbaseExportEmPortOfDischargeFormInputComponent
} from "../../../components/port-of-discharge-form-input/port-of-discharge-form-input.component";

@Component({
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    PortbaseExportAaxStatusIndicatorComponent,
    ReactiveFormsModule,
    TextareaAutoresizeDirective,
    PortbaseExportEmDocumentTypeFormInputComponent,
    PortbaseExportEmDocumentNumberFormInputComponent,
    PortbaseExportEmReferenceFormInputComponent,
    PortbaseExportEmWeightFormInputComponent,
    PortbaseExportEmPartshipmentFormSelectComponent,
    PortbaseExportEmCommodityFormInputComponent,
    PortbaseExportEmPortOfDischargeFormInputComponent,
    ExportManifestShipmentsTableControlsComponent,
    TableModule,
    CheckboxModule,
    FontAwesomeModule,
    MatListModule,
    MatOptionModule,
    TippyDirective,
    FormsModule,
  ],
  selector: "pbe-export-manifest-shipments-table",
  templateUrl: './export-shipments-table.component.html',
  styleUrl: './export-shipments-table.component.scss'
})
export class ExportManifestShipmentsTableComponent implements OnInit {
  @Input({ required: true }) formGroup!: FormGroup<{
    shipments: FormArray<FormGroup<ExportManifestShipmentFormControls>>;
  }>;

  @Input()
  readonly = false;

  @Input()
  vesselIsInRotterdam = false;

  selectAll = false;

  faCircleInfo = faCircleQuestion;

  constructor(private destroyRef: DestroyRef) {}

  ngOnInit(): void {
    this.formGroup.controls.shipments.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.updateSelectAllState();
      });
  }

  ignoreDocumentNumberLengthValidation(element: FormGroup) {
    const documentTypeControl = element.get("documentType");
    return isMRNDocumentType(
      documentTypeControl?.value as ExportManifestShipmentDocumentType,
    );
  }

  hasWarnings(element: FormGroup, controlName: string) {
    const control = element.get(controlName);

    if (control === null || control.untouched) {
      return false;
    }

    const errors = Object.keys(control.errors ?? []);

    if (controlName === "documentNumber") {
      const warningKeys = [
        "invalidYear",
        "invalidCountry",
        "invalidAlphaNumeric",
        "invalidCheckDigit",
        "invalidChecksum",
        "minlength",
        "maxlength",
      ];

      return errors.some((key) => warningKeys.includes(key));
    }

    return false;
  }

  hasErrors(element: FormGroup, controlName: string) {
    const control = element.get(controlName);

    if (control === null || control.untouched) {
      return false;
    }

    const errors = Object.keys(control.errors ?? []);

    if (controlName === "documentType") {
      const errorKeys = ["required", "invalidDocumentType"];
      return errors.some((key) => errorKeys.includes(key));
    }

    if (controlName === "documentNumber") {
      const errorKeys = ["required", "duplicateDocumentNumber"];

      if (!this.ignoreDocumentNumberLengthValidation(element)) {
        errorKeys.push("minlength", "maxlength");
      }

      return errors.some((key) => errorKeys.includes(key));
    }

    if (controlName === "referenceNumber") {
      const errorKeys = ["maxlength"];
      return errors.some((key) => errorKeys.includes(key));
    }

    if (controlName === "weight") {
      const errorKeys = [
        "requiredByPartshipment",
        "requiredByVesselInRotterdam",
        "pattern",
        "maxlength"
      ];
      return errors.some((key) => errorKeys.includes(key));
    }

    if (controlName === "partShipment") {
      const errorKeys = ["required", "pattern"];
      return errors.some((key) => errorKeys.includes(key));
    }

    if (controlName === "commodity") {
      const errorKeys = ["required", "invalidAutocompleteObject"];
      return errors.some((key) => errorKeys.includes(key));
    }

    if (controlName === "portOfDischarge") {
      const errorKeys = ["required", "invalidAutocompleteObject"];
      return errors.some((key) => errorKeys.includes(key));
    }

    return false;
  }

  isDisabled(element: FormGroup, controlName: string) {
    const control = element.get(controlName);

    if (control === null) {
      return false;
    }

    return control.disabled && !control.invalid;
  }

  checkAndDisableTooltip(
    tooltip: TippyDirective,
    formGroup: FormGroup,
    controlName: string,
  ) {
    const isControlDisabled = formGroup.get(controlName)?.disabled;

    if (isControlDisabled) {
      tooltip.disable();
    } else {
      tooltip.enable();
    }
  }

  onSelectAllChange(event: CheckboxChangeEvent) {
    this.formGroup.controls.shipments.controls.forEach((control) => {
      const selectedControl = control.get("selected");
      if (selectedControl && !selectedControl.disabled) {
        selectedControl.setValue(event.checked);
      }
    });

    this.updateSelectAllState();
  }

  updateSelectAllState() {
    const allSelected = this.formGroup.controls.shipments.controls.every(
      (control) => control.get("selected")?.value === true,
    );

    const partiallySelected = this.formGroup.controls.shipments.controls.some(
      (control) => control.get("selected")?.value === false,
    );

    this.selectAll = partiallySelected ? false : allSelected;
  }
}
