<div class="text-xs text-slate-600 mt-2">
  @if (rejectionReasons().data) {
    @if (
      rejectionReasons().data!.reasons !== null &&
      rejectionReasons().data!.reasons!.length > 0
    ) {
      <div class="text-xs text-slate-600">
        <h1>
          {{ "services.arrivalAtExit.editPage.rejection.title" | translate }}
        </h1>

        <ul class="list-disc ml-4">
          @for (reason of rejectionReasons().data!.reasons; track reason.code) {
            <li>
              <strong class="font-medium">{{ reason.code }}</strong> -
              {{ "rejectionReasons." + reason.code | translate }}
            </li>
          }
        </ul>
      </div>
    }

    <p class="mt-3">
      {{ "services.arrivalAtExit.editPage.rejection.moreDetails" | translate }}
      <a
        class="underline"
        target="_blank"
        [href]="'rejectionReasons.codebookUrl' | translate"
      >
        {{
          "services.arrivalAtExit.editPage.rejection.moreDetailsLink"
            | translate
        }}
      </a>
    </p>
  }
</div>
