import {
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { DEV_USER_ROLE } from "../../constants";

@Injectable()
export class AuthenticationTokenInterceptor implements HttpInterceptor {
  intercept(
    req: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    req = req.clone({
      withCredentials: true,
      headers:
        window.location.href.includes("localhost") &&
        req.url.includes("localhost")
          ? new HttpHeaders({ "x-dev-authentication-role": DEV_USER_ROLE })
          : undefined,
    });

    return next.handle(req);
  }
}
