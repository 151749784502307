<mat-action-list>
  @if (showStatusesFilter) {
    <pbe-filter-list-item
      [label]="'services.arrivalAtExit.filters.status' | translate"
      [filter]="statusFilter"
      [formattedFilter]="mapStatusFilters(statusFilter)"
      (clearButtonClick)="filterIconButtonClick('statusFilter')"
      [tp]="statusFilterList"
      [tpData]="statusFilterOptions"
      [tpVariation]="'popper'"
      [tpPlacement]="'bottom-start'"
      [tpPopperWidth]="260"
      [tpOffset]="[0, 8]"
      (tpVisible)="statusesFilterVisible = !statusesFilterVisible"
    />
  }
  <pbe-filter-list-item
    [label]="'services.arrivalAtExit.filters.creationDateRange' | translate"
    [filter]="creationDateRange"
    [formattedFilter]="mapDateRangeFilters(creationDateRange)"
    (clearButtonClick)="filterIconButtonClick('creationDateRange')"
    (click)="showDateRangePicker()"
  />
  @if (showOrganisationsFilter) {
    <pbe-filter-list-item
      [label]="'services.arrivalAtExit.filters.organisation' | translate"
      [filter]="organisationFilter"
      [formattedFilter]="mapOrganisationFilters(organisationFilter)"
      (clearButtonClick)="filterIconButtonClick('organisationFilter')"
      [tp]="organisationFilterList"
      [tpData]="organisationFilterOptions"
      [tpVariation]="'popper'"
      [tpPlacement]="'bottom-start'"
      [tpOffset]="[0, 8]"
      (tpVisible)="organisationFilterVisible = !organisationFilterVisible"
      [isAdmin]="true"
    />
  }
  <ng-template #statusFilterList>
    <div>
      <div matSubheader>
        {{ "services.arrivalAtExit.filters.status" | translate }}
      </div>
      <mat-selection-list
        #selection
        (selectionChange)="
            statusFilterSelectionChange(selection.selectedOptions)
          "
      >
        <ng-container *ngFor="let option of statusFilterOptions">
          <mat-list-option
            [value]="option"
            [selected]="statusFilter?.includes(option.value)"
            color="primary"
            togglePosition="before"
          >
            {{ option.label }}
          </mat-list-option>
        </ng-container>
      </mat-selection-list>
    </div>
  </ng-template>
  <ng-template #organisationFilterList>
    <div class="w-fit min-w-[260px] flex flex-col">
      <div matSubheader>
        {{ "services.arrivalAtExit.filters.organisation" | translate }}
      </div>

      <div class="flex flex-grow w-full mt-[-1.25rem] px-3.5">
        <mat-form-field class="w-full" [formGroup]="searchForm">
          <input
            matInput
            [placeholder]="
                'services.arrivalAtExit.filters.organisationSearchPlaceholder'
                  | translate
              "
            id="search"
            formControlName="search"
          />
          @if (searchForm.value.search) {
            <div matSuffix>
              <button
                mat-icon-button
                type="button"
                [icon]="faTimes"
                (click)="clearSearchQuery()"
              ></button>
            </div>
          }
        </mat-form-field>
      </div>

      @if (filteredOrganisationOptions.length > 0) {
        <mat-selection-list
          #selection
          (selectionChange)="
              organisationFilterSelectionChange(selection.selectedOptions)
            "
          class="max-h-[250px] overflow-y-auto"
        >
          <ng-container *ngFor="let option of filteredOrganisationOptions">
            <mat-list-option
              [value]="option"
              [selected]="organisationFilter?.includes(option.value)"
              color="primary"
              togglePosition="before"
            >
              {{ option.label }}
            </mat-list-option>
          </ng-container>
        </mat-selection-list>
      } @else {
        <div class="flex justify-center items-center h-10">
          {{
            "services.arrivalAtExit.filters.emptyOrganisationSearch"
              | translate
          }}
        </div>
      }
    </div>
  </ng-template>
</mat-action-list>
