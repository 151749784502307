import { CommonModule } from "@angular/common";
import { Component, Input } from "@angular/core";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { TranslateModule } from "@ngx-translate/core";
import { RejectionReasonResponse } from "../../services/export-manifest.service";

@Component({
  selector: "pbe-em-rejection-reason",
  standalone: true,
  imports: [CommonModule, FontAwesomeModule, TranslateModule],
  templateUrl: './rejection-reason.component.html',
  styleUrl: './rejection-reason.component.scss'
})
export class PortbaseExportEmRejectionReasonsComponent {
  @Input({ required: true }) rejectionReason!: RejectionReasonResponse;
}
