import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "pluralTranslate",
  standalone: true,
})
export class PluralTranslatePipe implements PipeTransform {
  public transform(key: string, number: number): string {
    return `${key}.${number == 0 ? "none" : number == 1 ? "singular" : "plural"}`;
  }
}
