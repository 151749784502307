import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import {
  ConfirmationDialogComponent,
  ConfirmationDialogData,
} from "./confirmation-dialog.component";
import { DateRangePickerDialogComponent } from "./date-range-picker-dialog.component";
import { SessionExpirationDialogComponent } from "./session-expiration-dialog.component";

export const DEFAULT_NOTIFICATION_DURATION = 3000;

@Injectable({ providedIn: "root" })
export class DialogService {
  constructor(private matDialog: MatDialog) {}

  showDeleteConfirmationDialog({
    title,
    description,
    confirmationButtonLabel,
    cancelButtonLabel,
    confirmationButtonColor,
  }: ConfirmationDialogData): Promise<boolean> {
    return new Promise((resolve) => {
      const dialogRef = this.matDialog.open(ConfirmationDialogComponent, {
        data: {
          title,
          description,
          confirmationButtonLabel,
          confirmationButtonColor,
          cancelButtonLabel,
        } as ConfirmationDialogData,
      });

      dialogRef.afterClosed().subscribe((result) => resolve(result === true));
    });
  }

  showSessionExpirationDialog() {
    const dialogRef = this.matDialog.open(SessionExpirationDialogComponent);
    dialogRef.componentInstance.dialogRef = dialogRef;
  }

  showDateRangePickerDialog(
    initialValue: [Date, Date] | null,
  ): Promise<[Date, Date] | undefined | ""> {
    return new Promise((res) => {
      const dialogRef = this.matDialog.open(DateRangePickerDialogComponent, {
        data: { initialValue },
      });

      dialogRef.afterClosed().subscribe((result) => res(result));
    });
  }
}
