import { AbstractControl, ValidationErrors } from "@angular/forms";

export function searchValidator(control: AbstractControl): ValidationErrors | null {
  const value = control.value;
  if (value === null || value.trim() === '') {
    return null;
  }
  if (value.trim().length >= 3) {
    return null;
  }
  return { minLength: true };
}
