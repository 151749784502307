import { HttpClientModule } from "@angular/common/http";
import { Component, OnInit, effect, inject, isDevMode } from "@angular/core";
import { ActivatedRoute, Router, RouterModule } from "@angular/router";

import {
  faClipboardListCheck,
  faCog,
  faDoorOpen,
} from "@fortawesome/pro-light-svg-icons";
import { TranslateModule, TranslateService } from "@ngx-translate/core";

import {
  NavigationListItem,
  PBSidenavModule,
} from "@portbase/material/sidenav";
import { SessionMonitoringService } from "@portbase/session-monitoring";
import { LANGUAGE_PREFERENCE_KEY } from "./constants";
import {
  AuthenticationService,
  UserRights,
} from "./core/authentication/authentication.service";
import { DialogService } from "./core/services/dialog/dialog.service";
import { WINDOW } from "./window";

@Component({
  standalone: true,
  imports: [RouterModule, HttpClientModule, TranslateModule, PBSidenavModule],
  selector: "pbe-mael-root",
  templateUrl: './app.component.html',
  styleUrl: "./app.component.scss",
})
export class AppComponent implements OnInit {
  route = inject(ActivatedRoute);
  router = inject(Router);
  session = inject(SessionMonitoringService);
  translateService = inject(TranslateService);
  dialogService = inject(DialogService);
  window = inject(WINDOW);
  faCog = faCog;
  faClipboardListCheck = faClipboardListCheck;
  faDoorOpen = faDoorOpen;

  authenticationService = inject(AuthenticationService);
  profile = this.authenticationService.getProfile().result;
  services = this.authenticationService.services;
  hasAax = this.authenticationService.hasAccessToAax;
  hasEm = this.authenticationService.hasAccessToExportManifest;

  userRights = this.authenticationService.userRights;

  navigationOptions: NavigationListItem[] = [];

  constructor() {
    effect(() => {
      const services = this.services();
      const userRights = this.userRights();
      this.setNavigationOptions(services, userRights);
    });
  }

  ngOnInit() {
    this.translateService.onLangChange.subscribe(() => {
      const services = this.services();
      const userRights = this.userRights();
      this.setNavigationOptions(services, userRights);
    });

    if (this.window.localStorage) {
      this.translateService.use(
        this.window.localStorage.getItem(LANGUAGE_PREFERENCE_KEY) ?? "nl",
      );
    } else {
      this.translateService.use("nl");
    }

    if (!isDevMode()) {
      // Start watching the session
      this.session.refresh().subscribe(() => {
        this.session.watch();
      });

      this.session.onIdleStart.subscribe(() => {
        // Do something when the user becomes idle
        this.dialogService.showSessionExpirationDialog();
      });

      this.session.onTimeout.subscribe(() => {
        // Do something when the session times out
        this.window.location.assign(this.authenticationService.logoutUrl);
      });
    }
  }

  setNavigationOptions(services: string[], userRights: UserRights[]) {
    const navigationOptions = [];

    if (this.hasAax()) {
      navigationOptions.push({
        link: "arrival-at-exit",
        label: this.translateService.instant("navigation.arrivalAtExit"),
        icon: faDoorOpen,
      });
    }
    if (this.hasEm()) {
      navigationOptions.push({
        link: "export-manifest",
        label: this.translateService.instant("navigation.exportManifest"),
        icon: faClipboardListCheck,
      });
    }

    if (userRights.includes(UserRights.SeePreferences)) {
      navigationOptions.push({
        link: "preferences",
        label: this.translateService.instant("navigation.preferences"),
        icon: faCog,
      });
    }

    this.navigationOptions = navigationOptions;
  }
}
