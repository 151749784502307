// See https://www.getbeamer.com/docs/#installation-guide for all possible parameters
export interface BeamerParameters {
  /* Required if enabled. This code identifies your product in Beamer. The value shown in the Embed section already
  corresponds to your product, so it should not be changed */
  product_id?: string;
  /* Optional. HTML id for the DOM element to be used as a trigger to show the panel (default: pb-beamer-trigger) */
  selector?: string;
  /* Optional. Retrieve only posts that have a translation in this language (default: en) */
  language?: string;
  /* Optional. Retrieve posts with segment filters that match or include this value (example: if a post has segment
  filters equal to ‘admin’ or ‘admin;user’, and this value equals ‘admin’, the post will be retrieved; otherwise, if
  this value equals ‘manager’, the post will not be retrieved). This parameter can include several filters separated by
  semicolons (‘;’), which don’t need to follow any specific order (example: if the value for this parameter equals
  ‘admin;manager’, all posts with segment filters equal to either ‘admin’, ‘manager’ or both will be retrieved). All
  posts without segment filters will be retrieved regardless (max length: 3,000 characters) */
  filter?: string;
  /* Optional. If true, the default button will be shown, even if a selector parameter is specified (default:
  false) */
  button?: boolean;
  /* Make sure it is always possible to set any parameter if not defined above */
  [key: string]: unknown;
}

export interface BeamerConfig {
  enabled: boolean;
  parameters?: BeamerParameters;
}

export function beamerConfig(config: Partial<BeamerConfig>): BeamerConfig {
  return {
    enabled: true,
    ...config,
    parameters: {
      selector: 'pb-beamer-trigger',
      language: 'en',
      button: false,
      ...config.parameters
    }
  }
}
