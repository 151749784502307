import { CommonModule } from "@angular/common";
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatTabsModule } from "@angular/material/tabs";
import { MatToolbarModule } from "@angular/material/toolbar";
import { ActivatedRoute, Router } from "@angular/router";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { faAngleDown } from "@fortawesome/pro-light-svg-icons";
import {
  faCircle,
  faCircleDot,
  faTimes,
} from "@fortawesome/pro-regular-svg-icons";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { PBAlertModule } from "@portbase/material/alert";
import { PBAutocompleteModule } from "@portbase/material/autocomplete";
import { ButtonIconDirective } from "@portbase/material/button";
import { PortbaseExportFormInputComponent } from "../../../components";
import { PortbaseDataItemComponent } from "../../../components/data-item/data-item.component";
import { PortbaseExportTabBarComponent } from "../../../components/tab-bar/tab-bar.component";
import { DialogService } from "../../../core/services/dialog/dialog.service";
import { PortbaseExportEmRejectionReasonsComponent } from "../../components/rejection-reason/rejection-reason.component";
import { PortbaseExportEmStatusIndicatorComponent } from "../../components/status-indicator/status-indicator.component";
import {
  CreateExportManifestShipmentDto,
  ExportManifestDetailsDto,
  ExportManifestService,
  ExportManifestShipmentDto,
  ExportManifestStatus,
} from "../../services/export-manifest.service";
import { ExportManifestShipmentsComponent } from "../components/export-shipments/export-shipments.component";

@Component({
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    MatToolbarModule,
    MatButtonModule,
    PortbaseExportTabBarComponent,
    PortbaseExportEmStatusIndicatorComponent,
    PortbaseExportFormInputComponent,
    FontAwesomeModule,
    ButtonIconDirective,
    MatTabsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    PortbaseDataItemComponent,
    ExportManifestShipmentsComponent,
    PBAutocompleteModule,
    PBAlertModule,
    PortbaseExportEmRejectionReasonsComponent,
  ],
  selector: "pbe-export-manifest-edit-page",
  templateUrl: "./edit-page.component.html",
  styleUrl: "./edit-page.component.scss",
})
export class ExportManifestEditPageComponent implements OnChanges {
  @Input({ required: true }) exportManifest!: ExportManifestDetailsDto;
  @Input() inEditMode = false;

  faTimes = faTimes;
  faCircle = faCircle;
  faCircleDot = faCircleDot;
  faAngleDown = faAngleDown;

  ExportManifestStatus = ExportManifestStatus;

  updateExportManifest =
    this.exportManifestService.updateExportManifest().mutateAsync;
  updateExportManifestForSendingDirectly =
    this.exportManifestService.updateExportManifest({
      refetchOnSuccess: false,
      showNotificationOnSuccess: false,
    }).mutateAsync;

  sendToCustoms = this.exportManifestService.sendToCustoms().mutateAsync;

  updatedShipments: (CreateExportManifestShipmentDto | null)[] | null = null;

  showEmptyShipmentsError = false;

  disableSubmit = false;

  @Output() editClose = new EventEmitter();

  constructor(
    private exportManifestService: ExportManifestService,
    private router: Router,
    private route: ActivatedRoute,
    private dialogService: DialogService,
    private translateService: TranslateService,
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes["exportManifest"]) {
      const shipments = changes["exportManifest"].currentValue
        .shipments as ExportManifestShipmentDto[];

      this.updatedShipments = shipments.map(
        this.mapExportManifestShipmentDtoToCreateExportManifestShipmentDto,
      );
    }

    if (changes["inEditMode"]) {
      const shouldRedirectToDetails = !changes["inEditMode"]
        .currentValue as boolean;

      if (shouldRedirectToDetails) {
        this.redirectToManifestDetails();
      }
    }
  }

  onClose() {
    this.editClose.emit();
  }

  async save(shipments: CreateExportManifestShipmentDto[]) {
    this.updatedShipments = shipments;
    this.showEmptyShipmentsError = false;

    await this.updateExportManifest({
      id: this.exportManifest.id,
      shipments: (this.updatedShipments?.filter(
        (shipment) => shipment !== null,
      ) ?? []) as CreateExportManifestShipmentDto[],
    });
  }

  async onSendToCustoms() {
    if (this.updatedShipments === null || this.updatedShipments.length === 0) {
      this.showEmptyShipmentsError = true;
      return;
    }

    await this.updateExportManifestForSendingDirectly({
      id: this.exportManifest.id,
      shipments: (this.updatedShipments?.filter(
        (shipment) => shipment !== null,
      ) ?? []) as CreateExportManifestShipmentDto[],
    });

    await this.sendToCustoms({ id: this.exportManifest.id });
    this.editClose.emit();
  }

  async onDelete() {
    const shouldDelete = await this.dialogService.showDeleteConfirmationDialog({
      title: this.translateService.instant(
        "services.exportManifest.deleteManifestDialog.title",
      ),
      description: this.translateService.instant(
        "services.exportManifest.deleteManifestDialog.description",
      ),
      confirmationButtonLabel: this.translateService.instant(
        "services.exportManifest.deleteManifestDialog.confirmButton",
      ),
      cancelButtonLabel: this.translateService.instant(
        "services.exportManifest.deleteManifestDialog.cancelButton",
      ),
      confirmationButtonColor: "warn",
    });

    if (!shouldDelete) {
      return;
    }

    await this.exportManifestService.deleteManifest().mutateAsync({
      manifestId: this.exportManifest.id,
    });

    this.editClose.emit();
  }

  redirectToManifestDetails() {
    if (!this.exportManifest) {
      return;
    }

    this.router.navigate([], {
      relativeTo: this.route,
      queryParamsHandling: "merge",
      queryParams: {
        view: "details",
        id: this.exportManifest.id,
      },
    });
  }

  get canDelete() {
    return (
      this.exportManifest.manifestStatus ===
      ExportManifestStatus.EXPORT_MANIFEST_NOT_YET_REPORTED_TO_CUSTOMS
    );
  }

  get vesselIsInRotterdam() {
    return this.exportManifest.callReferenceNumber.startsWith("NLRTM");
  }

  private mapExportManifestShipmentDtoToCreateExportManifestShipmentDto(
    shipment: ExportManifestShipmentDto,
  ): CreateExportManifestShipmentDto {
    return {
      documentType: shipment.documentType,
      documentNumber: shipment.documentNumber,
      referenceNumber: shipment.referenceNumber,
      weight: shipment.weight,
      partShipment: shipment.partShipment,
      copiedFromMael: shipment.copiedFromMael ?? false,
      commodityId: shipment.seaHarbourStatistics?.commodityId || null,
      portOfDischargeId:
        shipment.seaHarbourStatistics?.portOfDischargeId || null,
    };
  }
}
