import { BeamerService } from './beamer.service';
import { BeamerConfig } from './beamer-config.model';

export function beamerInitializer(
  config: BeamerConfig,
  service: BeamerService) {
  return (): void => {
    if (!config || !config.enabled) {
      return;
    }

    if (!config.parameters?.product_id) {
      throw new Error(`Beamer 'product_id' is not configured. Please make sure the 'product_id' is set in the configuration.`);
    }

    service.inject();
  }
}
