<form [formGroup]="formGroup" autocomplete="off">
  <p-table
    [value]="formGroup.controls.shipments.controls"
    formArrayName="shipments"
    class="em-shipments-table-container"
    responsiveLayout="scroll"
    [tableStyle]="{ 'min-width': '1600px' }"
  >
    <ng-template pTemplate="header">
      <tr>
        <th>
          <p-checkbox
            [(ngModel)]="selectAll"
            [ngModelOptions]="{ standalone: true }"
            [binary]="true"
            (onChange)="onSelectAllChange($event)"
            [disabled]="readonly"
          >
            <ng-template pTemplate="icon">
              <svg
                width="12"
                height="9"
                viewBox="0 0 12 9"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.6916 0.654148L4.1287 7.217L1.80851 4.89681C1.69868 4.78698 1.5206 4.78698 1.41075 4.89681L1.1456 5.16195C1.03578 5.27178 1.03578 5.44986 1.1456 5.55971L3.92984 8.34395C4.03967 8.45377 4.21774 8.45377 4.3276 8.34395L11.3545 1.31708C11.4643 1.20725 11.4643 1.02917 11.3545 0.91932L11.0893 0.654148C10.9795 0.54432 10.8014 0.54432 10.6916 0.654148Z"
                  fill="white"
                  stroke="white"
                />
              </svg>
            </ng-template>
          </p-checkbox>
        </th>
        <th class="w-[260px]">
          {{
            "services.exportManifest.createPage.fields.documentType"
              | translate
          }}
          *
        </th>
        <th class="w-[260px]">
          {{
            "services.exportManifest.createPage.fields.documentNumber"
              | translate
          }}
          *
        </th>
        <th class="w-[148px]">
          {{
            "services.exportManifest.createPage.fields.reference"
              | translate
          }}
        </th>
        <th class="w-[120px] text-right pr-2">
          {{
            "services.exportManifest.createPage.fields.weight" | translate
          }}
        </th>
        <th class="w-[320px]">
          {{
            "services.exportManifest.createPage.fields.commodityCode"
              | translate
          }}

          @if (vesselIsInRotterdam) {
            <fa-icon
              matSuffix
              size="lg"
              [icon]="faCircleInfo"
              class=" text-blue-700 hover:text-blue-900"
              [tp]="
                    'services.exportManifest.createPage.fields.commodityCodeInfo'
                      | translate
                  "
              tpVariation="tooltipArrow"
              [tpOffset]="[0, 0]"
            ></fa-icon>
          }
        </th>
        <th class="w-[173px]">
          {{
            "services.exportManifest.createPage.fields.portOfDischarge"
              | translate
          }}

          @if (vesselIsInRotterdam) {
            <fa-icon
              matSuffix
              size="lg"
              [icon]="faCircleInfo"
              class=" text-blue-700 hover:text-blue-900"
              [tp]="
                    'services.exportManifest.createPage.fields.portOfDischargeInfo'
                      | translate
                  "
              tpVariation="tooltipArrow"
              [tpOffset]="[0, 0]"
            ></fa-icon>
          }
        </th>
        <th class="w-[120px]">
          {{
            "services.exportManifest.createPage.fields.partShipment"
              | translate
          }}
        </th>
        <th class="w-[160px]">Status</th>
      </tr>
    </ng-template>

    <ng-template pTemplate="body" let-shipment let-index="rowIndex">
      <tr [formGroupName]="index">
        <td
          [ngClass]="{
                'disabled-cell': readonly
              }"
        >
          <p-checkbox formControlName="selected" [binary]="true">
            <ng-template pTemplate="icon">
              <svg
                width="12"
                height="9"
                viewBox="0 0 12 9"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.6916 0.654148L4.1287 7.217L1.80851 4.89681C1.69868 4.78698 1.5206 4.78698 1.41075 4.89681L1.1456 5.16195C1.03578 5.27178 1.03578 5.44986 1.1456 5.55971L3.92984 8.34395C4.03967 8.45377 4.21774 8.45377 4.3276 8.34395L11.3545 1.31708C11.4643 1.20725 11.4643 1.02917 11.3545 0.91932L11.0893 0.654148C10.9795 0.54432 10.8014 0.54432 10.6916 0.654148Z"
                  fill="white"
                  stroke="white"
                />
              </svg>
            </ng-template>
          </p-checkbox>
        </td>

        <td
          class="border border-gray-300 focusable-cell group hover:border-gray-500 z-10 hover:z-20 py-3 pr-2 pl-4"
          [ngClass]="{
                'disabled-cell': isDisabled(shipment, 'documentType'),
                'error-cell': hasErrors(shipment, 'documentType')
              }"
        >
          <pbe-em-document-type-form-input
            [formGroup]="shipment"
            controlName="documentType"
          ></pbe-em-document-type-form-input>
        </td>

        <td
          class="group"
          [ngClass]="{
                'disabled-cell': isDisabled(shipment, 'documentNumber'),
                'error-cell': hasErrors(shipment, 'documentNumber'),
                'warning-cell': hasWarnings(shipment, 'documentNumber')
              }"
        >
          <pbe-em-document-number-form-input
            [formGroup]="shipment"
            controlName="documentNumber"
            [ignoreLengthValidation]="
                  ignoreDocumentNumberLengthValidation(shipment)
                "
          ></pbe-em-document-number-form-input>
        </td>

        <td
          class="group"
          [ngClass]="{
                'disabled-cell': isDisabled(shipment, 'referenceNumber'),
                'error-cell': hasErrors(shipment, 'referenceNumber')
              }"
        >
          <pbe-em-reference-form-input
            [formGroup]="shipment"
            controlName="referenceNumber"
            placeholder="e.g 123"
          ></pbe-em-reference-form-input>
        </td>

        <td
          class="group"
          [ngClass]="{
                'disabled-cell': isDisabled(shipment, 'weight'),
                'error-cell': hasErrors(shipment, 'weight')
              }"
        >
          <pbe-em-weight-form-input
            [formGroup]="shipment"
            controlName="weight"
          ></pbe-em-weight-form-input>
        </td>

        <td
          class="group"
          [ngClass]="{
                'disabled-cell': isDisabled(shipment, 'commodity'),
                'error-cell': hasErrors(shipment, 'commodity')
              }"
        >
          <pbe-em-commodity-form-input
            [formGroup]="shipment"
            controlName="commodity"
          ></pbe-em-commodity-form-input>
        </td>

        <td
          class="group"
          [ngClass]="{
                'disabled-cell': isDisabled(shipment, 'portOfDischarge'),
                'error-cell': hasErrors(shipment, 'portOfDischarge')
              }"
        >
          <pbe-em-port-of-discharge-form-input
            [formGroup]="shipment"
            controlName="portOfDischarge"
          ></pbe-em-port-of-discharge-form-input>
        </td>

        <td
          class="group"
          [ngClass]="{
                'disabled-cell': isDisabled(shipment, 'partShipment'),
                'error-cell': hasErrors(shipment, 'partShipment')
              }"
          [tp]="orderByOptionList"
          [tpVariation]="'popper'"
          [tpPlacement]="'bottom-start'"
          [tpPopperWidth]="135"
          [tpOffset]="[0, 0]"
          #tooltip="tippy"
          (mouseenter)="
                checkAndDisableTooltip(tooltip, shipment, 'partShipment')
              "
        >
          <pbe-em-partshipment-form-select
            [formGroup]="shipment"
            controlName="partShipment"
          >
            <ng-template #orderByOptionList>
              <mat-action-list>
                <mat-list-item
                  (click)="
                        shipment.get('partShipment').setValue(true);
                        tooltip.hide()
                      "
                >
                  {{
                    "services.exportManifest.createPage.fields.partShipmentOptions.yes"
                      | translate
                  }}
                </mat-list-item>
                <mat-list-item
                  (click)="
                        shipment.get('partShipment').setValue(false);
                        tooltip.hide()
                      "
                >
                  {{
                    "services.exportManifest.createPage.fields.partShipmentOptions.no"
                      | translate
                  }}
                </mat-list-item>
              </mat-action-list>
            </ng-template>
          </pbe-em-partshipment-form-select>
        </td>

        <td
          [ngClass]="{
                'disabled-cell': isDisabled(shipment, 'arrivalAtExitStatus')
              }"
        >
          @if (shipment.get("arrivalAtExitStatus").value) {
            <pbe-aax-status-indicator
              [status]="shipment.get('arrivalAtExitStatus').value"
            ></pbe-aax-status-indicator>
          } @else {
            -
          }
        </td>
      </tr>
    </ng-template>
  </p-table>
</form>
