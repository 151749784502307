import { HttpResponse, http } from "msw";

export const securityProfileApiHandlers = [
  http.get(
    "https://security-api.accept.portbase.com/security-api/security-profile/v1",
    () =>
      HttpResponse.json({
        iamcUserId: "mock-iamc-user-id",
      }),
  ),
];
