import {
  Component,
  computed,
  effect,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output,
} from "@angular/core";
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from "@angular/forms";

import { CommonModule } from "@angular/common";
import { MatButtonModule } from "@angular/material/button";
import { MatToolbarModule } from "@angular/material/toolbar";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { faTrash } from "@fortawesome/pro-light-svg-icons";
import { faTimes } from "@fortawesome/pro-regular-svg-icons";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { ButtonIconDirective } from "@portbase/material/button";
import { DialogService } from "../../core/services/dialog/dialog.service";
import { OrganisationService } from "../../core/services/organisation.service";
import { formatArrivalLocationAddress } from "../../core/utils/address-formatter.utils";
import { autocompleteSuggestionValidator } from "../../core/validators/autocomplete-suggestion.validator";
import { PreferencesService } from "../../preferences/services/preferences.service";
import { ArrivalLocationService } from "../services/arrival-location.service";
import { PortbaseExportRejectionReasonsComponent } from "../components/rejection-reasons/rejection-reasons.component";
import { PortbaseExportAaxStatusIndicatorComponent } from "../components/status-indicator/status-indicator.component";
import { ArrivalAtExitFormComponent } from "../forms/arrival-at-exit-form.component";
import {
  ArrivalAtExitStatus,
  ShipmentDto,
  ShipmentService,
} from "../services/shipment.service";
import { documentNumberValidator } from "../validators/document-number.validator";
import { maxOneMonthOldValidator } from "../validators/max-one-month-old.validator";

@Component({
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TranslateModule,
    ButtonIconDirective,
    PortbaseExportAaxStatusIndicatorComponent,
    PortbaseExportRejectionReasonsComponent,
    FontAwesomeModule,
    MatButtonModule,
    MatToolbarModule,
    ArrivalAtExitFormComponent,
  ],
  selector: "pbe-arrival-at-exit-edit-page",
  templateUrl: './edit-page.component.html',
  styleUrl: './edit-page.component.scss'
})
export class ArrivalAtExitEditPageComponent implements OnInit {
  @Input({ required: true }) shipment!: ShipmentDto;

  faTimes = faTimes;
  faTrash = faTrash;
  translateService = inject(TranslateService);
  dialogService = inject(DialogService);
  shipmentService = inject(ShipmentService);
  arrivalLocationsService = inject(ArrivalLocationService);
  preferencesService = inject(PreferencesService);
  arrivalLocationAliases =
    this.preferencesService.getOrganisationArrivalLocationAliases().result;
  updateShipment = this.shipmentService.updateShipment();
  sendToCustoms = this.shipmentService.sendToCustoms();
  sendToCustomsResult = this.sendToCustoms.result;
  updateShipmentForSendingDirectly = this.shipmentService.updateShipment({
    refetchOnSuccess: false,
    showNotificationOnSuccess: false,
  });
  updateShipmentForSendingDirectlyResult =
    this.updateShipmentForSendingDirectly.result;

  updateShipmentResult = this.updateShipment.result;
  deleteShipment = this.shipmentService.deleteShipment();

  cargoHandlingAgentService = inject(OrganisationService);
  assignCargoHandlingAgent =
    this.cargoHandlingAgentService.assignCargoHandlingAgentToShipment();
  assignToCargoHandlingAgentResult = this.assignCargoHandlingAgent.result;

  @Input() userCanAssignCargoHandlingAgent = false;

  ArrivalAtExitStatus = ArrivalAtExitStatus;

  @Output() editClose = new EventEmitter();
  @Output() editSuccess = new EventEmitter();

  form!: FormGroup;

  isSaving = computed(() => {
    const isCallingUpdate = this.updateShipmentResult().isPending;
    const isCallingAssignCargoHandlingAgent =
      this.assignToCargoHandlingAgentResult().isPending;

    return isCallingUpdate || isCallingAssignCargoHandlingAgent;
  });

  isSendingToCustoms = computed(() => {
    const isCallingSendToCustoms = this.sendToCustomsResult().isPending;
    const isCallingUpdateDirectly =
      this.updateShipmentForSendingDirectlyResult().isPending;
    const isCallingAssignCargoHandlingAgent =
      this.assignToCargoHandlingAgentResult().isPending;

    return (
      isCallingSendToCustoms ||
      isCallingUpdateDirectly ||
      isCallingAssignCargoHandlingAgent
    );
  });

  constructor() {
    effect(() => {
      const aliases = this.arrivalLocationAliases().data ?? {};
      const arrivalLocation =
        this.arrivalLocationsService.getArrivalLocationForId(
          this.shipment.arrivalLocationId,
        );
      if (arrivalLocation) {
        this.form.get("arrivalLocation")?.setValue({
          label: formatArrivalLocationAddress(
            arrivalLocation,
            aliases[arrivalLocation.id],
          ),
          value: arrivalLocation.id,
        });
      }
    });
  }

  ngOnInit() {
    const arrivalLocation =
      this.arrivalLocationsService.getArrivalLocationForId(
        this.shipment.arrivalLocationId,
      );

    this.form = new FormGroup({
      documentType: new FormControl(this.shipment.documentType, {
        validators: [Validators.required],
      }),
      documentNumber: new FormControl(this.shipment.documentNumber, {
        validators: [Validators.required, documentNumberValidator()],
      }),
      reference: new FormControl(this.shipment.reference, {
        validators: [Validators.maxLength(255)],
      }),
      arrivalLocation: new FormControl(
        arrivalLocation
          ? {
              label: formatArrivalLocationAddress(arrivalLocation),
              value: arrivalLocation.id,
            }
          : {
              label: "",
              value: "",
            },
        {
          validators: [Validators.required, autocompleteSuggestionValidator()],
        },
      ),
      agent: new FormControl(
        this.userCanAssignCargoHandlingAgent
          ? {
              label: this.cargoHandlingAgentService.getCargoHandlingAgentForId(
                this.shipment.assignedToOrg,
              )?.name,
              value: this.shipment.assignedToOrg,
            }
          : "",
      ),
      creationDate: new FormControl(this.shipment.creationDate, {
        validators: [Validators.required, maxOneMonthOldValidator()],
      }),
    });
  }

  async onSave() {
    if (this.form.valid) {
      const { documentNumber, reference, documentType, agent } =
        this.form.value;

      if (
        this.userCanAssignCargoHandlingAgent &&
        typeof agent === "object" &&
        this.shipment.assignedToOrg !== agent.value
      ) {
        await this.assignCargoHandlingAgent.mutateAsync({
          shipmentId: this.shipment.shipmentId,
          cargoHandlingAgent: agent.value,
        });
      }

      await this.updateShipment.mutateAsync({
        arrivalLocationId: this.form.value.arrivalLocation.value,
        documentNumber,
        reference,
        documentType,
        id: this.shipment.shipmentId,
      });

      this.editSuccess.emit();
    } else {
      this.form.markAllAsTouched();
    }
  }

  async onSendToCustoms() {
    if (this.form.valid) {
      const { documentNumber, reference, documentType, agent } =
        this.form.value;
      await this.updateShipmentForSendingDirectly.mutateAsync({
        arrivalLocationId: this.form.value.arrivalLocation.value,
        documentNumber,
        reference,
        documentType,
        id: this.shipment.shipmentId,
      });

      if (
        this.userCanAssignCargoHandlingAgent &&
        typeof agent === "object" &&
        this.shipment.assignedToOrg !== agent.value
      ) {
        this.assignCargoHandlingAgent.mutateAsync({
          shipmentId: this.shipment.shipmentId,
          cargoHandlingAgent: agent.value,
        });
      }

      await this.sendToCustoms.mutateAsync({
        id: this.shipment.shipmentId,
      });

      this.editSuccess.emit();
    } else {
      this.form.markAllAsTouched();
    }
  }

  async onDelete() {
    const shouldDelete = await this.dialogService.showDeleteConfirmationDialog({
      title: this.translateService.instant(
        "services.arrivalAtExit.editPage.deleteDialog.title",
      ),
      description: this.translateService.instant(
        "services.arrivalAtExit.editPage.deleteDialog.description",
      ),
      confirmationButtonLabel: this.translateService.instant(
        "general.dialogs.delete.confirm",
      ),
      cancelButtonLabel: this.translateService.instant(
        "general.dialogs.delete.cancel",
      ),
      confirmationButtonColor: "warn",
    });
    if (shouldDelete) {
      await this.deleteShipment.mutateAsync({
        shipmentId: this.shipment.shipmentId,
      });
      this.editSuccess.emit();
    }
  }

  onClose() {
    this.editClose.emit();
  }
}
