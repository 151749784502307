import { Observable, of, tap } from "rxjs";

export function cacheInSessionStorage<T>(
  key: string,
  apiCallObservable: Observable<T>,
): Observable<T> {
  if (sessionStorage !== null) {
    const cachedData = sessionStorage.getItem(key);
    if (cachedData) {
      return of(JSON.parse(cachedData) as T);
    }
  }
  return apiCallObservable.pipe(
    tap((data) => {
      if (sessionStorage !== null) {
        sessionStorage.setItem(key, JSON.stringify(data));
      }
    }),
  );
}
