import { CommonModule } from "@angular/common";
import { Component, Input } from "@angular/core";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import {
  faCircleCheck,
  faCircleEllipsis,
  faExclamationCircle,
  faPenCircle,
  faQuestionCircle,
  faTimesCircle,
} from "@fortawesome/pro-solid-svg-icons";
import { TranslateModule } from "@ngx-translate/core";
import { ExportManifestStatus } from "../../services/export-manifest.service";

@Component({
  selector: "pbe-em-status-indicator",
  standalone: true,
  imports: [CommonModule, FontAwesomeModule, TranslateModule],
  templateUrl: './status-indicator.component.html',
  styleUrl: './status-indicator.component.scss'
})
export class PortbaseExportEmStatusIndicatorComponent {
  @Input({ required: true }) status!: ExportManifestStatus;
  @Input() includeLongDescription = false;

  faQuestionCircle = faQuestionCircle;

  statusToColorMap: Record<ExportManifestStatus, string> = {
    [ExportManifestStatus.EXPORT_MANIFEST_NOT_YET_REPORTED_TO_CUSTOMS]:
      "text-gray-300",
    [ExportManifestStatus.EXPORT_MANIFEST_UPDATED_NOT_YET_REPORTED_TO_CUSTOMS]:
      "text-gray-300",
    [ExportManifestStatus.EXPORT_MANIFEST_REPORTED_TO_CUSTOMS]: "text-blue-500",
    [ExportManifestStatus.EXPORT_MANIFEST_ACCEPTED_AT_CUSTOMS]:
      "text-green-500",
    [ExportManifestStatus.EXPORT_MANIFEST_REJECTED_AT_CUSTOMS]: "text-red-500",
    [ExportManifestStatus.CUSTOMS_UNAVAILABLE]: "text-red-500",
    [ExportManifestStatus.INTERNAL_ERROR]: "text-red-500",
  };

  statusToIconMap: Record<ExportManifestStatus, IconProp | null> = {
    [ExportManifestStatus.EXPORT_MANIFEST_NOT_YET_REPORTED_TO_CUSTOMS]: null,
    [ExportManifestStatus.EXPORT_MANIFEST_UPDATED_NOT_YET_REPORTED_TO_CUSTOMS]:
      faPenCircle,
    [ExportManifestStatus.EXPORT_MANIFEST_REPORTED_TO_CUSTOMS]:
      faCircleEllipsis,
    [ExportManifestStatus.EXPORT_MANIFEST_ACCEPTED_AT_CUSTOMS]: faCircleCheck,
    [ExportManifestStatus.EXPORT_MANIFEST_REJECTED_AT_CUSTOMS]: faTimesCircle,
    [ExportManifestStatus.CUSTOMS_UNAVAILABLE]: faQuestionCircle,
    [ExportManifestStatus.INTERNAL_ERROR]: faExclamationCircle,
  };

  getStatusColor(status: ExportManifestStatus) {
    return this.statusToColorMap[status];
  }

  getStatusIcon(status: ExportManifestStatus) {
    return this.statusToIconMap[status];
  }
}
