<div
  class="fixed w-screen h-screen top-0 left-0 z-[1] pointer-events-none"
>
  <div
    class="bg-black transition-opacity opacity-0 w-full h-full"
    [class]="
        drawer.opened
          ? 'opacity-50 pointer-events-auto'
          : 'opacity-0 pointer-events-none'
      "
    (click)="closeRightDrawer()"
  ></div>
  <mat-drawer
    (closed)="removeQueryParams()"
    #drawer
    mode="over"
    class="w-screen max-w-[1024px] 2xl:max-w-[1600px] z-10 pointer-events-auto"
    position="end"
  >
    <div class="bg-white h-full w-full z-10">
      @if (drawer.opened) {
        <pbe-export-manifest-details-page
          (detailsClose)="closeRightDrawer()"
          [exportManifest]="exportManifest().data!"
        ></pbe-export-manifest-details-page>
      }
    </div>
  </mat-drawer>
</div>
