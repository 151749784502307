<div class="h-full flex flex-col">
  <mat-toolbar>
    <div class="flex flex-row items-center gap-4">
      <button
        mat-icon-button
        [icon]="faTimes"
        (click)="createCancel.emit()"
      ></button>
      <h1 class="text-xl text-gray-700 ml-6 items-center">
        {{ "services.arrivalAtExit.createPage.header" | translate }}
      </h1>
    </div>
  </mat-toolbar>
  <div class="bg-slate-100 flex-grow p-8 w-full flex-col">
    @if (form !== undefined) {
      <pbe-arrival-at-exit-form
        [showCargoHandlingAgentField]="userCanAssignCargoHandlingAgent"
        [form]="form"
        (cancel)="onCancel()"
        (save)="onSave()"
        (sendToCustoms)="onSendToCustoms()"
        [isSaving]="isSaving()"
        [isSendingToCustoms]="isSendingToCustoms()"
      />
    }
  </div>
</div>
