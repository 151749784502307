import {
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  inject,
} from "@angular/core";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { faRightLeft } from "@fortawesome/pro-solid-svg-icons";
import { TranslateModule } from "@ngx-translate/core";
import { OrganisationService } from "../../../../core/services/organisation.service";
import {
  ShipmentHistoryDto,
  ShipmentOperationType,
} from "../../../services/shipment-history.service";

@Component({
  selector: "pbe-history-item-cargo-handling-agent-changed",
  standalone: true,
  imports: [TranslateModule, FontAwesomeModule],
  templateUrl: './history-item-cargo-handling-agent-changed.component.html',
  styleUrl: './history-item-cargo-handling-agent-changed.component.scss'
})
export class HistoryItemCargoHandlingAgentChangedComponent
  implements OnChanges
{
  @Input({ required: true })
  history!: ShipmentHistoryDto<ShipmentOperationType.AAX_SHIPMENT_CARGO_HANDLING_AGENT_CHANGED>;

  faArrowRight = faRightLeft;

  organisationService = inject(OrganisationService);

  oldCargoHandlingAgentQuery =
    this.organisationService.getOrganisationProfileForId(null);
  oldCargoHandlingAgent = this.oldCargoHandlingAgentQuery.result;

  newCargoHandlingAgentQuery =
    this.organisationService.getOrganisationProfileForId(null);
  newCargoHandlingAgent = this.newCargoHandlingAgentQuery.result;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes["history"].currentValue) {
      const history = changes["history"]
        .currentValue as ShipmentHistoryDto<ShipmentOperationType.AAX_SHIPMENT_CARGO_HANDLING_AGENT_CHANGED>;

      if (history.operationPayload.change.oldValue) {
        this.oldCargoHandlingAgentQuery.updateOptions(
          this.organisationService.organisationProfileQueryOptions(
            history.operationPayload.change.oldValue,
          ),
        );
      }

      if (history.operationPayload.change.newValue) {
        this.newCargoHandlingAgentQuery.updateOptions(
          this.organisationService.organisationProfileQueryOptions(
            history.operationPayload.change.newValue,
          ),
        );
      }
    }
  }
}
