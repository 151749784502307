<mat-form-field
  [formGroup]="form"
  class="w-full"
  [hideRequiredMarker]="hideRequiredMarker"
>
  <mat-label
  >{{ label
    }}{{
      required ? " " : ("general.forms.optionalLabel" | translate)
    }}</mat-label
  >
  <mat-select
    [formControlName]="controlName"
    [value]="form.controls[controlName].value"
    [placeholder]="placeholder ?? ''"
  >
    @if (!required) {
      <mat-option [value]="null">{{ placeholder }}</mat-option>
    }

    @for (option of options; track option.value) {
      <mat-option [value]="option.value">{{ option.label }}</mat-option>
    }
  </mat-select>
</mat-form-field>
