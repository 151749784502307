import { __awaiter, __generator, __values } from "tslib";
import { CLIENT_NOT_INITIALIZED, OPT_OUT_MESSAGE } from './messages';
import { Timeline } from './timeline';
import { createGroupEvent, createGroupIdentifyEvent, createIdentifyEvent, createRevenueEvent, createTrackEvent } from './utils/event-builder';
import { buildResult } from './utils/result-builder';
import { returnWrapper } from './utils/return-wrapper';
var AmplitudeCore = /** @class */function () {
  function AmplitudeCore(name) {
    if (name === void 0) {
      name = '$default';
    }
    this.initializing = false;
    this.q = [];
    this.dispatchQ = [];
    this.logEvent = this.track.bind(this);
    this.timeline = new Timeline(this);
    this.name = name;
  }
  AmplitudeCore.prototype._init = function (config) {
    return __awaiter(this, void 0, void 0, function () {
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            this.config = config;
            this.timeline.reset(this);
            return [4 /*yield*/, this.runQueuedFunctions('q')];
          case 1:
            _a.sent();
            return [2 /*return*/];
        }
      });
    });
  };
  AmplitudeCore.prototype.runQueuedFunctions = function (queueName) {
    return __awaiter(this, void 0, void 0, function () {
      var queuedFunctions, queuedFunctions_1, queuedFunctions_1_1, queuedFunction, e_1_1;
      var e_1, _a;
      return __generator(this, function (_b) {
        switch (_b.label) {
          case 0:
            queuedFunctions = this[queueName];
            this[queueName] = [];
            _b.label = 1;
          case 1:
            _b.trys.push([1, 6, 7, 8]);
            queuedFunctions_1 = __values(queuedFunctions), queuedFunctions_1_1 = queuedFunctions_1.next();
            _b.label = 2;
          case 2:
            if (!!queuedFunctions_1_1.done) return [3 /*break*/, 5];
            queuedFunction = queuedFunctions_1_1.value;
            return [4 /*yield*/, queuedFunction()];
          case 3:
            _b.sent();
            _b.label = 4;
          case 4:
            queuedFunctions_1_1 = queuedFunctions_1.next();
            return [3 /*break*/, 2];
          case 5:
            return [3 /*break*/, 8];
          case 6:
            e_1_1 = _b.sent();
            e_1 = {
              error: e_1_1
            };
            return [3 /*break*/, 8];
          case 7:
            try {
              if (queuedFunctions_1_1 && !queuedFunctions_1_1.done && (_a = queuedFunctions_1.return)) _a.call(queuedFunctions_1);
            } finally {
              if (e_1) throw e_1.error;
            }
            return [7 /*endfinally*/];
          case 8:
            return [2 /*return*/];
        }
      });
    });
  };
  AmplitudeCore.prototype.track = function (eventInput, eventProperties, eventOptions) {
    var event = createTrackEvent(eventInput, eventProperties, eventOptions);
    return returnWrapper(this.dispatch(event));
  };
  AmplitudeCore.prototype.identify = function (identify, eventOptions) {
    var event = createIdentifyEvent(identify, eventOptions);
    return returnWrapper(this.dispatch(event));
  };
  AmplitudeCore.prototype.groupIdentify = function (groupType, groupName, identify, eventOptions) {
    var event = createGroupIdentifyEvent(groupType, groupName, identify, eventOptions);
    return returnWrapper(this.dispatch(event));
  };
  AmplitudeCore.prototype.setGroup = function (groupType, groupName, eventOptions) {
    var event = createGroupEvent(groupType, groupName, eventOptions);
    return returnWrapper(this.dispatch(event));
  };
  AmplitudeCore.prototype.revenue = function (revenue, eventOptions) {
    var event = createRevenueEvent(revenue, eventOptions);
    return returnWrapper(this.dispatch(event));
  };
  AmplitudeCore.prototype.add = function (plugin) {
    if (!this.config) {
      this.q.push(this.add.bind(this, plugin));
      return returnWrapper();
    }
    return returnWrapper(this.timeline.register(plugin, this.config));
  };
  AmplitudeCore.prototype.remove = function (pluginName) {
    if (!this.config) {
      this.q.push(this.remove.bind(this, pluginName));
      return returnWrapper();
    }
    return returnWrapper(this.timeline.deregister(pluginName));
  };
  AmplitudeCore.prototype.dispatchWithCallback = function (event, callback) {
    if (!this.config) {
      return callback(buildResult(event, 0, CLIENT_NOT_INITIALIZED));
    }
    void this.process(event).then(callback);
  };
  AmplitudeCore.prototype.dispatch = function (event) {
    return __awaiter(this, void 0, void 0, function () {
      var _this = this;
      return __generator(this, function (_a) {
        if (!this.config) {
          return [2 /*return*/, new Promise(function (resolve) {
            _this.dispatchQ.push(_this.dispatchWithCallback.bind(_this, event, resolve));
          })];
        }
        return [2 /*return*/, this.process(event)];
      });
    });
  };
  AmplitudeCore.prototype.process = function (event) {
    return __awaiter(this, void 0, void 0, function () {
      var result, e_2, message, result;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            _a.trys.push([0, 2,, 3]);
            // skip event processing if opt out
            if (this.config.optOut) {
              return [2 /*return*/, buildResult(event, 0, OPT_OUT_MESSAGE)];
            }
            return [4 /*yield*/, this.timeline.push(event)];
          case 1:
            result = _a.sent();
            result.code === 200 ? this.config.loggerProvider.log(result.message) : result.code === 100 ? this.config.loggerProvider.warn(result.message) : this.config.loggerProvider.error(result.message);
            return [2 /*return*/, result];
          case 2:
            e_2 = _a.sent();
            message = String(e_2);
            this.config.loggerProvider.error(message);
            result = buildResult(event, 0, message);
            return [2 /*return*/, result];
          case 3:
            return [2 /*return*/];
        }
      });
    });
  };
  AmplitudeCore.prototype.setOptOut = function (optOut) {
    if (!this.config) {
      this.q.push(this.setOptOut.bind(this, Boolean(optOut)));
      return;
    }
    this.config.optOut = Boolean(optOut);
  };
  AmplitudeCore.prototype.flush = function () {
    return returnWrapper(this.timeline.flush());
  };
  return AmplitudeCore;
}();
export { AmplitudeCore };
