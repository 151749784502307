import { NgFor } from "@angular/common";
import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  standalone: true,
  imports: [NgFor],
  selector: "pbe-tab-bar",
  templateUrl: "./tab-bar.component.html",
  styleUrl: './tab-bar.component.scss'
})
export class PortbaseExportTabBarComponent {
  @Input({ required: true }) activeTabIndex!: number;
  @Input({ required: true }) tabLabels!: string[];
  @Output() changeTabIndex = new EventEmitter<number>();
}
