import { CommonModule } from "@angular/common";
import { Component, Input } from "@angular/core";
import { FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { PortbaseExportSelectComponent } from "../select/select.component";

@Component({
  selector: "pbe-form-select",
  standalone: true,
  imports: [
    CommonModule,
    FontAwesomeModule,
    ReactiveFormsModule,
    PortbaseExportSelectComponent,
  ],
  templateUrl: './form-select.component.html',
  styleUrl: "./form-select.component.scss"
})
export class PortbaseExportFormSelectComponent {
  @Input({ required: true }) label!: string;
  @Input() placeholder?: string;

  @Input({ required: true }) form!: FormGroup;
  @Input({ required: true }) controlName!: string;
  @Input() options: { label: string; value: string }[] = [];

  @Input() hideRequiredMarker = false;

  required = () =>
    this.form.get(this.controlName)?.hasValidator(Validators.required);
}
