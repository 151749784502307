const documentTypeMap = new Map<string, string>([
  ["CO", "CO"],
  ["EU", "EU"],
  ["EX", "EX"],
  ["LEGACY_TG1", "TG1"],
  ["LEGACY_TG2", "TG2"],
  ["LEGACY_651", "651"],
  ["LEGACY_705", "705"],
  ["LEGACY_760", "760"],
  ["LEGACY_T2F", "T2F"],
  ["LEGACY_720", "720"],
  ["LEGACY_750", "750"],
  ["LEGACY_955", "955"],
  ["LEGACY_AAD", "AAD"],
  ["LEGACY_PPE", "PPE"],
  ["LEGACY_823", "823"],
  ["LEGACY_355", "355"],
  ["LEGACY_821", "821"],
  ["LEGACY_952", "952"],
  ["LEGACY_KWU", "KWU"],
  ["LEGACY_820", "820"],
  ["LEGACY_822", "822"],
  ["LEGACY_ICT", "ICT"],
  ["LEGACY_REN", "REN"],
  ["LEGACY_EXS", "EXS"],
  ["LEGACY_ZZZ", "ZZZ"],
]);

export function resolveDocumentType(documentType: string): string {
  return documentTypeMap.get(documentType) || documentType;
}
