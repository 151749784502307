import { bootstrapApplication } from "@angular/platform-browser";
import { AppComponent } from "./app/app.component";
import { appConfig } from "./app/app.config";
import { environment } from "./environments/environment";
import { http, worker } from "./mock-api/browser";

if (environment.useMockApi) {
  (window as any).msw = { worker, http };

  worker
    .start({
      onUnhandledRequest: "bypass",
    })
    .then(() => {
      bootstrapApplication(AppComponent, appConfig).catch((err) =>
        console.error(err),
      );
    });
} else {
  bootstrapApplication(AppComponent, appConfig).catch((err) =>
    console.error(err),
  );
}
