<table mat-table [dataSource]="shipments">
  <ng-container matColumnDef="organisationName">
    <th mat-header-cell *matHeaderCellDef>
      {{
        "services.arrivalAtExit.overview.table.headers.organisationName"
          | translate
      }}
    </th>
    <td mat-cell *matCellDef="let element">
      {{ mapOrganisationName(element.registeredByOrg) }}
    </td>
  </ng-container>

  <ng-container matColumnDef="documentNumber">
    <th mat-header-cell *matHeaderCellDef>
      {{
        "services.arrivalAtExit.overview.table.headers.documentNumber"
          | translate
      }}
    </th>
    <td
      mat-cell
      *matCellDef="let element"
      [innerHTML]="element.documentNUmber | highlight:search"
    >
    </td>
  </ng-container>

  <ng-container matColumnDef="documentType">
    <th mat-header-cell *matHeaderCellDef>
      {{
        "services.arrivalAtExit.overview.table.headers.documentType"
          | translate
      }}
    </th>
    <td mat-cell *matCellDef="let element">
      {{ mapDocumentType(element.documentType) }}
    </td>
  </ng-container>
  <ng-container matColumnDef="creationDate">
    <th mat-header-cell *matHeaderCellDef>
      {{
        "services.arrivalAtExit.overview.table.headers.creationDate"
          | translate
      }}
    </th>
    <td mat-cell *matCellDef="let element">
      {{ element.creationDate | date: "dd-MM-yyyy" }}
    </td>
  </ng-container>
  <ng-container matColumnDef="reference">
    <th mat-header-cell *matHeaderCellDef>
      {{
        "services.arrivalAtExit.overview.table.headers.reference"
          | translate
      }}
    </th>
    <td
      class="w-64 max-w-64 text-ellipsis overflow-hidden whitespace-nowrap"
      mat-cell
      *matCellDef="let element"
      [innerHTML]="element.reference | highlight:search"
    >
    </td>
  </ng-container>
  <ng-container matColumnDef="arrivalAtExitStatus">
    <th mat-header-cell *matHeaderCellDef>
      {{
        "services.arrivalAtExit.overview.table.headers.arrivalAtExitStatus"
          | translate
      }}
    </th>
    <td
      class="w-60 max-w-60 text-ellipsis overflow-hidden whitespace-nowrap"
      mat-cell
      *matCellDef="let element"
    >
      <pbe-aax-status-indicator
        [status]="element.arrivalAtExitStatus"
      ></pbe-aax-status-indicator>
    </td>
  </ng-container>
  <ng-container matColumnDef="actions">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell *matCellDef="let element">
      <button
        class="flex flex-row pl-3 float-right text-sm font-medium text-pb-blue-700 hover:underline hover:text-pb-blue-900 active:text-pb-blue-950"
        (click)="openDetailsPage(element)"
      >
            <span>{{
                "services.arrivalAtExit.overview.table.actions.details"
                  | translate
              }}</span>
        <fa-icon class="ml-4" [icon]="faChevronRight"></fa-icon>
      </button>
    </td>
  </ng-container>
  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
</table>
