import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Injectable, computed, effect, inject } from "@angular/core";
import { injectQuery } from "@ngneat/query";
import { Observable } from "rxjs";
import { WINDOW } from "../../window";
import { AppConfigService } from "../services/app-config.service";

interface Authority {
  authority: string;
}

export interface Authentication {
  authenticated: boolean;
  authorities: Authority[];
  credentials: string;
  name: string;
  principal: string;
}

export interface Profile {
  loginName: string;
  emailAddress: string;
  firstName: string;
  lastName: string;
  language: string;
  organisationShortname: string;
  organisationFullname: string;
  organisationTypes: string[];
}

export interface SecurityProfile {
  logonName: string;
  iamcUserId: string;
  organisationShortName: string;
  iamcOrgId: string;
  roles: string[];
}

export enum ServiceSubscriptionDescriptionCode {
  ExportManifestForCargoHandlingAgent = "eri.npcs.external.cargodeclarationexportmleb.cargohandlingagent.portal",
  ArrivalAtExitForCargoHandlingAgent = "eri.npcs.external.arrivalnotificationexport.cargohandlingagent.portal",
  ExportManifestForAdmin = "eri.npcs.external.cargodeclarationexportmleb.admin.portal",
  ArrivalAtExitForTerminal = "eri.npcs.external.arrivalnotificationexport.terminaloperator.portal",
  ArrivalAtExitForAdmin = "eri.npcs.external.arrivalnotificationexport.admin.portal",
}

export interface ServiceSubscription {
  url: string; // URL for accessing the service.
  packageDescription: string; // Seems like the specific role type? Values can be "Agent" and others
  descriptionCode: ServiceSubscriptionDescriptionCode | string; // Unique identifier
  description: string; // Name as shown in PCS
}

export enum UserRole {
  CargoHandlingAgent = "ROLE_AneCargoHandlingAgent",
  TerminalOperator = "ROLE_AneTerminalOperator",
  PcsAdmin = "ROLE_AneAdmin", // Customer service
  UserAdministrator = "ROLE_IAMC_ORGANIZATION_ROLE_USERMANAGER", // Customer admin
  MlebPcsAdmin = "ROLE_MlebAdmin",
  MlebCargoHandlingAgent = "ROLE_MlebCargoHandlingAgent",
}

export enum UserRights {
  AssignCargoHandlingAgent = "ASSIGN_CARGO_HANDLING_AGENT",
  SeeCustomerServiceView = "SEE_CUSTOMER_SERVICE_VIEW",
  ConfigureEmailAddresses = "CONFIGURE_EMAIL_ADDRESSES",
  SeePreferences = "SEE_PREFERENCES",
}

const SUPPORTED_ROLES: UserRole[] = [
  UserRole.CargoHandlingAgent,
  UserRole.TerminalOperator,
  UserRole.PcsAdmin,
  UserRole.UserAdministrator,
  UserRole.MlebPcsAdmin,
  UserRole.MlebCargoHandlingAgent,
];

@Injectable({
  providedIn: "root",
})
export class AuthenticationService {
  #query = injectQuery();
  #httpClient = inject(HttpClient);
  #appConfigService = inject(AppConfigService);
  #window = inject(WINDOW);

  private readonly baseUrl = this.#appConfigService.getConfig().apiUrl;
  private readonly loginUrl = this.#appConfigService.getConfig().loginUrl;
  private readonly tokenApiUrl = this.#appConfigService.getConfig().tokenApiUrl;
  private readonly accountManagementApiUrl =
    this.#appConfigService.getConfig().accountManagementApiUrl;
  private readonly securityProfileIdUrl =
    this.#appConfigService.getConfig().securityApiProfileUrl;
  private authenticatedUrl = this.baseUrl + "/authenticated"; // URL to web api
  public logoutUrl = this.tokenApiUrl + "/token/logout";

  public authenticated = this.getAuthentication().result;
  public servicesResponse = this.getServices().result;

  public userRoles = computed(
    () =>
      this.authenticated()
        .data?.authorities.map((a) => a.authority as UserRole)
        .filter((a) => SUPPORTED_ROLES.includes(a)) ?? [],
  );

  public userRights = computed(() => {
    const roles = this.userRoles();

    const rights: UserRights[] = [];

    if (
      roles.includes(UserRole.CargoHandlingAgent) ||
      roles.includes(UserRole.MlebCargoHandlingAgent)
    ) {
      rights.push(UserRights.SeePreferences);
    }

    if (roles.includes(UserRole.TerminalOperator)) {
      rights.push(UserRights.AssignCargoHandlingAgent);
      rights.push(UserRights.SeePreferences);
    }

    if (
      roles.includes(UserRole.PcsAdmin) ||
      roles.includes(UserRole.MlebPcsAdmin)
    ) {
      rights.push(UserRights.SeeCustomerServiceView);
    }

    if (roles.includes(UserRole.UserAdministrator)) {
      rights.push(UserRights.SeePreferences);
      rights.push(UserRights.ConfigureEmailAddresses);
    }

    return Array.from(new Set(rights));
  });

  public services = computed(
    () => this.servicesResponse().data?.map((s) => s.descriptionCode) ?? [],
  );

  hasAccessToAax = computed(
    () =>
      this.services().includes(
        ServiceSubscriptionDescriptionCode.ArrivalAtExitForCargoHandlingAgent,
      ) ||
      this.services().includes(
        ServiceSubscriptionDescriptionCode.ArrivalAtExitForTerminal,
      ) ||
      this.services().includes(
        ServiceSubscriptionDescriptionCode.ArrivalAtExitForAdmin,
      ),
  );

  hasAccessToExportManifest = computed(
    () =>
      this.services().includes(
        ServiceSubscriptionDescriptionCode.ExportManifestForCargoHandlingAgent,
      ) ||
      this.services().includes(
        ServiceSubscriptionDescriptionCode.ExportManifestForAdmin,
      ),
  );

  constructor() {
    effect(() => {
      const { error } = this.authenticated();
      if (error && (error as HttpErrorResponse).status === 401) {
        this.#window.location.assign(
          `${this.loginUrl}?redirect=${window.location.href}`,
        );
      }
    });
  }

  isAuthenticated(): Observable<Authentication> {
    return this.#httpClient.get<Authentication>(this.authenticatedUrl);
  }

  getAuthentication() {
    return this.#query({
      queryFn: () => this.isAuthenticated(),
      queryKey: ["authentication"],
      refetchOnWindowFocus: true,
      refetchIntervalInBackground: true,
      refetchInterval: 1000 * 60 * 5, // every 5 minutes
      retry: false,
    });
  }

  getProfile() {
    return this.#query({
      queryFn: () =>
        this.#httpClient.get<Profile>(
          this.accountManagementApiUrl + "/users/profile",
        ),
      queryKey: ["profile"],
      refetchOnWindowFocus: false,
    });
  }

  getSecurityProfile() {
    return this.#query({
      queryFn: () =>
        this.#httpClient.get<SecurityProfile>(this.securityProfileIdUrl),
      queryKey: ["security-profile"],
      refetchOnWindowFocus: false,
    });
  }

  getServices() {
    return this.#query({
      queryFn: () =>
        this.#httpClient.get<ServiceSubscription[]>(
          this.accountManagementApiUrl + "/users/services",
        ),
      queryKey: ["services"],
      refetchOnWindowFocus: false,
    });
  }
}
