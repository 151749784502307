import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({
  standalone: true,
  name: "highlight",
})
export class HighlightPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  transform(value: string, search: string | null): SafeHtml {
    if (!value || !search) {
      return value;
    }

    const regex = new RegExp(`(${this.escapeRegExp(search)})`, "gi");
    const highlighted = value.replace(
      regex,
      `<span style="background-color: #a2caff">$1</span>`,
    );
    return this.sanitizer.bypassSecurityTrustHtml(highlighted);
  }

  private escapeRegExp(string: string): string {
    return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
  }
}
