import { DatePipe } from "@angular/common";
import {
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  inject,
} from "@angular/core";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { faPipe } from "@fortawesome/pro-light-svg-icons";
import { faCircle, faCircleDot } from "@fortawesome/pro-regular-svg-icons";
import { OrganisationService } from "../../../core/services/organisation.service";
import { ShipmentHistoryDto } from "../../services/shipment-history.service";

@Component({
  selector: "pbe-shipment-history-wrapper",
  standalone: true,
  imports: [DatePipe, FontAwesomeModule],
  templateUrl: './shipment-history-wrapper.component.html',
  styleUrl: './shipment-history-wrapper.component.scss'
})
export class ShipmentHistoryWrapperComponent implements OnChanges {
  @Input({ required: true }) history!: ShipmentHistoryDto;
  @Input({ required: true }) isMostRecent!: boolean;

  faCircleDot = faCircleDot;
  faCircle = faCircle;
  faPipe = faPipe;

  organisationService = inject(OrganisationService);
  organisationQuery =
    this.organisationService.getOrganisationProfileForId(null);
  organisation = this.organisationQuery.result;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes["history"].currentValue) {
      const history = changes["history"].currentValue as ShipmentHistoryDto;

      this.organisationQuery.updateOptions(
        this.organisationService.organisationProfileQueryOptions(
          history.organisationShortName,
        ),
      );
    }
  }
}
