import { LowerCasePipe } from "@angular/common";
import { Component, Input } from "@angular/core";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { TranslateModule } from "@ngx-translate/core";
import {
  ShipmentHistoryDto,
  ShipmentOperationType,
} from "../../../services/shipment-history.service";

@Component({
  selector: "pbe-history-item-released",
  standalone: true,
  imports: [LowerCasePipe, TranslateModule, FontAwesomeModule],
  templateUrl: './history-item-released.component.html',
  styleUrl: "./history-item-released.component.scss"
})
export class HistoryItemReleasedComponent {
  @Input({ required: true })
  history!: ShipmentHistoryDto<ShipmentOperationType.AAX_EXPORT_SHIPMENT_RELEASED_AT_CUSTOMS>;
}
