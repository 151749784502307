import {
  Component,
  EventEmitter,
  Input,
  Output,
  effect,
  inject,
} from "@angular/core";
import { FormGroup, ReactiveFormsModule } from "@angular/forms";
import {
  PortbaseExportDocumentNumberFormInputComponent,
  PortbaseExportFormInputComponent,
  PortbaseExportFormSelectComponent,
} from "../../components";

import { CommonModule } from "@angular/common";
import { MatButtonModule } from "@angular/material/button";
import { MatInputModule } from "@angular/material/input";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { faCircleNotch } from "@fortawesome/pro-regular-svg-icons";
import { TranslateModule } from "@ngx-translate/core";
import { PBAlertModule } from "@portbase/material/alert";
import {
  AutocompleteSuggestion,
  PBAutocompleteModule,
} from "@portbase/material/autocomplete";
import { PbInput } from "@portbase/portbase-theme";
import Fuse from "fuse.js";
import { OrganisationService } from "../../core/services/organisation.service";
import { formatArrivalLocationAddress } from "../../core/utils/address-formatter.utils";
import { PreferencesService } from "../../preferences/services/preferences.service";
import { ArrivalLocationService } from "../services/arrival-location.service";

const fuseOptions = {
  threshold: 0.4,
  shouldSort: true,
  minMatchCharLength: 3,
  keys: ["label"],
};

@Component({
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TranslateModule,
    PBAutocompleteModule,
    PortbaseExportFormInputComponent,
    PortbaseExportFormSelectComponent,
    PortbaseExportDocumentNumberFormInputComponent,
    FontAwesomeModule,
    MatButtonModule,
    PbInput,
    MatInputModule,
    PBAlertModule,
  ],
  selector: "pbe-arrival-at-exit-form",
  templateUrl: './arrival-at-exit-form.component.html',
  styleUrl: './arrival-at-exit-form.component.scss'
})
export class ArrivalAtExitFormComponent {
  locations = inject(ArrivalLocationService).getArrivalLocations().result;
  cargoHandlingAgents =
    inject(OrganisationService).getCargoHandlingAgents().result;

  preferencesService = inject(PreferencesService);

  arrivalLocationAliases =
    this.preferencesService.getOrganisationArrivalLocationAliases().result;

  loadingIcon = faCircleNotch;

  @Input() showCargoHandlingAgentField = false;
  @Input() isSaving = false;
  @Input() isSendingToCustoms = false;

  @Output() cancel = new EventEmitter();
  @Output() save = new EventEmitter();
  @Output() sendToCustoms = new EventEmitter();

  @Input({ required: true }) form!: FormGroup;

  suggestionsForArrivalLocations: AutocompleteSuggestion[] = [];
  suggestionsForCargoHandlingAgents: AutocompleteSuggestion[] = [];
  quickSelectionSuggestionsForArrivalLocations: AutocompleteSuggestion[] = [];

  searchableListForArrivalLocations?: Fuse<{ value: string; label: string }>;
  searchableListForCargoHandlingAgents?: Fuse<{ value: string; label: string }>;

  constructor() {
    effect(() => {
      const aliases = this.arrivalLocationAliases().data ?? {};

      const mappedValues =
        this.locations().data?.map((location) => ({
          label: formatArrivalLocationAddress(location, aliases[location.id]),
          value: location.id,
        })) ?? [];
      // Sort locations by presence of alias (check on ID)
      mappedValues.sort((a, b) => {
        const aHasAlias = aliases[a.value] !== undefined;
        const bHasAlias = aliases[b.value] !== undefined;

        if (aHasAlias && !bHasAlias) {
          return -1;
        }

        if (!aHasAlias && bHasAlias) {
          return 1;
        }

        return 0;
      });

      this.suggestionsForArrivalLocations = mappedValues;

      this.searchableListForArrivalLocations = new Fuse(
        this.suggestionsForArrivalLocations,
        fuseOptions,
      );

      this.quickSelectionSuggestionsForArrivalLocations = Object.entries(
        aliases,
      ).map(([id, alias]) => ({
        label: alias,
        value: id,
      }));
    });

    effect(() => {
      if (this.showCargoHandlingAgentField) {
        const mappedValues =
          this.cargoHandlingAgents().data?.map((agent) => ({
            label: agent.name,
            value: agent.shortName,
          })) ?? [];
        this.suggestionsForCargoHandlingAgents = mappedValues;

        this.searchableListForCargoHandlingAgents = new Fuse(
          this.suggestionsForCargoHandlingAgents,
          fuseOptions,
        );
      }
    });
  }

  getFilteredSuggestionsForArrivalLocations(
    query: string | undefined,
  ): { value: string; label: string }[] {
    return query && query.length > 2
      ? this.searchableListForArrivalLocations
          ?.search(query)
          .map((r) => r.item) ?? []
      : this.suggestionsForArrivalLocations;
  }

  getFilteredSuggestionsForCargoHandlingAgents(
    query: string | undefined,
  ): { value: string; label: string }[] {
    return query && query.length > 2
      ? this.searchableListForCargoHandlingAgents
          ?.search(query)
          .map((r) => r.item) ?? []
      : this.suggestionsForCargoHandlingAgents;
  }
}
