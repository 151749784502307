<div
  class="w-full pl-8 py-2 border-b border-b-black/20 flex flex-row justify-between action-bar @container"
>
  <form [formGroup]="searchForm" class="flex-grow">
    <div class="relative -mt-12 inline-block h-4 mr-2 max-w-96 w-1/2">
      <div class="flex flex-col mt-6 w-full">
        <mat-form-field>
          <input
            matInput
            [placeholder]="
                  'services.arrivalAtExit.overview.searchPlaceholder'
                    | translate
                "
            id="search"
            formControlName="search"
          />
          @if (searchForm.value.search) {
            <div matSuffix>
              <button
                mat-icon-button
                type="button"
                (click)="clearSearchQuery($event)"
                [icon]="faTimes"
              ></button>
            </div>
          }
          <mat-error *ngIf="searchForm.get('search')?.invalid">
            {{ "services.arrivalAtExit.errors.search" | translate }}
          </mat-error>
        </mat-form-field>
      </div>
    </div>
  </form>
  @if (shouldShowResendAllCustomsUnavailableButton()) {
    <button
      mat-stroked-button
      color="primary"
      (click)="resendAllCustomsUnavailableToCustoms()"
    >
          <span>{{
              "services.arrivalAtExit.overview.resubmitCustomsUnavailableButton"
                | translate
            }}</span>
    </button>
  }
  <div class="mr-3 flex flex-row gap-2 items-center">
    <button
      mat-button
      iconPos="right"
      [icon]="faChevronDown"
      [tp]="orderByOptionList"
      class="text-nowrap"
      [tpVariation]="'popper'"
      [tpPlacement]="'bottom-start'"
      [tpPopperWidth]="260"
      [tpOffset]="[0, 8]"
      #tooltip="tippy"
    >
          <span class="">{{
              "services.arrivalAtExit.overview.orderBy." +
              orderBy +
              "." +
              orderDirection | translate
            }}</span>
    </button>
    <ng-template #orderByOptionList>
      <mat-action-list>
        <mat-list-item
          (click)="submitOrderDirection('desc'); tooltip.hide()"
        >
          {{
            "services.arrivalAtExit.overview.orderBy.creationDate.desc"
              | translate
          }}
        </mat-list-item>
        <mat-list-item
          (click)="submitOrderDirection('asc'); tooltip.hide()"
        >
          {{
            "services.arrivalAtExit.overview.orderBy.creationDate.asc"
              | translate
          }}
        </mat-list-item>
      </mat-action-list>
    </ng-template>
    <span class="text-sm min-w-20 text-right">{{
        "services.arrivalAtExit.overview.pageAndCount"
          | translate: getPaginationTranslationVariables()
      }}</span>
    <div class="flex flex-row items-center">
      <button
        mat-icon-button
        [icon]="faChevronLeft"
        [disabled]="isLoading || paginationData?.first"
        (click)="previousPage()"
      ></button>
      <button
        mat-icon-button
        [icon]="faChevronRight"
        [disabled]="isLoading || paginationData?.last"
        (click)="nextPage()"
      ></button>
    </div>

    <button
      mat-raised-button
      (click)="
            router.navigate([], {
              relativeTo: this.route,
              queryParamsHandling: 'merge',
              queryParams: { view: 'create' }
            })
          "
      [icon]="faPlus"
    >
          <span class="hidden @4xl:block">{{
              "services.arrivalAtExit.overview.createButton" | translate
            }}</span>
      <span class="block @4xl:hidden">{{
          "services.arrivalAtExit.overview.createButtonShort" | translate
        }}</span>
    </button>
  </div>
</div>
