import { CommonModule } from "@angular/common";
import { Component, Input } from "@angular/core";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import {
  faCircleCheck,
  faCircleEllipsis,
  faCircleExclamation,
  faExclamationCircle,
  faQuestionCircle,
  faTimesCircle,
} from "@fortawesome/pro-solid-svg-icons";
import { TranslateModule } from "@ngx-translate/core";
import { ArrivalAtExitStatus } from "../../services/shipment.service";

@Component({
  selector: "pbe-aax-status-indicator",
  standalone: true,
  imports: [CommonModule, FontAwesomeModule, TranslateModule],
  templateUrl: './status-indicator.component.html',
  styleUrl: './status-indicator.component.scss',
})
export class PortbaseExportAaxStatusIndicatorComponent {
  @Input({ required: true }) status!: ArrivalAtExitStatus;

  statusToColorMap: Record<ArrivalAtExitStatus, string> = {
    [ArrivalAtExitStatus.ARRIVAL_AT_EXIT_NOT_YET_REPORTED_TO_CUSTOMS]:
      "text-gray-300",
    [ArrivalAtExitStatus.ARRIVAL_AT_EXIT_REPORTED_TO_CUSTOMS]: "text-blue-500",
    [ArrivalAtExitStatus.INSPECTION_OF_EXPORT_SHIPMENTS_AT_CUSTOMS]:
      "text-yellow-500",
    [ArrivalAtExitStatus.EXPORT_SHIPMENT_RELEASED_AT_CUSTOMS]: "text-green-500",
    [ArrivalAtExitStatus.EXPORT_SHIPMENT_ACCEPTED_AT_CUSTOMS]: "text-green-500",
    [ArrivalAtExitStatus.EXPORT_SHIPMENT_REJECTED_AT_CUSTOMS]: "text-red-500",
    [ArrivalAtExitStatus.CUSTOMS_UNAVAILABLE]: "text-red-500",
    [ArrivalAtExitStatus.INTERNAL_ERROR]: "text-red-500",
  };

  statusToIconMap: Record<ArrivalAtExitStatus, IconProp | null> = {
    [ArrivalAtExitStatus.ARRIVAL_AT_EXIT_NOT_YET_REPORTED_TO_CUSTOMS]: null,
    [ArrivalAtExitStatus.ARRIVAL_AT_EXIT_REPORTED_TO_CUSTOMS]: faCircleEllipsis,
    [ArrivalAtExitStatus.INSPECTION_OF_EXPORT_SHIPMENTS_AT_CUSTOMS]:
      faCircleExclamation,
    [ArrivalAtExitStatus.EXPORT_SHIPMENT_RELEASED_AT_CUSTOMS]: faCircleCheck,
    [ArrivalAtExitStatus.EXPORT_SHIPMENT_ACCEPTED_AT_CUSTOMS]: faCircleCheck,
    [ArrivalAtExitStatus.EXPORT_SHIPMENT_REJECTED_AT_CUSTOMS]: faTimesCircle,
    [ArrivalAtExitStatus.CUSTOMS_UNAVAILABLE]: faQuestionCircle,
    [ArrivalAtExitStatus.INTERNAL_ERROR]: faExclamationCircle,
  };

  getStatusColor(status: ArrivalAtExitStatus) {
    return this.statusToColorMap[status];
  }

  getStatusIcon(status: ArrivalAtExitStatus) {
    return this.statusToIconMap[status];
  }
}
