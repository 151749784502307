<div class="flex flex-col mt-3 w-full">
  <mat-form-field [formGroup]="form">
    <mat-label>
      {{
        "services.arrivalAtExit.createPage.fields.documentNumber"
          | translate
      }}
    </mat-label>
    <input
      matInput
      [id]="controlName"
      [formControlName]="controlName"
      oninput="this.value = this.value.trim().toUpperCase();"
    />
  </mat-form-field>
  <div class="h-4">
    @if (form.controls[controlName].invalid &&
    form.controls[controlName].touched) {
      <mat-error class="text-xs">
        {{
          "general.forms.errors.documentNumber." +
          getFirstError(form.controls[controlName].errors) | translate
        }}
      </mat-error>
    }
  </div>
</div>
