import { Component, effect } from "@angular/core";
import {
  AuthenticationService,
  UserRights,
} from "../core/authentication/authentication.service";
import { OrganisationService } from "../core/services/organisation.service";

@Component({
  selector: "pbe-export-manifest-data-prefetcher",
  standalone: true,
  template: `<ng-container></ng-container>`,
})
export class ExportManifestDataPrefetcherComponent {

  userRoles = this.authenticationService.userRoles;
  userRights = this.authenticationService.userRights;

  // Always preload organisations, as the information is basically always required for table views & filters.
  organisationsQuery =
    this.organisationService.getOrganisationsForExportManifestCustomerService();
  organisations = this.organisationsQuery.result;

  constructor(private authenticationService: AuthenticationService, private organisationService: OrganisationService) {
    effect(() => {
      if (this.userRights().includes(UserRights.SeeCustomerServiceView)) {
        this.organisationsQuery.updateOptions(
          this.organisationService.getOrganisationsForExportManifestCustomerServiceQueryOptions(
            true,
          ),
        );
      }
    });
  }
}
