import { HttpClient } from "@angular/common/http";
import { Injectable, inject } from "@angular/core";
import { injectQuery } from "@ngneat/query";
import { AppConfigService } from "../../core/services/app-config.service";
import { cacheInSessionStorage } from "../../core/utils/session-storage-cache.util";

export interface ArrivalLocationDto {
  id: string;
  warehouseName: string;
  zipCode: string;
  houseNumber: string;
}

@Injectable({ providedIn: "root" })
export class ArrivalLocationService {
  #http = inject(HttpClient);
  #query = injectQuery();
  #arrivalLocations = this.getArrivalLocations().result;

  private readonly _appConfigService = inject(AppConfigService);

  getArrivalLocations() {
    return this.#query({
      queryKey: ["arrival-locations"] as const,
      queryFn: () =>
        cacheInSessionStorage(
          "arrival-locations",
          this.#http.get<ArrivalLocationDto[]>(
            `${this._appConfigService.getConfig().apiUrl}/shipments/arrival-locations`,
          ),
        ),
      enabled: true,
    });
  }

  getArrivalLocationForId(
    arrivalLocationId: string,
  ): ArrivalLocationDto | null {
    return (
      this.#arrivalLocations().data?.find(
        (location) => location.id === arrivalLocationId,
      ) ?? null
    );
  }
}
