import { NgFor } from "@angular/common";
import { Component, Inject, inject } from "@angular/core";
import { FormControl, FormGroup, ReactiveFormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogClose,
} from "@angular/material/dialog";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import {
  DatePickerTimeRangeOption,
  PBDatePickerModule,
} from "@portbase/material/date-picker";
import {
  endOfDay,
  endOfWeek,
  startOfDay,
  startOfWeek,
  subDays,
} from "date-fns";

interface DateRangePickerDialogData {
  initialValue: [Date, Date] | null;
}

@Component({
  selector: "pbe-date-range-picker-dialog",
  standalone: true,
  imports: [
    MatDialogActions,
    MatDialogClose,
    MatButtonModule,
    ReactiveFormsModule,
    PBDatePickerModule,
    NgFor,
    TranslateModule,
  ],
  template: `<div
      class="flex flex-row bg-white border-b border-b-slate-300 w-4xl"
      [formGroup]="form"
    >
      <div class="p-8 grow">
        <h3 class="text-lg font-medium">
          {{
            "services.arrivalAtExit.overview.dateRangeDialog.dateRange.title"
              | translate
          }}
        </h3>
        <p class="mb-4 mt-0 text-sm">
          {{
            "services.arrivalAtExit.overview.dateRangeDialog.dateRange.description"
              | translate
          }}
        </p>
        <pb-datepicker
          [form]="form"
          controlName="date"
          [numberOfMonths]="2"
          selectionMode="range"
        ></pb-datepicker>
      </div>

      <div
        class="p-8 grow-0 w-72 box-border border-l border-l-slate-300 time-range bg-slate-100"
      >
        <h3 class="text-lg font-medium">
          {{
            "services.arrivalAtExit.overview.dateRangeDialog.timeRange.title"
              | translate
          }}
        </h3>
        <p class="mb-4 mt-0 text-sm">
          {{
            "services.arrivalAtExit.overview.dateRangeDialog.timeRange.description"
              | translate
          }}
        </p>
        <div class="flex flex-col items-stretch gap-2">
          <button
            *ngFor="let timeRangeOption of timeRanges"
            mat-stroked-button
            (click)="setDatesToTimeRange(timeRangeOption.dates)"
          >
            {{ timeRangeOption.label }}
          </button>
        </div>
      </div>
    </div>

    <mat-dialog-actions [align]="'end'" class="p-0">
      <button mat-stroked-button mat-dialog-close>
        {{
          "services.arrivalAtExit.overview.dateRangeDialog.buttons.cancel"
            | translate
        }}
      </button>
      <button
        mat-raised-button
        [mat-dialog-close]="form.value.date"
        color="primary"
      >
        {{
          "services.arrivalAtExit.overview.dateRangeDialog.buttons.confirm"
            | translate
        }}
      </button>
    </mat-dialog-actions>`,
})
export class DateRangePickerDialogComponent {
  translate = inject(TranslateService);
  optionsKey =
    "services.arrivalAtExit.overview.dateRangeDialog.timeRange.options";
  form = new FormGroup({
    date: new FormControl<[Date, Date]>(
      this.data.initialValue ?? [new Date(), new Date()]
    ),
  });

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DateRangePickerDialogData
  ) {}

  timeRanges: DatePickerTimeRangeOption[] = [
    {
      label: this.translate.instant(`${this.optionsKey}.today`),
      dates: [startOfDay(new Date()), endOfDay(new Date())],
    },
    {
      label: this.translate.instant(`${this.optionsKey}.yesterday`),
      dates: [
        startOfDay(subDays(new Date(), 1)),
        endOfDay(subDays(new Date(), 1)),
      ],
    },
    {
      label: this.translate.instant(`${this.optionsKey}.thisWeek`),
      dates: [
        startOfWeek(new Date(), { weekStartsOn: 1 }),
        endOfWeek(new Date(), { weekStartsOn: 1 }),
      ],
    },
    {
      label: this.translate.instant(`${this.optionsKey}.pastSevenDays`),
      dates: [startOfDay(subDays(new Date(), 7)), endOfDay(new Date())],
    },
    {
      label: this.translate.instant(`${this.optionsKey}.pastThirtyDays`),
      dates: [startOfDay(subDays(new Date(), 30)), endOfDay(new Date())],
    },
  ];

  setDatesToTimeRange(dates: [Date, Date]) {
    this.form.setValue({ date: dates });
  }
}
