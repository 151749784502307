import { __assign, __awaiter, __extends, __generator } from "tslib";
import { AmplitudeCore, Destination, Identify, returnWrapper, Revenue, UUID } from '@amplitude/analytics-core';
import { getAnalyticsConnector, getAttributionTrackingConfig, getPageViewTrackingConfig, IdentityEventSender, isAttributionTrackingEnabled, isSessionTrackingEnabled, isFileDownloadTrackingEnabled, isFormInteractionTrackingEnabled, setConnectorDeviceId, setConnectorUserId, isNewSession, isPageViewTrackingEnabled } from '@amplitude/analytics-client-common';
import { OfflineDisabled } from '@amplitude/analytics-types';
import { convertProxyObjectToRealObject, isInstanceProxy } from './utils/snippet-helper';
import { Context } from './plugins/context';
import { useBrowserConfig, createTransport } from './config';
import { webAttributionPlugin } from '@amplitude/plugin-web-attribution-browser';
import { pageViewTrackingPlugin } from '@amplitude/plugin-page-view-tracking-browser';
import { formInteractionTracking } from './plugins/form-interaction-tracking';
import { fileDownloadTracking } from './plugins/file-download-tracking';
import { DEFAULT_SESSION_END_EVENT, DEFAULT_SESSION_START_EVENT } from './constants';
import { detNotify } from './det-notification';
import { networkConnectivityCheckerPlugin } from './plugins/network-connectivity-checker';
var AmplitudeBrowser = /** @class */function (_super) {
  __extends(AmplitudeBrowser, _super);
  function AmplitudeBrowser() {
    return _super !== null && _super.apply(this, arguments) || this;
  }
  AmplitudeBrowser.prototype.init = function (apiKey, userIdOrOptions, maybeOptions) {
    if (apiKey === void 0) {
      apiKey = '';
    }
    var userId;
    var options;
    if (arguments.length > 2) {
      userId = userIdOrOptions;
      options = maybeOptions;
    } else {
      if (typeof userIdOrOptions === 'string') {
        userId = userIdOrOptions;
        options = undefined;
      } else {
        userId = userIdOrOptions === null || userIdOrOptions === void 0 ? void 0 : userIdOrOptions.userId;
        options = userIdOrOptions;
      }
    }
    return returnWrapper(this._init(__assign(__assign({}, options), {
      userId: userId,
      apiKey: apiKey
    })));
  };
  AmplitudeBrowser.prototype._init = function (options) {
    var _a, _b;
    return __awaiter(this, void 0, void 0, function () {
      var browserOptions, connector, attributionTrackingOptions, webAttribution;
      var _this = this;
      return __generator(this, function (_c) {
        switch (_c.label) {
          case 0:
            // Step 1: Block concurrent initialization
            if (this.initializing) {
              return [2 /*return*/];
            }
            this.initializing = true;
            return [4 /*yield*/, useBrowserConfig(options.apiKey, options, this)];
          case 1:
            browserOptions = _c.sent();
            this.config = browserOptions;
            // Step 3: Set session ID
            // Priority 1: `options.sessionId`
            // Priority 2: last known sessionId from user identity storage
            // Default: `Date.now()`
            // Session ID is handled differently than device ID and user ID due to session events
            this.setSessionId((_b = (_a = options.sessionId) !== null && _a !== void 0 ? _a : this.config.sessionId) !== null && _b !== void 0 ? _b : Date.now());
            return [4 /*yield*/, _super.prototype._init.call(this, this.config)];
          case 2:
            _c.sent();
            connector = getAnalyticsConnector(options.instanceName);
            connector.identityStore.setIdentity({
              userId: this.config.userId,
              deviceId: this.config.deviceId
            });
            if (!(this.config.offline !== OfflineDisabled)) return [3 /*break*/, 4];
            return [4 /*yield*/, this.add(networkConnectivityCheckerPlugin()).promise];
          case 3:
            _c.sent();
            _c.label = 4;
          case 4:
            return [4 /*yield*/, this.add(new Destination()).promise];
          case 5:
            _c.sent();
            return [4 /*yield*/, this.add(new Context()).promise];
          case 6:
            _c.sent();
            return [4 /*yield*/, this.add(new IdentityEventSender()).promise];
          case 7:
            _c.sent();
            // Notify if DET is enabled
            detNotify(this.config);
            if (!isFileDownloadTrackingEnabled(this.config.defaultTracking)) return [3 /*break*/, 9];
            return [4 /*yield*/, this.add(fileDownloadTracking()).promise];
          case 8:
            _c.sent();
            _c.label = 9;
          case 9:
            if (!isFormInteractionTrackingEnabled(this.config.defaultTracking)) return [3 /*break*/, 11];
            return [4 /*yield*/, this.add(formInteractionTracking()).promise];
          case 10:
            _c.sent();
            _c.label = 11;
          case 11:
            if (!isAttributionTrackingEnabled(this.config.defaultTracking)) return [3 /*break*/, 13];
            attributionTrackingOptions = getAttributionTrackingConfig(this.config);
            webAttribution = webAttributionPlugin(attributionTrackingOptions);
            return [4 /*yield*/, this.add(webAttribution).promise];
          case 12:
            _c.sent();
            _c.label = 13;
          case 13:
            if (!isPageViewTrackingEnabled(this.config.defaultTracking)) return [3 /*break*/, 15];
            return [4 /*yield*/, this.add(pageViewTrackingPlugin(getPageViewTrackingConfig(this.config))).promise];
          case 14:
            _c.sent();
            _c.label = 15;
          case 15:
            this.initializing = false;
            // Step 6: Run queued dispatch functions
            return [4 /*yield*/, this.runQueuedFunctions('dispatchQ')];
          case 16:
            // Step 6: Run queued dispatch functions
            _c.sent();
            // Step 7: Add the event receiver after running remaining queued functions.
            connector.eventBridge.setEventReceiver(function (event) {
              void _this.track(event.eventType, event.eventProperties);
            });
            return [2 /*return*/];
        }
      });
    });
  };
  AmplitudeBrowser.prototype.getUserId = function () {
    var _a;
    return (_a = this.config) === null || _a === void 0 ? void 0 : _a.userId;
  };
  AmplitudeBrowser.prototype.setUserId = function (userId) {
    if (!this.config) {
      this.q.push(this.setUserId.bind(this, userId));
      return;
    }
    if (userId !== this.config.userId || userId === undefined) {
      this.config.userId = userId;
      setConnectorUserId(userId, this.config.instanceName);
    }
  };
  AmplitudeBrowser.prototype.getDeviceId = function () {
    var _a;
    return (_a = this.config) === null || _a === void 0 ? void 0 : _a.deviceId;
  };
  AmplitudeBrowser.prototype.setDeviceId = function (deviceId) {
    if (!this.config) {
      this.q.push(this.setDeviceId.bind(this, deviceId));
      return;
    }
    this.config.deviceId = deviceId;
    setConnectorDeviceId(deviceId, this.config.instanceName);
  };
  AmplitudeBrowser.prototype.reset = function () {
    this.setDeviceId(UUID());
    this.setUserId(undefined);
  };
  AmplitudeBrowser.prototype.getSessionId = function () {
    var _a;
    return (_a = this.config) === null || _a === void 0 ? void 0 : _a.sessionId;
  };
  AmplitudeBrowser.prototype.setSessionId = function (sessionId) {
    var _a;
    if (!this.config) {
      this.q.push(this.setSessionId.bind(this, sessionId));
      return;
    }
    // Prevents starting a new session with the same session ID
    if (sessionId === this.config.sessionId) {
      return;
    }
    var previousSessionId = this.getSessionId();
    var lastEventTime = this.config.lastEventTime;
    var lastEventId = (_a = this.config.lastEventId) !== null && _a !== void 0 ? _a : -1;
    this.config.sessionId = sessionId;
    this.config.lastEventTime = undefined;
    this.config.pageCounter = 0;
    if (isSessionTrackingEnabled(this.config.defaultTracking)) {
      if (previousSessionId && lastEventTime) {
        this.track(DEFAULT_SESSION_END_EVENT, undefined, {
          device_id: this.previousSessionDeviceId,
          event_id: ++lastEventId,
          session_id: previousSessionId,
          time: lastEventTime + 1,
          user_id: this.previousSessionUserId
        });
      }
      this.config.lastEventTime = this.config.sessionId;
      this.track(DEFAULT_SESSION_START_EVENT, undefined, {
        event_id: ++lastEventId,
        session_id: this.config.sessionId,
        time: this.config.lastEventTime
      });
    }
    this.previousSessionDeviceId = this.config.deviceId;
    this.previousSessionUserId = this.config.userId;
  };
  AmplitudeBrowser.prototype.extendSession = function () {
    if (!this.config) {
      this.q.push(this.extendSession.bind(this));
      return;
    }
    this.config.lastEventTime = Date.now();
  };
  AmplitudeBrowser.prototype.setTransport = function (transport) {
    if (!this.config) {
      this.q.push(this.setTransport.bind(this, transport));
      return;
    }
    this.config.transportProvider = createTransport(transport);
  };
  AmplitudeBrowser.prototype.identify = function (identify, eventOptions) {
    if (isInstanceProxy(identify)) {
      var queue = identify._q;
      identify._q = [];
      identify = convertProxyObjectToRealObject(new Identify(), queue);
    }
    if (eventOptions === null || eventOptions === void 0 ? void 0 : eventOptions.user_id) {
      this.setUserId(eventOptions.user_id);
    }
    if (eventOptions === null || eventOptions === void 0 ? void 0 : eventOptions.device_id) {
      this.setDeviceId(eventOptions.device_id);
    }
    return _super.prototype.identify.call(this, identify, eventOptions);
  };
  AmplitudeBrowser.prototype.groupIdentify = function (groupType, groupName, identify, eventOptions) {
    if (isInstanceProxy(identify)) {
      var queue = identify._q;
      identify._q = [];
      identify = convertProxyObjectToRealObject(new Identify(), queue);
    }
    return _super.prototype.groupIdentify.call(this, groupType, groupName, identify, eventOptions);
  };
  AmplitudeBrowser.prototype.revenue = function (revenue, eventOptions) {
    if (isInstanceProxy(revenue)) {
      var queue = revenue._q;
      revenue._q = [];
      revenue = convertProxyObjectToRealObject(new Revenue(), queue);
    }
    return _super.prototype.revenue.call(this, revenue, eventOptions);
  };
  AmplitudeBrowser.prototype.process = function (event) {
    return __awaiter(this, void 0, void 0, function () {
      var currentTime, isEventInNewSession;
      return __generator(this, function (_a) {
        currentTime = Date.now();
        isEventInNewSession = isNewSession(this.config.sessionTimeout, this.config.lastEventTime);
        if (event.event_type !== DEFAULT_SESSION_START_EVENT && event.event_type !== DEFAULT_SESSION_END_EVENT && (!event.session_id || event.session_id === this.getSessionId()) && isEventInNewSession) {
          this.setSessionId(currentTime);
        }
        return [2 /*return*/, _super.prototype.process.call(this, event)];
      });
    });
  };
  return AmplitudeBrowser;
}(AmplitudeCore);
export { AmplitudeBrowser };
