<div class="flex flex-row items-center h-full min-w-32">
  @if (getStatusIcon(status) !== null) {
    <fa-icon
      [class]="getStatusColor(status)"
      [icon]="getStatusIcon(status) ?? faQuestionCircle"
      size="lg"
    ></fa-icon>
  } @else {
    <div class="w-5 h-5 min-w-5 rounded-full text-center bg-gray-300"></div>
  }
  <span class="ml-4 text-sm text-slate-800"
  >{{ "general.manifestStatus.short." + status | translate }}
    @if (includeLongDescription) {
      <span
      >-
          <span
            [innerHTML]="'general.manifestStatus.long.' + status | translate"
          ></span
          ></span>
    }
    </span>
</div>
