<div class="flex flex-row justify-between items-center">
  <ng-container [formGroup]="formGroup">
    <p class="font-sans text-sm font-normal text-gray-800 w-5">
      {{
        formGroup.get("partShipment")?.value
          ? ("services.exportManifest.createPage.fields.partShipmentOptions.yes"
            | translate)
          : ("services.exportManifest.createPage.fields.partShipmentOptions.no"
            | translate)
      }}
    </p>

    <fa-icon
      matSuffix
      size="lg"
      [icon]="faChevronDown"
      class="py-1.5 px-2 rounded-full"
      [ngClass]="{
            'text-blue-300': isDisabled,
            'text-blue-700 group-hover:bg-blue-100': !isDisabled
          }"
    ></fa-icon>

    <ng-content></ng-content>
  </ng-container>
</div>
