<div class="flex flex-col w-full h-full">
  <pbe-topbar>
    <div class="flex flex-row justify-between items-center w-full">
      <h1 class="text-xl text-gray-700 ml-6">
        {{ "services.arrivalAtExit.title" | translate }}
      </h1>
      @if (!isProduction) {
        <p class="text-xs mr-6">{{ userRoles().join(", ") }}</p>
      }
    </div>
  </pbe-topbar>

  @if (view() === ArrivalAtExitView.CustomerService) {
    <pbe-arrival-at-exit-customer-service-view></pbe-arrival-at-exit-customer-service-view>
  } @else {
    <pbe-arrival-at-exit-operational-view></pbe-arrival-at-exit-operational-view>
  }
</div>

@if (view() === ArrivalAtExitView.CustomerService) {
  <pbe-arrival-at-exit-customer-service-right-drawer></pbe-arrival-at-exit-customer-service-right-drawer>
} @else {
  <pbe-arrival-at-exit-operational-right-drawer></pbe-arrival-at-exit-operational-right-drawer>
}

<pbe-data-prefetcher />
