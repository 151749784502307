import {
  Component,
  DestroyRef,
  QueryList,
  ViewChildren,
  effect,
} from "@angular/core";

import { CommonModule } from "@angular/common";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatInputModule } from "@angular/material/input";
import { MatListModule } from "@angular/material/list";
import { faPlus } from "@fortawesome/pro-light-svg-icons";
import { faTimes } from "@fortawesome/pro-regular-svg-icons";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import {
  PBAutocompleteComponent,
  PBAutocompleteModule,
} from "@portbase/material/autocomplete";
import { ButtonIconDirective } from "@portbase/material/button";
import { ArrivalLocationService } from "../../../arrival-at-exit/services/arrival-location.service";
import {
  PortbaseExportFormInputComponent,
  PortbaseExportFormSelectComponent,
} from "../../../components";
import { DOCUMENT_TYPE_PREFERENCE_KEY } from "../../../constants";
import {
  AuthenticationService,
  UserRights,
} from "../../../core/authentication/authentication.service";
import { NotificationService } from "../../../core/services/notification.service";
import {
  ArrivalNotificationTypes,
  NotificationType,
  PersonalNotificationSettings,
  PreferencesService
} from "../../services/preferences.service";
import { EmailAddressListComponent } from "../email-address-list/email-address-list.component";
import { OrganisationArrivalLocationAliasesFormComponent } from "../organisation-arrival-location-aliases-form/organisation-arrival-location-aliases-form.component";

@Component({
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TranslateModule,
    PBAutocompleteModule,
    PortbaseExportFormInputComponent,
    PortbaseExportFormSelectComponent,
    EmailAddressListComponent,
    ButtonIconDirective,
    MatButtonModule,
    MatInputModule,
    MatListModule,
    OrganisationArrivalLocationAliasesFormComponent,
  ],
  selector: "pbe-arrival-at-exit-preferences-form",
  templateUrl: "./arrival-at-exit-preferences-form.component.html",
  styleUrl: "./arrival-at-exit-preferences-form.component.scss",
})
export class ArrivalAtExitPreferencesFormComponent {
  @ViewChildren(PBAutocompleteComponent)
  autocompleteComponents: QueryList<PBAutocompleteComponent> =
    new QueryList<PBAutocompleteComponent>();

  faPlus = faPlus;
  faTimes = faTimes;

  preferredDocumentType = this.preferencesService.getPreference<string>(
    DOCUMENT_TYPE_PREFERENCE_KEY,
  );

  notificationTypes = ArrivalNotificationTypes;

  UserRights = UserRights;
  userRights = this.authenticationService.userRights;

  organisationNotificationSettings =
    this.preferencesService.getOrganisationNotificationPreferencesForAax()
      .result;
  setOrganisationNotificationSettings =
    this.preferencesService.setOrganisationNotificationPreferencesForAax();

  personalNotificationSettings: PersonalNotificationSettings | null = null;
  setPersonalNotificationSettings =
    this.preferencesService.setPersonalNotificationPreferencesForAax();

  profile = this.authenticationService.getProfile().result;

  form = new FormGroup({
    defaultDocumentType: new FormControl(this.preferredDocumentType ?? "EX", {
      validators: [Validators.required],
    }),
  });

  constructor(
    private destroyRef: DestroyRef,
    private preferencesService: PreferencesService,
    private notificationService: NotificationService,
    private translateService: TranslateService,
    private authenticationService: AuthenticationService
  ) {
    this.form.controls.defaultDocumentType.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((value) => {
        this.preferencesService.setPreference(
          DOCUMENT_TYPE_PREFERENCE_KEY,
          value,
        );

        this.notificationService.show({
          type: "success",
          closable: true,
          title: this.translateService.instant(
            "preferences.arrivalAtExit.notifications.defaultDocumentTypeSaveSuccess.title",
          ),
          message: this.translateService.instant(
            "preferences.arrivalAtExit.notifications.defaultDocumentTypeSaveSuccess.message",
          ),
        });
      });

    effect(() => {
      if (this.organisationNotificationSettings().data) {
        this.personalNotificationSettings =
          this.preferencesService.convertToPersonalNotificationSettings(
            this.organisationNotificationSettings().data as Record<
              NotificationType,
              string[]
            >,
            this.profile().data?.emailAddress ?? "",
          );
      }
    });
  }

  async updatePersonalNotificationSettings(
    key: keyof PersonalNotificationSettings,
    value: boolean,
  ) {
    if (!this.personalNotificationSettings) {
      return;
    }

    await this.setPersonalNotificationSettings.mutateAsync({
      ...this.personalNotificationSettings,
      [key]: value as boolean,
    });
  }

  async updateOrganisationNotificationSettings(
    type: NotificationType,
    emailList: string[],
  ) {
    await this.setOrganisationNotificationSettings.mutateAsync({
      ...this.organisationNotificationSettings().data!,
      [type]: emailList,
    });
  }
}
