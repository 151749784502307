<div class="flex flex-col mt-3 w-full">
  <pbe-select
    [form]="form"
    [controlName]="controlName"
    [placeholder]="placeholder"
    [label]="label"
    [options]="options"
    [required]="required() === true"
    [hideRequiredMarker]="hideRequiredMarker"
  />
</div>
