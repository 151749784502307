import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { NotificationService } from "../services/notification.service";
import { EMPTY, Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { TranslateService } from "@ngx-translate/core";

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private notificationService: NotificationService, private translateService: TranslateService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error?.error?.type === 'about:blank') {

          console.error('An unexpected error has occured:', error);

          this.notificationService.show({
            type: 'error',
            closable: true,
            title: this.translateService.instant('services.error.title'),
            message: this.translateService.instant('services.error.message'),
          });

          return EMPTY;
        }

        return throwError(() => error);
      })
    );
  }
}
