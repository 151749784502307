<mat-toolbar class="flex flex-row items-stretch justify-between">
  <div class="pl-2 flex-grow"><ng-content></ng-content></div>
  <div class="h-full flex flex-row">
    <div class="h-full border-r-slate-300 border-r"></div>
    <div
      class="flex flex-row gap-3 justify-around pl-3 items-center h-full"
    >
      <button
        mat-icon-button
        [icon]="faHeadset"
        [disableRipple]="true"
        [tp]="supportMenu"
        [tpVariation]="'popper'"
        #tooltip="tippy"
      ></button>
      <button
        mat-icon-button
        [icon]="faBullhorn"
        [disableRipple]="true"
        id="pb-beamer-trigger"
      ></button>
      <button
        id="user-menu-button"
        mat-icon-button
        [icon]="faUserGear"
        [disableRipple]="true"
        [tp]="userMenu"
        [tpVariation]="'popper'"
        #tooltip="tippy"
      ></button>
      <ng-template #userMenu>
        <div class="min-w-48">
          <mat-list>
            <div matSubheader>
              {{ "general.userMenu.userSubheader" | translate }}
            </div>
            @if (profile().data) {
              <div class="mb-3 mt-2 px-4">
                <div>
                  {{ profile().data!.firstName }}
                  {{ profile().data!.lastName }}
                </div>
                <div>
                  {{ profile().data!.organisationFullname }}
                </div>
              </div>
            }
          </mat-list>
          <mat-divider></mat-divider>
          <div matSubheader>
            {{ "general.userMenu.languageSubheader" | translate }}
          </div>
          <mat-action-list>
            <mat-list-item
              [activated]="translateService.currentLang === 'nl'"
              (click)="setLanguage('nl'); tooltip.hide()"
              [pbAmplitudeEvent]="'changeLanguage'"
              [eventProperties]="{ language: 'nl' }"
            >
              <div
                class="w-full flex flex-row justify-between items-center"
              >
                    <span>{{
                        "general.userMenu.languages.nl" | translate
                      }}</span>
                @if (translateService.currentLang === "nl") {
                  <fa-icon size="lg" [icon]="faCheck"></fa-icon>
                }
              </div>
            </mat-list-item>
            <mat-list-item
              [activated]="translateService.currentLang === 'en'"
              (click)="setLanguage('en'); tooltip.hide()"
              [pbAmplitudeEvent]="'changeLanguage'"
              [eventProperties]="{ language: 'en' }"
            >
              <div
                class="w-full flex flex-row justify-between items-center"
              >
                    <span>{{
                        "general.userMenu.languages.en" | translate
                      }}</span>
                @if (translateService.currentLang === "en") {
                  <fa-icon size="lg" [icon]="faCheck"></fa-icon>
                }
              </div>
            </mat-list-item>
          </mat-action-list>
          <mat-divider></mat-divider>
          <mat-action-list>
            <mat-list-item (click)="logout(); tooltip.hide()">{{
                "general.userMenu.logout" | translate
              }}</mat-list-item>
          </mat-action-list>
        </div>
      </ng-template>
      <ng-template #supportMenu>
        <div>
          <mat-nav-list>
            <a
              mat-list-item
              href="https://support.portbase.com/services/melding-aankomst-ecs-lading/"
              target="_blank"
              [activated]="translateService.currentLang === 'en'"
              (click)="tooltip.hide()"
            >
              <p matListItemLine>
                    <span class="font-medium">{{
                        "general.supportMenu.title" | translate
                      }}</span>
              </p>
              <p matListItemLine>
                    <span>{{
                        "general.supportMenu.subTitle" | translate
                      }}</span>
              </p>
            </a>
          </mat-nav-list>
        </div>
      </ng-template>
    </div>
  </div>
</mat-toolbar>
