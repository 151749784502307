import {
  Component,
  DestroyRef,
  Input,
  OnChanges,
  SimpleChanges,
} from "@angular/core";
import { FormControl, FormGroup, ReactiveFormsModule } from "@angular/forms";
import { PortbaseExportFormInputComponent } from "../../../../components";

import { CommonModule, NgFor } from "@angular/common";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { MatButtonModule } from "@angular/material/button";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatListModule } from "@angular/material/list";
import { ActivatedRoute, Router } from "@angular/router";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import {
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faMagnifyingGlass,
  faPlus,
  faTimes,
} from "@fortawesome/pro-light-svg-icons";
import { TippyDirective } from "@ngneat/helipopper";
import { TranslateModule } from "@ngx-translate/core";
import { ButtonIconDirective } from "@portbase/material/button";
import { debounceTime } from "rxjs/operators";
import { PaginatedExportManifestsResponseDto } from "../../../services/export-manifest.service";
import { searchValidator } from "../../../validators/search.validator";

export type OrderDirection = "asc" | "desc";
export type OrderByOption = "callReferenceNumber" | "callReferenceNumber";

@Component({
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    ReactiveFormsModule,
    TippyDirective,
    MatListModule,
    ButtonIconDirective,
    MatFormFieldModule,
    MatInputModule,
    FontAwesomeModule,
    MatButtonModule,
    PortbaseExportFormInputComponent,
    NgFor,
  ],
  selector: "pbe-operational-view-table-controls",
  templateUrl: "./operational-view-table-controls.component.html",
  styleUrl: "./operational-view-table-controls.component.scss"
})
export class OperationalViewTableControlsComponent implements OnChanges {
  faMagnifyingGlass = faMagnifyingGlass;
  faPlus = faPlus;
  faTimes = faTimes;
  faChevronRight = faChevronRight;
  faChevronLeft = faChevronLeft;
  faChevronDown = faChevronDown;

  @Input({ required: true }) orderBy!: OrderByOption;
  @Input({ required: true }) orderDirection!: OrderDirection;
  @Input({ required: true }) search!: string;
  @Input({ required: true }) page!: number;
  @Input({ required: true }) pageSize!: number;

  searchForm: FormGroup = new FormGroup({
    search: new FormControl("", [searchValidator]),
  });

  @Input() paginationData!: PaginatedExportManifestsResponseDto | undefined;
  @Input() isLoading!: boolean;

  constructor(
    public router: Router,
    public route: ActivatedRoute,
    private destroyRef: DestroyRef,
  ) {
    this.searchForm
      .get("search")
      ?.valueChanges.pipe(
        debounceTime(300),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe(() => {
        if (this.searchForm.valid) {
          this.submitSearchQuery();
        }
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes["search"]) {
      const searchValue = changes["search"].currentValue as string;

      if (!searchValue && searchValue === "") {
        return;
      }

      this.searchForm.get("search")?.setValue(searchValue);
    }
  }

  clearSearchQuery(event: Event) {
    this.searchForm.controls["search"].setValue(null);
    this.submitSearchQuery(event);
  }

  submitSearchQuery(event?: Event) {
    event?.preventDefault();

    const value = this.searchForm.value.search;
    const shouldRemoveParam = !value || value.length === 0;

    this.router.navigate([], {
      relativeTo: this.route,
      queryParamsHandling: "merge",
      queryParams: {
        search: shouldRemoveParam ? null : value,
      },
    });
  }

  submitOrderDirection(orderDirection: OrderDirection) {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParamsHandling: "merge",
      queryParams: {
        orderDirection,
      },
    });
  }

  previousPage() {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParamsHandling: "merge",
      queryParams: {
        page: Math.max(this.page - 1, 0),
      },
    });
  }

  nextPage() {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParamsHandling: "merge",
      queryParams: {
        page: this.page + 1,
      },
    });
  }

  getPaginationTranslationVariables() {
    const totalElements = this.paginationData?.totalElements ?? 0;

    return {
      total: totalElements,
      first: this.page * this.pageSize + 1,
      last: Math.min((this.page + 1) * this.pageSize, totalElements),
    };
  }
}
