import { CommonModule } from "@angular/common";
import {
  Component,
  DestroyRef,
  effect,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
} from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { FormGroup, ReactiveFormsModule } from "@angular/forms";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatButtonModule } from "@angular/material/button";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { faChevronDown } from "@fortawesome/pro-light-svg-icons";
import {
  faCircleExclamation,
  faWarning,
} from "@fortawesome/pro-regular-svg-icons";
import { TippyDirective } from "@ngneat/helipopper";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { ButtonIconDirective } from "@portbase/material/button";
import { debounceTime } from "rxjs";
import { TextareaAutoresizeDirective } from "../../../directives/textarea-autoresize.directive";
import { ExportManifestService, PortOfDischarge } from "../../services/export-manifest.service";

const SEARCH_DEBOUNCE_TIME = 650; // 650 milliseconds

@Component({
  selector: "pbe-em-port-of-discharge-form-input",
  standalone: true,
  imports: [
    CommonModule,
    MatFormFieldModule,
    TranslateModule,
    MatInputModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    ButtonIconDirective,
    MatButtonModule,
    TippyDirective,
    MatAutocompleteModule,
    TextareaAutoresizeDirective,
  ],
  templateUrl: "./port-of-discharge-form-input.component.html",
  styleUrls: ["./port-of-discharge-form-input.component.scss"],
})
export class PortbaseExportEmPortOfDischargeFormInputComponent
  implements OnInit
{
  @Input({ required: true }) formGroup!: FormGroup;
  @Input({ required: true }) controlName!: string;

  @ViewChild("textareaRef") textareaRef!: ElementRef<HTMLTextAreaElement>;

  faCircleExclamation = faCircleExclamation;
  faWarning = faWarning;
  faChevronDown = faChevronDown;

  exportManifestPortsReferenceDataQuery =
    this.exportManifestService.getExportManifestPortsReferenceData("");
  exportManifestPortsReferenceDataResult =
    this.exportManifestPortsReferenceDataQuery.result;

  suggestionsForPorts: PortOfDischarge[] = [];

  constructor(
    private translateService: TranslateService,
    private exportManifestService: ExportManifestService,
    private destroyRef: DestroyRef,
  ) {
    effect(() => {
      const ports = this.exportManifestPortsReferenceDataResult()?.data ?? [];

      if (!ports.length) {
        return;
      }

      this.suggestionsForPorts = ports;
    });
  }

  ngOnInit(): void {
    this.formGroup
      .get(this.controlName)
      ?.valueChanges.pipe(
        debounceTime(SEARCH_DEBOUNCE_TIME),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe((value) => {
        this.exportManifestPortsReferenceDataQuery.updateOptions(
          this.exportManifestService.getExportManifestPortsReferenceDataQueryOptions(
            value,
            true,
          ),
        );
      });

    const initialPort = this.formGroup.get(this.controlName)?.value;

    if (initialPort) {
      this.suggestionsForPorts.push(initialPort);
    }
  }

  displayFn = (port: PortOfDischarge | null): string => {
    if (port === null) {
      return "";
    }

    return `${port.portCode} - ${port.portName}`;
  };

  get isFocused(): boolean {
    if (!this.textareaRef) {
      return false;
    }

    return this.textareaRef.nativeElement === document.activeElement;
  }

  get errorLabel(): string | undefined {
    const control = this.formGroup.get(this.controlName);

    if (control === null || control.untouched) {
      return undefined;
    }

    if (control.hasError("required")) {
      return this.translateService.instant("general.forms.errors.required");
    }

    if (control.hasError("invalidAutocompleteObject")) {
      return this.translateService.instant(
        "services.exportManifest.createPage.errors.invalidPortOfDischarge",
      );
    }

    return undefined;
  }

  get isDisabled() {
    return (
      this.formGroup.controls[this.controlName].disabled &&
      !this.formGroup.controls[this.controlName].invalid
    );
  }
}
