import { Component, computed, effect, inject } from "@angular/core";

import { CommonModule } from "@angular/common";

import { TranslateModule } from "@ngx-translate/core";
import { BeamerService } from "@portbase/beamer";
import { PBSidenavModule } from "@portbase/material/sidenav";
import { environment } from "../../environments/environment";
import {
  AuthenticationService,
  UserRights,
} from "../core/authentication/authentication.service";
import { PortbaseExportTopbarComponent } from "../core/topbar.component";
import { DataPrefetcherComponent } from "./data-prefetcher/data-prefetcher.component";
import { ArrivalAtExitCustomerServiceRightDrawerComponent } from "./views/customer-service-view/customer-service-right-drawer/customer-service-right-drawer.component";
import { ArrivalAtExitCustomerServiceViewComponent } from "./views/customer-service-view/customer-service-view.component";
import { ArrivalAtExitOperationalRightDrawerComponent } from "./views/operational-view/operational-right-drawer/operational-right-drawer.component";
import { ArrivalAtExitOperationalViewComponent } from "./views/operational-view/operational-view.component";

enum ArrivalAtExitView {
  Default,
  CustomerService,
}

@Component({
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    PBSidenavModule,
    PortbaseExportTopbarComponent,
    DataPrefetcherComponent,
    ArrivalAtExitOperationalViewComponent,
    ArrivalAtExitOperationalRightDrawerComponent,
    ArrivalAtExitCustomerServiceViewComponent,
    ArrivalAtExitCustomerServiceRightDrawerComponent,
  ],
  selector: "pbe-arrival-at-exit-page",
  templateUrl: './arrival-at-exit.component.html',
  styleUrl: './arrival-at-exit.component.scss'
})
export class ArrivalAtExitComponent {
  userRoles = inject(AuthenticationService).userRoles;
  userRights = inject(AuthenticationService).userRights;

  ArrivalAtExitView = ArrivalAtExitView;

  isProduction = environment.production;

  view = computed(() => {
    if (this.userRights().includes(UserRights.SeeCustomerServiceView)) {
      return ArrivalAtExitView.CustomerService;
    }

    return ArrivalAtExitView.Default;
  });

  authenticationService = inject(AuthenticationService);
  beamerService = inject(BeamerService);

  securityProfile = this.authenticationService.getSecurityProfile().result;

  constructor() {
    effect(() => {
      const userId = this.securityProfile().data?.iamcUserId;
      if (userId) {
        this.beamerService.updateUserId(userId, "melding-aankomst-ecs-lading");
      }
    });
  }
}
