@if (arrivalLocationAliases !== undefined) {
  <form [formGroup]="form" class="flex flex-col mt-4 max-w-xl">
    <div formArrayName="arrivalLocationAliases">
      @for (
          group of form.controls.arrivalLocationAliases.controls;
        track $index
        ) {
        <div class="relative" [formGroupName]="$index">
          <div class="flex flex-row items-center gap-2">
            <pb-autocomplete
              class="arrival-at-exit-default-arrival-location-autocomplete w-96"
              optionalLabel=""
              [label]="
                  $index === 0
                    ? ('preferences.arrivalAtExit.fields.arrivalLocationAlias'
                      | translate)
                    : undefined
                "
              [placeholder]="
                  'preferences.arrivalAtExit.fields.arrivalLocationAliasPlaceholderLocation'
                    | translate
                "
              [formGroup]="group"
              controlName="location"
              [suggestions]="
                  getFilteredSuggestionsForArrivalLocationAliases(
                    group.controls.location.value
                  )
                "
            ></pb-autocomplete>
            <mat-form-field class="!mt-2">
              <input
                matInput
                formControlName="alias"
                type="text"
                [placeholder]="
                    'preferences.arrivalAtExit.fields.arrivalLocationAliasPlaceholderAlias'
                      | translate
                  "
              />
            </mat-form-field>
          </div>

          <div class="absolute -right-[35px] top-1/2 -translate-y-1/2">
            <button
              mat-icon-button
              [icon]="faTimes"
              (click)="removeArrivalLocationAlias($index)"
            ></button>
          </div>
        </div>
      }
    </div>

    <div class="flex justify-end items-end gap-4">
      <button
        mat-button
        color="primary"
        class="!p-0"
        [icon]="faPlus"
        (click)="addAnotherArrivalLocationAlias()"
        [disabled]="isAddAnotherArrivalLocationAliasButtonDisabled"
      >
        {{
          "preferences.arrivalAtExit.fields.addAnotherButtonText" | translate
        }}
      </button>
      <button
        mat-button
        color="primary"
        class="!p-0"
        [disabled]="
            form.controls.arrivalLocationAliases.invalid ||
            form.controls.arrivalLocationAliases.pristine
          "
        (click)="saveArrivalLocationAliases()"
      >
        {{
          "preferences.arrivalAtExit.fields.saveArrivalLocationAliasesButtonText"
            | translate
        }}
      </button>
    </div>
  </form>
}
