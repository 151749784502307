<div
  class="flex flex-row justify-between items-center"
  (click)="triggerRef.openPanel(); textareaRef.focus()"
>
  <ng-container [formGroup]="formGroup">
        <textarea
          wrap="soft"
          matInput
          [placeholder]="
            'services.exportManifest.createPage.fields.documentTypePlaceholder'
              | translate
          "
          [formControlName]="controlName"
          [matAutocomplete]="auto"
          [matAutocompleteDisabled]="isDisabled"
          class="w-full resize-none overflow-hidden placeholder:font-normal placeholder:text-gray-400 placeholder:text-sm font-normal text-gray-800 text-sm disabled:cursor-not-allowed"
          autocomplete="off"
          pbTextareaAutoresize
          #triggerRef="matAutocompleteTrigger"
          #textareaRef
          rows="1"
        ></textarea>

    @if (
      formGroup.controls[controlName].invalid &&
      formGroup.controls[controlName].touched
      ) {
      <fa-icon
        matSuffix
        size="lg"
        [icon]="faCircleExclamation"
        class="py-1.5 px-2 text-red-700 rounded-full hover:bg-red-100"
        [tp]="errorLabel"
        tpVariation="tooltipArrow"
        [tpOffset]="[0, 0]"
      ></fa-icon>
    } @else {
      <fa-icon
        matSuffix
        size="lg"
        [icon]="faChevronDown"
        class="py-1.5 px-2 rounded-full"
        [ngClass]="{
              'text-blue-300': isDisabled,
              'text-blue-700 group-hover:bg-blue-100': !isDisabled,
              'bg-blue-200 group-hover:bg-blue-200': isFocused
            }"
      ></fa-icon>
    }

    <mat-autocomplete
      #auto="matAutocomplete"
      [hideSingleSelectionIndicator]="true"
      [displayWith]="displayFn"
    >
      <mat-option
        *ngFor="
              let option of getFilteredOptionsForDocumentTypes(
                formGroup.get('documentType')?.value
              )
            "
        [value]="option.value"
      >
        {{ option.label }}
      </mat-option>
    </mat-autocomplete>
  </ng-container>
</div>
