@if (shipment) {
  <div class="w-full cursor-pointer" (click)="toggleSelected.emit()">
    <div
      class="w-full pt-5 pb-2.5 px-6 border flex flex-row justify-between hover:border-pb-blue-600"
      [ngClass]="{
          'border-pb-blue-600': selected,
          'border-gray-200': !selected
        }"
    >
      <div class="mt-2">
        <mat-pseudo-checkbox
          [state]="selected ? 'checked' : 'unchecked'"
        ></mat-pseudo-checkbox>
      </div>
      <pbe-data-item
        class="flex-grow-1"
        [label]="
            'services.exportManifest.copyShipmentsDialog.shipmentFields.reference'
              | translate
          "
        [value]="shipment.reference || '-'"
        [textWarp]="true"
      />
      <pbe-data-item
        class="flex-grow-1"
        [label]="
            'services.exportManifest.copyShipmentsDialog.shipmentFields.document'
              | translate
          "
        [value]="formatDocumentTypeAndNumber()"
      />
      <pbe-data-item
        class="flex-grow-1"
        [label]="
            'services.exportManifest.copyShipmentsDialog.shipmentFields.arrivalDateTime'
              | translate
          "
        [value]="(shipment.arrivalDateTime | date: 'dd-MM-yyyy') || '-'"
      />
      <pbe-data-item
        [label]="
            'services.exportManifest.copyShipmentsDialog.shipmentFields.arrivalAtExitStatus'
              | translate
          "
        [value]="''"
      >
        @if (shipment.arrivalAtExitStatus) {
          <pbe-aax-status-indicator
            [status]="shipment.arrivalAtExitStatus"
          ></pbe-aax-status-indicator>
        } @else {
          <span>-</span>
        }
      </pbe-data-item>
    </div>
  </div>
}
