<div class="flex flex-col items-stretch">
  <div class="px-5 pt-5 flex flex-row justify-between items-start mb-3">
    <h3 class="text-lg font-medium mb-2">
      {{ "services.exportManifest.copyShipmentsDialog.title" | translate }}
    </h3>
    <button mat-dialog-close mat-icon-button [icon]="faTimes"></button>
  </div>

  <pbe-tab-bar
    [activeTabIndex]="activeTabIndex"
    (changeTabIndex)="activeTabIndex = $event"
    [tabLabels]="[getAllShipmentsLabel(), getSelectedLabel()]"
  ></pbe-tab-bar>
</div>
<div class="px-5 !min-w-[700px] h-[500px] overflow-y-auto">
  <div *ngIf="activeTabIndex === 0">
    <mat-form-field class="w-full" [formGroup]="searchForm">
      <input
        matInput
        [placeholder]="
              'services.arrivalAtExit.overview.searchPlaceholder' | translate
            "
        id="search"
        formControlName="search"
      />
    </mat-form-field>
    <div class="flex flex-col gap-2 mt-4 items-stretch">
      @for (
          shipment of getFilteredShipments();
        track shipment.documentNumber
        ) {
        <pbe-export-manifest-copy-shipment-list-item
          [shipment]="shipment"
          [selected]="isShipmentSelected(shipment)"
          (toggleSelected)="toggleSelected(shipment)"
        />
      } @empty {
        <div
          class="flex flex-col items-center w-full mt-8 justify-center text-center"
        >
          <div class="bg-gray-200 w-24 h-24 pt-6 text-center rounded-full">
            <fa-icon
              class="text-5xl block text-gray-400"
              [icon]="faShip"
            ></fa-icon>
          </div>
          <p class="mt-3">
            {{
              "services.exportManifest.copyShipmentsDialog.empty.title"
                | translate
            }}
          </p>
        </div>
      }
    </div>
  </div>
  <div *ngIf="activeTabIndex === 1">
    <div class="flex flex-col gap-2 mt-4 items-stretch">
      @for (shipment of selection; track shipment.documentNumber) {
        <pbe-export-manifest-copy-shipment-list-item
          [shipment]="shipment"
          [selected]="isShipmentSelected(shipment)"
          (toggleSelected)="toggleSelected(shipment)"
        />
      } @empty {
        <div
          class="flex flex-col items-center w-full mt-8 justify-center text-center"
        >
          <div class="bg-gray-200 w-24 h-24 pt-6 text-center rounded-full">
            <fa-icon
              class="text-5xl block text-gray-400"
              [icon]="faShip"
            ></fa-icon>
          </div>
          <p class="mt-3">
            {{
              "services.exportManifest.copyShipmentsDialog.noSelected.title"
                | translate
            }}
          </p>
        </div>
      }
    </div>
  </div>
</div>
<mat-dialog-actions [align]="'end'" class="p-0">
  <button mat-dialog-close mat-stroked-button>
    {{
      "services.exportManifest.copyShipmentsDialog.cancelButton" | translate
    }}
  </button>
  <button
    mat-raised-button
    [disabled]="selection.length === 0"
    [mat-dialog-close]="selection"
  >
    {{
      "services.exportManifest.copyShipmentsDialog.confirmButton"
        | translate
    }}
  </button>
</mat-dialog-actions>
