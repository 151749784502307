import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";

export interface WeightValidatorOptions {
  requiredBypartshipment?: boolean;
  requiredByVesselInRotterdam?: boolean;
}

export function weightValidator(options: WeightValidatorOptions): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const weight = control.value;

    if (options.requiredBypartshipment && !weight) {
      return { requiredByPartshipment: true };
    }

    if (options.requiredByVesselInRotterdam && !weight) {
      return { requiredByVesselInRotterdam: true };
    }

    return null;
  };
}
