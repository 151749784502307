import { CommonModule } from "@angular/common";
import { Component, Input } from "@angular/core";
import { FormGroup, ReactiveFormsModule } from "@angular/forms";
import {
  MAT_RIPPLE_GLOBAL_OPTIONS,
  RippleGlobalOptions,
} from "@angular/material/core";
import {
  MAT_FORM_FIELD_DEFAULT_OPTIONS,
  MatFormFieldDefaultOptions,
} from "@angular/material/form-field";
import {
  MAT_SELECT_CONFIG,
  MatSelectConfig,
  MatSelectModule,
} from "@angular/material/select";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { TranslateModule } from "@ngx-translate/core";

@Component({
  selector: "pbe-select",
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    FontAwesomeModule,
    ReactiveFormsModule,
    MatSelectModule,
  ],
  providers: [
    {
      provide: MAT_RIPPLE_GLOBAL_OPTIONS,
      useValue: <RippleGlobalOptions>{ disabled: true },
    },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: <MatFormFieldDefaultOptions>{
        appearance: "outline",
        floatLabel: "always",
      },
    },
    {
      provide: MAT_SELECT_CONFIG,
      useValue: <MatSelectConfig>{ hideSingleSelectionIndicator: true },
    },
  ],
  templateUrl: './select.component.html',
  styleUrl: "./select.component.scss"
})
export class PortbaseExportSelectComponent {
  @Input({ required: true }) form!: FormGroup;
  @Input({ required: true }) label!: string;
  @Input({ required: true }) controlName!: string;
  @Input() options: { label: string; value: string }[] = [];
  @Input() placeholder?: string;

  @Input() required = false;
  @Input() hideRequiredMarker = false;
}
