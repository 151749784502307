import { AfterViewInit, Component, DestroyRef, effect, OnInit } from "@angular/core";

import { CommonModule } from "@angular/common";
import { FormControl, FormGroup, ReactiveFormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatInputModule } from "@angular/material/input";
import { MatListModule } from "@angular/material/list";
import { faPlus } from "@fortawesome/pro-light-svg-icons";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { PBAutocompleteModule } from "@portbase/material/autocomplete";
import { ButtonIconDirective } from "@portbase/material/button";
import {
  PortbaseExportFormInputComponent,
  PortbaseExportFormSelectComponent,
} from "../../../components";
import { EmailAddressListComponent } from "../email-address-list/email-address-list.component";
import { AuthenticationService, UserRights } from "../../../core/authentication/authentication.service";
import {
  ExportNotificationTypes, NotificationType,
  PersonalNotificationSettings,
  PreferencesService
} from "../../services/preferences.service";
import {
  ExportManifestService,
  ExportManifestShipmentDocumentType
} from "../../../export-manifest/services/export-manifest.service";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { MANIFEST_DOCUMENT_TYPE_PREFERENCE_KEY } from "../../../constants";
import { NotificationService } from "../../../core/services/notification.service";

@Component({
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TranslateModule,
    PBAutocompleteModule,
    PortbaseExportFormInputComponent,
    PortbaseExportFormSelectComponent,
    ButtonIconDirective,
    MatButtonModule,
    MatInputModule,
    MatListModule,
    EmailAddressListComponent,
  ],
  selector: "pbe-export-manifest-preferences-form",
  templateUrl: "./export-manifest-preferences-form.component.html",
  styleUrl: "./export-manifest-preferences-form.component.scss",
})
export class ExportManifestPreferencesFormComponent {
  faPlus = faPlus;

  preferredDocumentType = this.preferencesService.getPreference<string>(
    MANIFEST_DOCUMENT_TYPE_PREFERENCE_KEY,
  );

  form = new FormGroup({
    defaultDocumentType: new FormControl(this.preferredDocumentType ?? ""),
  });
  notificationTypes = ExportNotificationTypes;
  personalNotificationSettings: PersonalNotificationSettings | null = null;
  organisationNotificationSettings =
    this.preferencesService.getOrganisationNotificationPreferencesForAax()
      .result;
  UserRights = UserRights;
  userRights = this.authenticationService.userRights;
  setPersonalNotificationSettings =
    this.preferencesService.setPersonalNotificationPreferencesForAax();
  setOrganisationNotificationSettings =
    this.preferencesService.setOrganisationNotificationPreferencesForAax();
  profile = this.authenticationService.getProfile().result;
  documentTypeOptions: { value: ExportManifestShipmentDocumentType; label: string }[] = [];

  constructor(private preferencesService: PreferencesService, private authenticationService: AuthenticationService, private exportManifestService: ExportManifestService, private destroyRef: DestroyRef, private notificationService: NotificationService, private translateService: TranslateService) {
    this.documentTypeOptions = this.exportManifestService.getDocumentTypeOptions();

    this.form.controls.defaultDocumentType.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((value) => {
        this.preferencesService.setPreference(
          MANIFEST_DOCUMENT_TYPE_PREFERENCE_KEY,
          value,
        );

        this.notificationService.show({
          type: "success",
          closable: true,
          title: this.translateService.instant(
            "preferences.arrivalAtExit.notifications.defaultDocumentTypeSaveSuccess.title",
          ),
          message: this.translateService.instant(
            "preferences.arrivalAtExit.notifications.defaultDocumentTypeSaveSuccess.message",
          ),
        });
      });

    effect(() => {
      if (this.organisationNotificationSettings().data) {
        this.personalNotificationSettings =
          this.preferencesService.convertToPersonalNotificationSettings(
            this.organisationNotificationSettings().data as Record<
              NotificationType,
              string[]
            >,
            this.profile().data?.emailAddress ?? "",
          );
      }
    });
  }

  async updatePersonalNotificationSettings(
    key: keyof PersonalNotificationSettings,
    value: boolean,
  ) {
    if (!this.personalNotificationSettings) {
      return;
    }

    await this.setPersonalNotificationSettings.mutateAsync({
      ...this.personalNotificationSettings,
      [key]: value as boolean,
    });
  }

  async updateOrganisationNotificationSettings(
    type: NotificationType,
    emailList: string[],
  ) {
    await this.setOrganisationNotificationSettings.mutateAsync({
      ...this.organisationNotificationSettings().data!,
      [type]: emailList,
    });
  }
}
