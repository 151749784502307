import { Component, Input } from "@angular/core";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { TranslateModule } from "@ngx-translate/core";
import {
  ShipmentHistoryDto,
  ShipmentOperationType,
} from "../../../services/shipment-history.service";

@Component({
  selector: "pbe-history-item-inspection",
  standalone: true,
  imports: [TranslateModule, FontAwesomeModule],
  templateUrl: "./history-item-inspection.component.html",
  styleUrl: "./history-item-inspection.component.scss"
})
export class HistoryItemInspectionComponent {
  @Input({ required: true })
  history!: ShipmentHistoryDto<ShipmentOperationType.AAX_INSPECTION_OF_EXPORT_SHIPMENTS_AT_CUSTOMS>;
}
