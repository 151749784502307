<div class="flex flex-col w-full h-full">
  <pbe-topbar>
    <div class="flex flex-row justify-between items-center w-full">
      <h1 class="text-xl text-gray-700 ml-6">
        {{ "services.exportManifest.title" | translate }}
      </h1>
      @if (!isProduction) {
        <p class="text-xs mr-6">{{ userRoles().join(", ") }}</p>
      }
    </div>
  </pbe-topbar>

  @if (isAdmin()) {
    <pbe-export-manifest-customer-service-view></pbe-export-manifest-customer-service-view>
    <pbe-export-manifest-customer-service-right-drawer></pbe-export-manifest-customer-service-right-drawer>
  } @else {
    <pbe-export-manifest-operational-view></pbe-export-manifest-operational-view>
    <pbe-export-manifest-operational-right-drawer></pbe-export-manifest-operational-right-drawer>
  }

  <pbe-export-manifest-data-prefetcher />
</div>
