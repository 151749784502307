<button
  class="flex flex-col items-stretch justify-start w-full px-4 py-2 hover:bg-gray-200 mb-2 min-h-8 rounded-sm disabled:opacity-70 disabled:bg-gray-200 disabled:cursor-not-allowed"
  [class.bg-gray-100]="filter !== null"
  [disabled]="disabled"
>
  <div class="flex flex-row justify-between items-center">
    <div class="grid grid-cols-[auto_1fr] items-center gap-2">
      @if (isAdmin) {
        <fa-icon [icon]="faHeadset"></fa-icon>
      }
      <span class="font-medium text-sm">{{ label }}</span>
    </div>
    <button
      mat-icon-button
      (click)="clearButtonClicked($event)"
      [disabled]="disabled"
    >
      <fa-icon [icon]="filter === null ? faPlus : faTimes"></fa-icon>
    </button>
  </div>
  <span
    *ngIf="filter !== null"
    class="text-xs text-slate-500 text-left mb-2 text-ellipsis overflow-hidden whitespace-nowrap"
    >{{ formattedFilter }}</span
  >
</button>
