<div class="flex flex-row justify-between items-center">
  <ng-container [formGroup]="formGroup">
        <textarea
          wrap="soft"
          matInput
          [placeholder]="
            'services.exportManifest.createPage.fields.referencePlaceholder'
              | translate
          "
          [formControlName]="controlName"
          class="w-full resize-none overflow-hidden placeholder:font-normal placeholder:text-gray-400 placeholder:text-sm font-normal text-gray-800 text-sm disabled:cursor-not-allowed"
          autocomplete="off"
          pbTextareaAutoresize
          rows="1"
          maxlength="25"
        ></textarea>

    @if (
      formGroup.controls[controlName].invalid &&
      formGroup.controls[controlName].touched
      ) {
      <fa-icon
        matSuffix
        size="lg"
        [icon]="faCircleExclamation"
        class="py-1.5 px-2 text-red-700 rounded-full hover:bg-red-100"
        [tp]="errorLabel"
        tpVariation="tooltipArrow"
        [tpOffset]="[0, 0]"
      ></fa-icon>
    }
  </ng-container>
</div>
