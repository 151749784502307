import { Injectable, inject } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import {
  PBAlertComponent,
  PBSnackBarAlertData,
} from "@portbase/material/alert";

export const DEFAULT_NOTIFICATION_DURATION = 3000;

@Injectable({ providedIn: "root" })
export class NotificationService {
  #snackbar = inject(MatSnackBar);

  show(
    data: PBSnackBarAlertData,
    duration: number = DEFAULT_NOTIFICATION_DURATION
  ) {
    this.#snackbar.openFromComponent(PBAlertComponent, {
      data,
      duration,
    });
  }
}
