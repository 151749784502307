<div class="flex gap-x-3">
  <div class="text-end min-w-fit mt-1">
    <p class="text-gray-600 font-sans font-normal text-sm">
      {{ history.operationDateTime | date: "dd-MM-yy" }}
    </p>
    <p class="text-gray-600 font-sans font-normal text-sm">
      {{ history.operationDateTime | date: "HH:mm" }}
    </p>
  </div>

  <div
    class="relative -mt-1 last:after:hidden after:absolute after:top-[35px] after:-bottom-[10px] after:start-3 after:w-px after:-translate-x-[0.5px] after:bg-[#dae1e6]"
  >
    <fa-icon
      [icon]="isMostRecent ? faCircleDot : faCircle"
      class="text-2xl text-blue-500"
    ></fa-icon>
  </div>

  <div class="flex flex-col grow gap-1">
    <ng-content></ng-content>
    <div class="flex gap-1.5 items-center">
      @if (history.userFullName) {
        <p class="text-gray-600 font-sans font-normal text-sm">
          {{ history.userFullName }}
        </p>

        <fa-icon [icon]="faPipe" class="text-gray-700 text-sm"></fa-icon>
      }

      <p class="text-gray-600 font-sans font-normal text-sm">
        {{ organisation().data?.fullName }}
      </p>
    </div>
  </div>
</div>
