import { Component, Input } from "@angular/core";

@Component({
  selector: "pbe-vessel-visit-item",
  standalone: true,
  templateUrl: './vessel-visit-item.component.html',
  styleUrls: ["./vessel-visit-item.component.scss"]
})
export class PortbaseVesselVisitItemComponent {
  @Input({ required: true }) label!: string;
  @Input({ required: true }) value!: string | number | Date | boolean;
}
