import { Component, Input } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { faCircleNotch } from "@fortawesome/pro-light-svg-icons";
import { TranslateModule } from "@ngx-translate/core";
import { ButtonIconDirective } from "@portbase/material/button";

@Component({
  standalone: true,
  imports: [
    FontAwesomeModule,
    TranslateModule,
    MatButtonModule,
    ButtonIconDirective,
  ],
  selector: "pbe-overview-table-loading-state",
  templateUrl: './loading-state.component.html',
  styleUrl: "./loading-state.component.scss"
})
export class OverviewTableLoadingStateComponent {
  loadingIcon = faCircleNotch;
  @Input() service!: "exportManifest" | "arrivalAtExit";
}
